import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { videoPlayerUtils } from "../../utils";
import { videoMessageState, videoControlsState } from "../../store/videoState";
import styles from "./styles.module.scss";
import { FullScreen } from "./FullScreen";
import { OptionIcon } from "../Chat/ChatHeader/OptionIcon";
import { VolumeIcon } from "../Chat/ChatHeader/VolumeIcon";
import { hideChatState, minimizedChatState } from "../../store/states";
import { ButtonSVG } from "../shared/ButtonSVG";
import IconSVG from "../IconImages/IconSVG";

const SeekBarPane = ({ duration, thisVideo }) => {
  const seekRef = useRef(0);
  const videoMessage = useRecoilValue(videoMessageState);
  const hideChat = useRecoilValue(hideChatState);
  const minimizedChat = useRecoilValue(minimizedChatState);
  const { displayVideoIcons, height } = useRecoilValue(videoControlsState);
  const isWebkitBased = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const queryMedia = () => {
    if (height > 600 || videoMessage?.targetContainer) return "l";
    if (height < 240) return "s";
    return "m";
  };

  const video = videoPlayerUtils.getVideo();
  useEffect(() => {
    if (!video) return () => {};
    const handleProgress = () => {
      seekRef.current.value = video.currentTime;
    };
    video.addEventListener("timeupdate", handleProgress);
    return () => {
      video.removeEventListener("timeupdate", handleProgress);
    };
  }, [video]);

  const handleReplay = () => {
    thisVideo.current.seekTo(videoMessage.movie?.start || 0, "seconds");
  };

  const handleSeekChange = (e) => {
    thisVideo.current.seekTo(e.target.value, "seconds");
  };

  return (
    <div
      id="--harper-video-seek-bar"
      className={clsx(
        queryMedia() === "l" && styles.seekBarPaneControlOffset,
        styles.seekBarPaneControl,
        displayVideoIcons ? styles.displaySeekbar : styles.hideSeekbar,
      )}
    >
      <div className={clsx(styles.paneControls)}>
        <div className={clsx(styles.paneControls1)}>
          <ButtonSVG
            onClick={handleReplay}
            iconSVG={IconSVG.VideoReplayIcon}
            className="--harper-btn-replay"
          />
          <VolumeIcon />
        </div>

        <div className={clsx(styles.paneControls1)}>
          <span className={clsx(styles.videoTime)}>
            {videoPlayerUtils.secondsToTime(video?.currentTime || 0)}/
            {videoPlayerUtils.secondsToTime(duration)}
          </span>
          <FullScreen />
          {(minimizedChat || hideChat) && <OptionIcon position="top" />}
        </div>
      </div>

      <input
        ref={seekRef}
        className={clsx(styles.seekSlider, !isWebkitBased && styles.height3px)}
        type="range"
        aria-labelledby="video-seek-bar"
        placeholder="video-seek-bar"
        defaultValue={0}
        onChange={handleSeekChange}
        max={duration}
        step="any"
      ></input>
    </div>
  );
};

export default SeekBarPane;
