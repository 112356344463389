import { selector } from "recoil";
import { hideChatState, hideVideoState } from "./states";
import { videoMessageState } from "./videoState";

export const isVideoInChatOnlySelector = selector({
  key: "isVideoInChatOnlySelector",
  get: ({ get }) => {
    const hideChat = get(hideChatState);
    const hideVideo = get(hideVideoState);
    const videoMessage = get(videoMessageState);
    return hideChat && !hideVideo && videoMessage && !videoMessage?.targetContainer;
  },
});

export const isExternalVideoOnlySelector = selector({
  key: "isExternalVideoOnlySelector",
  get: ({ get }) => {
    const hideChat = get(hideChatState);
    const hideVideo = get(hideVideoState);
    const videoMessage = get(videoMessageState);
    return hideChat && videoMessage && videoMessage.targetContainer && !hideVideo;
  },
});
