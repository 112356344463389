import { useEffect, useState } from "react";
import { formatDate } from "../utils";

// update time every minute
const useTimeStamp = (dateString) => {
  const [date, setDate] = useState(() => formatDate(dateString));

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(formatDate(dateString));
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return date;
};

export default useTimeStamp;
