import React from "react";
import MarkdownToJSX from "markdown-to-jsx";
import clsx from "clsx";
import styles from "../styles.module.scss";

const Markdown = ({ field }) => {
  return (
    <div className={clsx(styles.videoformMarkdown, "--harper-videoForm-markdown")}>
      <MarkdownToJSX>{field?.title}</MarkdownToJSX>
    </div>
  );
};

export default Markdown;
