import { init, formula } from "expressionparser";

export const evalExpression = (text) => {
  const redefine = {
    "||": "OR",
    "&&": "AND",
    "===": "=",
    "==": "=",
    "!==": "!=",
  };
  let t = text;
  Object.entries(redefine).forEach(([k, v]) => {
    t = t.replace(k, v);
  });
  const parser = init(formula, (term) => {
    if (term === "true") return true;
    if (term === "false") return false;
    if (term === "null") return null;
    if (term === "undefined") return undefined;
    return term;
  });
  try {
    const parsed = parser.expressionToValue(t);
    if (typeof parsed === "boolean") return parsed;
    return Boolean(parsed);
  } catch (err) {
    if (t === "null") return false;
    // eslint-disable-next-line no-console
    console.log(`Fail to evaluate ${text}`);
    return false;
  }
};
