import objectPath from "object-path";
import { sample, merge } from "lodash-es";
import { patchStorage, sendMessage } from "./api";
import { handleBlock } from "../utils/blockHandling";
import { logStore, replaceText } from "../utils";
import {
  blocksState,
  botConfigState,
  messagesState,
  currentBlockState,
  videoFormState,
  visitorInfoState,
  visitorStorageState,
  triggersState,
  messageState,
  minimizedVideoState,
  minimizedChatState,
  hideChatState,
  hideVideoState,
  stopProcessConditionState,
} from "./states";
import {
  isVideoPlayingSelector,
  videoControlsState,
  videoIsStartedState,
  videoMessageState,
  queueVideoState,
} from "./videoState";

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const processStoreJson = (specificKey, aliases, method = "GET", setValue = {}) => {
  const { organisationAlias, botAlias } = aliases;
  const generalKey = `${organisationAlias}-${botAlias}`;
  const storedJson = JSON.parse(localStorage.getItem(generalKey)) || {};
  if (method === "GET") return objectPath.get(storedJson, specificKey) || {};
  if (method === "SET") {
    objectPath.set(storedJson, specificKey, setValue);
    localStorage.setItem(generalKey, JSON.stringify(storedJson));
  }
  return setValue;
};

export const handleBotActionEvent = ({ action, set, videoMessage, hideChat }) => {
  switch (action) {
    case "USE-CHAT-AND-VIDEO":
      set(hideChatState, false);
      set(hideVideoState, false);
      if (videoMessage?.targetContainer) {
        set(minimizedVideoState, false);
      }
      break;
    case "USE-CHAT-ONLY":
      set(hideChatState, false);
      set(hideVideoState, true);
      break;
    case "USE-VIDEO-ONLY":
      set(hideChatState, true);
      set(hideVideoState, false);
      if (videoMessage?.targetContainer) {
        set(minimizedVideoState, false);
      }
      break;
    case "HIDE-BOT":
      set(hideChatState, true);
      set(hideVideoState, true);
      break;
    case "MINIMIZE-CHAT":
      if (hideChat && !videoMessage?.targetContainer) {
        set(minimizedVideoState, true);
      }
      set(minimizedChatState, true);
      if (!videoMessage?.targetContainer) {
        set(videoControlsState, (v) => ({ ...v, isPlaying: false }));
      }
      break;
    case "MAXIMIZE-CHAT":
      set(minimizedChatState, false);
      set(minimizedVideoState, false);
      break;
    case "FORCE-RELOAD":
      window.location.reload();
      break;
    default:
      break;
  }
};

export const processing = async ({ snapshot, set }, queueBlockId, processConfig) => {
  const botConfig = await snapshot.getPromise(botConfigState);
  const blocks = await snapshot.getPromise(blocksState);
  const visitorInfo = await snapshot.getPromise(visitorInfoState);
  const visitorStorage = await snapshot.getPromise(visitorStorageState);
  const minimizedChat = await snapshot.getPromise(minimizedChatState);
  const hideVideo = await snapshot.getPromise(hideVideoState);
  const hideChat = await snapshot.getPromise(hideChatState);
  const videoMessage = await snapshot.getPromise(videoMessageState);
  const { organisationAlias, alias: botAlias, baseUrl } = botConfig;
  const blockId = replaceText(queueBlockId, visitorStorage.variables);

  const processVideo = (message) => {
    logStore("processVideo", message);
    set(videoMessageState, message);
    // Prevent showing of VideoForm in the next video
    set(videoFormState, null);
    if (processConfig?.videoSendMessage !== undefined && processConfig.videoSendMessage) {
      set(messageState(message._id), message);
      set(messagesState, (state) => [...state, message._id]);
      sendMessage(message, { organisationAlias, botAlias, visitorId: visitorInfo._id });
      logStore("processVideo - sendMessage", message);
    }

    if (processConfig?.isPlaying !== undefined) {
      set(isVideoPlayingSelector, processConfig.isPlaying);
    }

    if (processConfig?.videoIsStarted !== undefined) {
      logStore("set videoIsStarted", processConfig);
      set(videoIsStartedState, processConfig.videoIsStarted);
    }

    if (processConfig?.minimizedVideoState !== undefined) {
      set(minimizedVideoState, processConfig.minimizedVideoState);
    }

    return message;
  };

  const processVideoForm = (message) => {
    logStore("videoFormState", message);
    set(videoFormState, message);

    return message;
  };

  const processQueueVideo = (videoBlock) => {
    logStore("queueVideo", videoBlock);
    set(queueVideoState, videoBlock);
    return videoBlock;
  };

  const processChat = (message) => {
    logStore("processChat", message);
    set(messageState(message._id), message);
    set(messagesState, (state) => [...state, message._id]);
    sendMessage(message, { organisationAlias, botAlias, visitorId: visitorInfo._id });
    return message;
  };

  const processABTEST = (_id, fields) => {
    const key = "ABTEST";
    const aliases = { organisationAlias, botAlias };
    const storeABTEST = processStoreJson(key, aliases, "GET");
    let nextBlockId = storeABTEST[_id] || null;
    if (!nextBlockId) {
      const bigArrayIndx = [];
      fields.forEach((f, i) => {
        bigArrayIndx.push(...Array(f.weight).fill(i));
      });
      nextBlockId = fields[sample(bigArrayIndx)].nextBlockId;
      storeABTEST[_id] = nextBlockId;
      processStoreJson(key, aliases, "SET", storeABTEST);
    }
    return nextBlockId;
  };

  const processEvent = (message) => {
    let isStopProcess = false;
    const { action, haltProcessCondition } = message;
    logStore("processEvent", action);
    handleBotActionEvent({ action, set, videoMessage, hideChat });
    if (haltProcessCondition === "WHEN-BOT-IS-MINIMIZED" && minimizedChat) {
      isStopProcess = true;
    }
    if (haltProcessCondition === "WHEN-VIDEO-IS-DISABLED" && hideVideo) {
      isStopProcess = true;
    }
    if (haltProcessCondition === "WHEN-CHAT-IS-DISABLED" && hideChat) {
      isStopProcess = true;
    }
    if (isStopProcess) {
      set(stopProcessConditionState, {
        halt: haltProcessCondition,
        resumeBlockId: message.blockId,
        urlSrc: window.location.href,
      });
      return true;
    }
    return isStopProcess;
  };

  if (blocks[blockId]) {
    const variables = merge({}, visitorStorage.variables, processConfig?.storageObj || {});
    const passwords = merge(
      {},
      visitorStorage?.passwords || {},
      processConfig?.storagePasswords || {},
    );

    await handleBlock(blocks[blockId], {
      blocks,
      visitorInfo,
      botConfig,
      visitorId: visitorInfo._id,
      organisationAlias: botConfig.organisationAlias,
      botAlias: botConfig.alias,
      baseUrl,
      variables,
      passwords,
      process: {
        processVideo,
        processVideoForm,
        processChat,
        processChatForm: processChat,
        processEvent,
        processQueueVideo,
        processABTEST,
      },
    }).then((block) => {
      if (
        JSON.stringify(variables) !== JSON.stringify(visitorStorage.variables) ||
        JSON.stringify(passwords) !== JSON.stringify(visitorStorage?.passwords || {})
      ) {
        patchStorage(
          { variables, passwords },
          { organisationAlias, botAlias, visitorId: visitorInfo._id },
        );
      }
      set(currentBlockState, block._id);
      logStore("processing is set!", visitorStorage);
    });
  } else if (blockId) {
    // eslint-disable-next-line no-console
    console.warn("block does not exist in blocks", blockId, blocks);
  }
};
const searchForTriggers = (src, triggers) => {
  logStore("Trigger search:", src, triggers);
  const arr = triggers.filter((block) => {
    if (src.includes(block.trigger.src) || block.trigger.src === "*") {
      logStore(`Trigger found: ${block.trigger.src}`, block, src);
      return true;
    }
    return false;
  });

  // prioritize triggers;
  if (arr.length > 0) {
    const sorted = arr.sort(
      (a, b) =>
        a.trigger.priority - b.trigger.priority ||
        a.trigger.src.split("/").length - b.trigger.src.split("/").length ||
        b.trigger.src.length - a.trigger.src.length,
    );
    logStore(`Trigger used: ${sorted[0].trigger.src}`, sorted[0], src);
    return [sorted[0]];
  }
  return [];
};

export const processTrigger = async ({ snapshot, set }, src) => {
  const triggers = await snapshot.getPromise(triggersState);
  const currentTriggers = searchForTriggers(src, triggers);
  if (currentTriggers.length > 0) {
    const newTriggerBlockId = currentTriggers[0]._id;
    await processing({ snapshot, set }, newTriggerBlockId, {
      videoIsStarted: false,
      isPlaying: false,
      videoSendMessage: false,
    });
  }
};
