/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from "react";

if (!window._harper_location_overload) {
  const { pushState, replaceState } = window.history;

  const dispatchLocationChangeEvent = () => {
    window.dispatchEvent(new Event("locationchange"));
  };

  window.history.pushState = (...args) => {
    pushState.apply(window.history, args);
    dispatchLocationChangeEvent();
  };

  window.history.replaceState = (...args) => {
    replaceState.apply(window.history, args);
    dispatchLocationChangeEvent();
  };

  window.addEventListener("popstate", () => {
    dispatchLocationChangeEvent();
  });

  window._harper_location_overload = true;
}

export const useLocationCallback = (cb) => {
  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      const listenLocationChange = (e) => {
        cb(e);
      };

      window.addEventListener("locationchange", listenLocationChange);
      return () => {
        window.removeEventListener("locationchange", listenLocationChange);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useLocation = () => {
  const [location, setLocation] = useState(window.location);
  const once = useRef(false);

  useEffect(() => {
    if (!once.current) {
      once.current = true;

      const listenLocationChange = () => {
        const { hash, host, hostname, href, origin, pathname, port, protocol } = window.location;
        setLocation({ hash, host, hostname, href, origin, pathname, port, protocol });
      };

      window.addEventListener("locationchange", listenLocationChange);
      return () => {
        window.removeEventListener("locationchange", listenLocationChange);
      };
    }
    return () => {};
  }, []);
  return location;
};
