import React, { useState, useRef, useEffect, useCallback } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import ReactPlayer from "react-player";
import clsx from "clsx";
import { logRender, videoPlayerUtils } from "../../utils";
import { useIsTabHasFocus } from "../../hooks";
// store
import { useProcessBlocks, useSendMessage } from "../../store/hooks";
import {
  videoFormState,
  botConfigState,
  minimizedChatState,
  hideChatState,
  minimizedVideoState,
  isSettingsModeState,
} from "../../store/states";
import {
  isVideoBufferSelector,
  isVideoPlayingSelector,
  videoControlsState,
  videoIsStartedState,
  videoMessageState,
  queueVideoState,
  isComplexFormHiddenState,
  isDropDownState,
} from "../../store/videoState";
// style
import styles from "./styles.module.scss";
// components
import VideoForm from "./VideoForm/VideoForm";
import PlayButton from "./PlayButton";
import SeekBarPane from "./SeekBarPane";
import { isVideoInChatOnlySelector } from "../../store/selectorState";
import { CloseIconVideo } from "../Chat/ChatHeader/CloseIconVideo";
import { ChatHeaderMenu } from "../Chat/ChatHeader/ChatHeaderMenu";
import { useViewedProgress } from "./videoHooks/useViewedProgress";
import IconSVG from "../IconImages/IconSVG";

const VideoPlayer = () => {
  const runOnceIsMute = useRef(false);
  const sendMessage = useSendMessage();
  const thisVideo = useRef();
  const [timeProgress, setTimeProgress] = useState(0);
  const videoMessage = useRecoilValue(videoMessageState);
  const videoForm = useRecoilValue(videoFormState);
  const botConfig = useRecoilValue(botConfigState);
  const setIsPlaying = useSetRecoilState(isVideoPlayingSelector);
  const [videoIsStarted, setVideoIsStarted] = useRecoilState(videoIsStartedState);
  const [videoBuffers, setVideoBuffers] = useRecoilState(isVideoBufferSelector);
  const [isVideoMinimized, setIsVideoMinimized] = useRecoilState(minimizedVideoState);
  const [duration, setDuration] = useState();
  const [detectLoop, setDetectLoop] = useState(false);
  const hideChat = useRecoilValue(hideChatState);
  const processBlock = useProcessBlocks();
  const [queueVideo, setQueueVideo] = useRecoilState(queueVideoState);
  const [allowShowForm, setAllowShowForm] = useState(false);
  const [pointerType, setPointerType] = useState("");
  const [playerReloadId, setPlayerReloadId] = useState(videoMessage.blockId);
  const isTabHasFocus = useIsTabHasFocus();
  const [enablePauseTab, setEnablePauseTab] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const isDropDown = useRecoilValue(isDropDownState);
  const isSettingsMode = useRecoilValue(isSettingsModeState);
  const [videoProgress, updateVideo] = useViewedProgress(timeProgress, detectLoop, duration);
  const { viewedProgress, maxViewedProgress, watchTime } = videoProgress;
  const refWatchTime = useRef(0);
  logRender("VideoPlayer");

  const isVideoInChatOnly = useRecoilValue(isVideoInChatOnlySelector);
  const [isChatMinimized, setIsChatMinimized] = useRecoilState(minimizedChatState);
  const [
    { volume, isMute, isMutePlaying, goToTime, isPlaying, displayVideoIcons },
    setVideoControls,
  ] = useRecoilState(videoControlsState);
  const isComplexForm = useRecoilState(isComplexFormHiddenState);
  const videoPlayerRef = useRef();
  const player = videoPlayerRef?.current?.firstChild.firstChild;
  const { hideVideoControls, minimizedVideoIcon } = botConfig;
  const preventPlaying =
    !videoMessage.targetContainer && isChatMinimized && !(isChatMinimized && isVideoMinimized);
  const preventPlayingRef = useRef(preventPlaying);

  useEffect(() => {
    preventPlayingRef.current = preventPlaying;
  }, [preventPlaying]);

  useEffect(() => {
    if (isPlaying) {
      setAllowShowForm(true);
    } else {
      setAllowShowForm(false);
    }
    if (isPlaying && !runOnceIsMute.current) {
      runOnceIsMute.current = true;
      setVideoControls((v) => ({
        ...v,
        isMutePlaying: false,
        goToTime: (viewedProgress > 1 && viewedProgress) || videoMessage.movie.start || 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoMessage, videoForm, isPlaying, setVideoControls]);

  useEffect(() => {
    if (player) player.currentTime = goToTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToTime]);

  useEffect(() => {
    if (
      Math.abs(timeProgress - duration) < 0.6 &&
      queueVideo &&
      !isMutePlaying &&
      videoMessage.nextBlockId === queueVideo._id
    ) {
      processBlock(queueVideo._id);
      setQueueVideo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeProgress, duration]);

  useEffect(() => {
    if (videoMessage.targetContainer) {
      setIsVideoMinimized(false);
    }
    if (videoPlayerRef.current) {
      setVideoControls((v) => ({
        ...v,
        width: videoPlayerRef.current?.firstChild?.offsetWidth || 400,
        height: videoPlayerRef?.current?.firstChild?.offsetHeight || 239,
        isMute: videoMessage.mute,
      }));
    }

    if (!videoMessage.autoStart || isMutePlaying) return;
    if (isVideoMinimized && isVideoInChatOnly) return;
    if (player) {
      if (preventPlaying) return;
      const promise = isTabHasFocus ? player.play() : undefined;
      if (promise !== undefined) {
        promise
          .then(() => {
            setVideoControls((v) => ({ ...v, isMutePlaying: false }));
            if (document.hasFocus()) {
              setIsPlaying(true);
              setIsVideoPlayed(true);
            } else {
              throw new Error();
            }
          })
          .catch((error) => {
            // https://developer.chrome.com/blog/autoplay/
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            setVideoControls((v) => ({
              ...v,
              isMutePlaying: true,
              goToTime: (viewedProgress > 1 && viewedProgress) || 0.01,
            }));
            runOnceIsMute.current = false;
            setIsPlaying(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoMessage, player]);

  useEffect(() => {
    if (preventPlaying) {
      setIsPlaying(false);
    }
  }, [player, preventPlaying, setIsPlaying]);

  useEffect(() => {
    return () => {
      setVideoBuffers(true);
    };
  }, [videoMessage.blockId, setVideoBuffers]);

  // pause video when tab is out of focus
  useEffect(() => {
    if (isMutePlaying && preventPlaying) {
      setEnablePauseTab(false);
    } else {
      setEnablePauseTab(!videoBuffers);
    }
    return () => {};
  }, [enablePauseTab, isMutePlaying, isVideoPlayed, preventPlaying, videoBuffers, videoIsStarted]);

  const handlePlay = useCallback(
    (focus) => {
      const video = videoPlayerUtils.getVideo();
      if (isVideoMinimized && isVideoInChatOnly) return;
      if (video && !preventPlayingRef.current) {
        if (focus && video && isVideoPlayed && !preventPlayingRef.current) video.play();
        if (!focus && video && isVideoPlayed) video.pause();
      }
    },
    [isVideoInChatOnly, isVideoMinimized, isVideoPlayed],
  );

  useEffect(() => {
    if (enablePauseTab) {
      handlePlay(isTabHasFocus);
    }
  }, [enablePauseTab, handlePlay, isTabHasFocus]);

  useEffect(() => {
    if (!videoPlayerRef?.current?.date || isDropDown || isSettingsMode) return () => {};
    let intervalChecking;
    const checkSec = () => {
      if (Date.now() - videoPlayerRef.current.date >= 3000) {
        setVideoControls((v) => ({ ...v, displayVideoIcons: false }));
        clearInterval(intervalChecking);
      }
    };
    intervalChecking = setInterval(checkSec, 1000);
    return () => {
      clearInterval(intervalChecking);
    };
  }, [videoPlayerRef?.current?.date, isDropDown, isSettingsMode, setVideoControls]);

  const isToMinimizedIcon =
    botConfig.type === "popup" &&
    isComplexForm &&
    hideChat &&
    isChatMinimized &&
    !videoMessage.targetContainer;

  const handleClickDiv = useCallback(() => {
    setIsVideoPlayed((prevState) => !prevState);
    if (!videoIsStarted) {
      setVideoIsStarted(true);
      sendMessage({
        ...videoMessage,
        viewedProgress,
        maxViewedProgress,
        watchTime,
        movie: { ...videoMessage.movie, end: duration },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setIsVideoPlayed,
    videoIsStarted,
    videoMessage,
    viewedProgress,
    maxViewedProgress,
    watchTime,
    duration,
    sendMessage,
  ]);

  const toggleMinimizedVideo = useCallback(() => {
    setIsVideoMinimized(false);
    setIsChatMinimized(false);
  }, [setIsVideoMinimized, setIsChatMinimized]);

  const onPlay = () => {
    if (Math.abs(timeProgress - duration) > 1 && viewedProgress > 1)
      setVideoControls((v) => ({ ...v, goToTime: viewedProgress }));
    if (!isMutePlaying && isTabHasFocus) setIsPlaying(true);
  };
  const onPause = () => {
    if (timeProgress > 1 && !isMutePlaying && watchTime - refWatchTime.current > 0.2) {
      updateVideo();
      refWatchTime.current = watchTime;
    }
    if (Math.abs(timeProgress - duration) < 1) return;
    setIsPlaying(false);
  };

  const onError = useCallback((err) => {
    logRender("Force re-play due to error");
    setPlayerReloadId(Date.now());
  }, []);

  const handleMouseOut = useCallback(() => {
    if (isSettingsMode) return;
    setVideoControls((v) => ({ ...v, displayVideoIcons: false }));
  }, [setVideoControls, isSettingsMode]);

  const handleMouseEnter = useCallback(() => {
    if (!displayVideoIcons) {
      setVideoControls((v) => ({ ...v, displayVideoIcons: true }));
    }
  }, [displayVideoIcons, setVideoControls]);

  const onMouseMove = useCallback(() => {
    handleMouseEnter();
    if (videoPlayerRef?.current) {
      videoPlayerRef.current.date = Date.now();
    }
  }, [handleMouseEnter, videoPlayerRef]);

  if (!videoMessage) return null;

  return (
    <>
      <CloseIconVideo />
      {!videoMessage.targetContainer && <ChatHeaderMenu />}
      <div
        ref={videoPlayerRef}
        className={clsx(
          styles.video_container,
          !videoMessage.targetContainer && styles.video_container_popup,
          "--harper-videoPlayer-container",
        )}
        onPointerDown={(e) => setPointerType(e.pointerType)}
        onClick={handleClickDiv}
        onMouseMove={onMouseMove}
        onMouseEnter={() => (pointerType !== "touch" ? handleMouseEnter() : null)}
        onMouseLeave={() => (pointerType !== "touch" ? handleMouseOut() : null)}
        onTouchEnd={handleMouseOut}
        onTouchStart={handleMouseEnter}
        style={{
          display: isVideoMinimized && "none",
          position: videoMessage.targetContainer && "initial",
        }}
      >
        <ReactPlayer
          id="react-video-player"
          key={playerReloadId}
          onPlay={onPlay}
          onPause={onPause}
          onProgress={(e) =>
            videoPlayerUtils.setProgress(
              player,
              videoMessage,
              e.playedSeconds,
              duration,
              setDetectLoop,
              setTimeProgress,
            )
          }
          ref={thisVideo}
          volume={volume / 100}
          muted={isMutePlaying ? true : isMute}
          onDuration={(e) => setDuration(e)}
          progressInterval={500}
          width="100%"
          height="100%"
          controls={!hideVideoControls}
          playing={isMutePlaying ? true : isTabHasFocus && isPlaying}
          url={`${videoMessage.src}#t=0.1`}
          onBuffer={(e) => setVideoBuffers(true)}
          onBufferEnd={(e) => setVideoBuffers(false)}
          onReady={(e) => {
            setVideoBuffers(false);
            setDetectLoop(false);
          }}
          onError={onError}
          className={clsx(
            styles.react_player,
            !videoMessage.targetContainer && styles.react_player_popup,
            "--harper-videoPlayer",
          )}
          playsinline={true}
        />
        <PlayButton detectLoop={detectLoop} timeProgress={timeProgress} />
        <SeekBarPane thisVideo={thisVideo} duration={duration} />

        {isToMinimizedIcon && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleMinimizedVideo();
            }}
            className={clsx(styles.close_icon_container, "--harper-videoPlayer-minimized-icon")}
          >
            <IconSVG.ThreeDotsIcon size={22} />
          </div>
        )}

        {allowShowForm && videoForm && !isMutePlaying && <VideoForm videoForm={videoForm} />}
      </div>
      {isVideoMinimized && isVideoInChatOnly && (
        <div
          onClick={toggleMinimizedVideo}
          className={clsx(styles.video_bubble, "--harper-videoPlayer-video-bubble")}
          style={{ backgroundImage: minimizedVideoIcon && `url(${minimizedVideoIcon})` }}
        >
          {!minimizedVideoIcon && <IconSVG.MinimizeVideoIcon size={35} />}
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
