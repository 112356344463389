import { useEffect } from "react";
import { pick } from "lodash-es";
import { useRecoilState, useRecoilValue } from "recoil";
import { videoMessageRefState, videoMessageState } from "../videoState";
import { blocksDataState, visitorStorageState } from "../states";
import { createVideoMessage } from "../../utils/blockHandling";

export const useStoreVideoMessageId = () => {
  const [videoMessageRef, setVideoMessageRef] = useRecoilState(videoMessageRefState);
  const [videoMessage, setVideoMessage] = useRecoilState(videoMessageState);
  const blocks = useRecoilValue(blocksDataState);
  const { variables = {} } = useRecoilValue(visitorStorageState);

  useEffect(() => {
    if (videoMessageRef) {
      const block = blocks.find((b) => b._id === videoMessageRef.blockId);
      if (block) {
        const recoveredVideoMessage = createVideoMessage(block, variables);
        const vidMessage = { ...recoveredVideoMessage, ...videoMessageRef };
        setVideoMessage(vidMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoMessage) {
      setVideoMessage(videoMessage);
      if (videoMessage.actorType !== "ADMIN") {
        setVideoMessageRef(pick(videoMessage, ["actorType", "blockId", "_id", "nextBlockId"]));
      }
    } else {
      setVideoMessageRef(null);
    }
  }, [setVideoMessage, setVideoMessageRef, videoMessage]);
};
