import React from "react";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={styles.container__footer}>
      <a className={styles.link} href="https://gretaharper.com/" target="_blank" rel="noreferrer">
        Powered by <span className={styles.logo}>Greta Harper</span>
      </a>
    </div>
  );
};

export default Footer;
