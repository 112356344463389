// Display as admin-view, and the chat is authored by visitor

import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTimeStamp } from "../../../../hooks";
import { visitorImage } from "../../../IconImages/images";
import { Avatar, ChatTooltip } from "../../../shared";

export const UserAvatar = ({ messageDate }) => {
  const date = useTimeStamp(messageDate);
  const tooltipText = `${date} · visitor`;
  const id = `message-${messageDate}-${uuidv4()}-visitor`;

  return (
    <>
      <Avatar
        id={id}
        altText={"User avatar"}
        imageSrc={visitorImage}
        className="--harper-adminMessage-avatar-container"
      />
      <ChatTooltip id={id} text={tooltipText} />
    </>
  );
};
