import { useRecoilCallback } from "recoil";
import { merge } from "lodash-es";
import { patchStorage } from "../api";
import { botConfigState, visitorInfoState, visitorStorageState } from "../states";
import { HarperStorageEvent, HarperProcess } from "../../utils/events";

export const useUpdateStorage = () => {
  const setter = useRecoilCallback(({ snapshot, set }) => (storage) => {
    set(visitorStorageState, (state) => {
      const newState = merge({}, state, storage);
      HarperProcess(HarperStorageEvent(newState.variables));
      return newState;
    });

    const updateDb = async () => {
      const botConfig = await snapshot.getPromise(botConfigState);
      const visitorInfo = await snapshot.getPromise(visitorInfoState);
      const { organisationAlias, alias: botAlias } = botConfig;

      await patchStorage(
        { variables: storage.variables, passwords: storage?.passwords || {} },
        {
          organisationAlias,
          botAlias,
          visitorId: visitorInfo._id,
        },
      );
    };

    updateDb();
  });

  return setter;
};
