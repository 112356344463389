import debugRoot from "debug";

const dbgRender = debugRoot(`harper:render:info`);
const dbgApi = debugRoot(`harper:api:info`);
const dbgStore = debugRoot(`harper:store:info`);
const dbgDebug = debugRoot(`harper:debug:info`);

export function logApi(...text) {
  dbgApi(...text);
}

export function logStore(...text) {
  dbgStore(...text);
}

export function logRender(...text) {
  dbgRender(...text);
}

export function logDebug(...text) {
  dbgDebug(...text);
}
