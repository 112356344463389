import React from "react";
import Markdown from "markdown-to-jsx";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { BotAvatar } from "../ChatAvatar";

const ChatUrl = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();
  const { imageSrc, text, src, browserUrl } = message;
  const url = browserUrl && new URL(browserUrl);
  const baseUrl = url && url.origin;
  if (!isAdmin && !imageSrc && !text) return null;

  return (
    <div>
      <div className={styles.container__chatVideo}>
        <div>
          <BotAvatar
            messageDate={messageDate}
            isAdmin={isAdmin}
            getBot={getBot}
            botAlias={message?.botAlias}
          />
        </div>
        <div className={styles.message}>
          {isAdmin && (
            <>
              {!text && !imageSrc && (
                <small>
                  Visitor was navigated to:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${src.startsWith("/") && baseUrl ? `${baseUrl}${src}` : src}`}
                  >
                    {message.src}
                  </a>
                </small>
              )}
            </>
          )}
          <a
            href={`${src.startsWith("/") && baseUrl ? `${baseUrl}${src}` : src}`}
            target="_blank"
            rel="noreferrer"
            className={clsx(styles.player_wrapper, "--harper-message-url")}
          >
            {text && <Markdown className={styles.text}>{text}</Markdown>}
            {imageSrc && (
              <div
                className={clsx(styles.player_wrapper, "--harper-message-video")}
                style={{
                  background: `pink url(${imageSrc})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChatUrl;
