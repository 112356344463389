// Display as visitor-view, and the chat is authored by admin

import React from "react";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { activeAdminState } from "../../../../store/states";
import { useTimeStamp } from "../../../../hooks";
import { adminBackupImage } from "../../../IconImages/images";
import { Avatar, ChatTooltip } from "../../../shared";

export const AdminAvatar = ({ adminId, messageDate }) => {
  const admin = useRecoilValue(activeAdminState(adminId));
  const date = useTimeStamp(messageDate);
  const tooltipText = `${date} · ${admin?.firstName} ${admin?.lastName}`;
  const id = `message-${messageDate}-${uuidv4()}-admin`;

  return (
    <>
      <Avatar
        id={id}
        altText={admin?.firstName || "admin avatar"}
        imageSrc={admin?.profileImageUrl ? admin.profileImageUrl : adminBackupImage}
        className="--harper-adminMessage-avatar-container"
      />
      <ChatTooltip id={id} text={tooltipText} />
    </>
  );
};
