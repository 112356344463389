import React, { Fragment } from "react";
import clsx from "clsx";

import Form from "./Form";

import styles from "./styles.module.scss";

const ChatForm = ({ message, isAdmin, bot, getBot }) => {
  const isAllButton =
    message.fields.filter((form) => form.type === "BUTTON").length === message.fields.length;

  const alreadySelected = message.fields.find((choice) => choice.selected);

  return (
    <div className={clsx(styles.container, "--harper-chatForm-container")}>
      <Form
        fields={message.fields}
        message={message}
        isAdmin={isAdmin}
        isAllButton={isAllButton}
        alreadySelected={alreadySelected}
        bot={bot}
        getBot={getBot}
      />
    </div>
  );
};

export default ChatForm;
