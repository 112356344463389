import React from "react";
import ReactDraggable from "react-draggable";

const Draggable = ({ children, setDisableClick, x, y, setX, setY }) => {
  const handleStop = (event, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
    setTimeout(() => setDisableClick(false), 0);
  };

  const handleDrag = (event) => {
    setDisableClick(true);
  };

  return (
    <ReactDraggable onDrag={handleDrag} onStop={handleStop} position={{ x, y }} allowAnyClick>
      <div>{children}</div>
    </ReactDraggable>
  );
};

export default Draggable;
