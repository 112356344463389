import React from "react";
import { createRoot } from "react-dom/client";
import { Chatbot } from "@lpgroup/harper-web-core-ui";

export {
  HarperProcess,
  HarperListen,
  HarperTriggerEvent,
  HarperBlockEvent,
  HarperMessageEvent,
} from "@lpgroup/harper-web-core-ui";

// TODO: deprecated, botType is in botConfig
export const Container = (props) => {
  const root = createRoot(document.querySelector(props.container));
  const newProps = { ...props, type: "container" };
  root.render(
    <React.StrictMode>
      <Chatbot {...newProps} />
    </React.StrictMode>,
  );
};

// TODO: deprecated, botType is in botConfig
export const Popup = (props) => {
  const root = createRoot(document.querySelector(props.container));
  const newProps = { ...props, type: "popup" };
  root.render(
    <React.StrictMode>
      <Chatbot {...newProps} />
    </React.StrictMode>,
  );
};

export const Render = (props) => {
  const root = createRoot(document.querySelector(props.container));
  root.render(
    <React.StrictMode>
      <Chatbot {...props} />
    </React.StrictMode>,
  );
};
