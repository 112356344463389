import { useRecoilValue, useSetRecoilState } from "recoil";
import React from "react";
import clsx from "clsx";

import Form from "./Form";
import styles from "../styles.module.scss";
import { logRender } from "../../../utils";
import {
  isComplexFormHiddenState,
  isVideoPlayingSelector,
  videoMessageState,
} from "../../../store/videoState";

const VideoForm = React.memo(({ videoForm }) => {
  logRender("VideoForm");
  const setIsPlaying = useSetRecoilState(isVideoPlayingSelector);
  const isComplexFormHidden = useRecoilValue(isComplexFormHiddenState);
  const videoMessage = useRecoilValue(videoMessageState);

  const { isPopOver = false } = videoForm;

  const isAllButton =
    videoForm.fields.filter((field) => field.type === "BUTTON").length === videoForm.fields.length;

  return (
    <div
      className={clsx(
        styles.videoForm_container,
        styles.videoForm_container__choices,
        isPopOver && !isAllButton && styles.videoForm_container__fields,
        videoMessage?.targetContainer && isComplexFormHidden && styles.adjustVideoBottom,
        "--harper-videoForm-container",
      )}
      onClick={() => setIsPlaying((v) => !v)}
    >
      <Form videoForm={videoForm} isAllButton={isAllButton} />
    </div>
  );
});

VideoForm.displayName = "VideoForm";

export default VideoForm;
