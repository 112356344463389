import { useEffect, useRef, useState } from "react";
import { useRecoilCallback } from "recoil";
import { logStore } from "../../utils";
import { HarperVisitorEvent, HarperAdminEvent, HarperProcess } from "../../utils/events";
import { usersGetService, visitorService, usersService } from "../api";
import {
  visitorInfoState,
  assignedAdminState,
  activeAdminsState,
  activeAdminState,
  latestReadMessageState,
} from "../states";
import { useServerEvents } from "./useServerEvents";

const getAdminUser = async ({ adminId }) => {
  try {
    return await usersGetService(adminId);
  } catch (err) {
    console.error("Error in getting adminId", err);
    return null;
  }
};

export const useVisitorInfo = () => {
  const [done, setDone] = useState(false);

  const visitorUpdater = useRecoilCallback(({ set }) => async (data) => {
    logStore("visitorInfo", data);
    set(visitorInfoState, data);
    const { assignedTo, latestReadMessage } = data;
    set(latestReadMessageState, latestReadMessage);
    if (assignedTo) {
      const adminPerson = await getAdminUser({ adminId: assignedTo });
      logStore("assignedAdmin", adminPerson);
      set(assignedAdminState, adminPerson);
      /* Do not confuse with usersUpdater activeAdmins
       * this catches the admin that login before the visitor is created
       * since channel.js filtered with assignedTo
       */
      set(activeAdminState(adminPerson._id), adminPerson);
      set(activeAdminsState, (state) =>
        state.indexOf(adminPerson._id) === -1 ? [...state, adminPerson._id] : state,
      );
      HarperProcess(HarperAdminEvent(adminPerson));
    } else {
      set(assignedAdminState, null);
    }
    HarperProcess(HarperVisitorEvent(data));
  });

  const usersUpdater = useRecoilCallback(({ set, snapshot }) => async (data) => {
    logStore("usersInfo", data);
    const admin = await snapshot.getPromise(assignedAdminState);
    if (data?._id === admin?._id) {
      set(assignedAdminState, data);
    }
    // admin that makes changes will be included, and no remove if offline
    set(activeAdminState(data._id), data);
    set(activeAdminsState, (state) =>
      state.indexOf(data._id) === -1 ? [...state, data._id] : state,
    );
    HarperProcess(HarperAdminEvent(data));
  });

  const init = useRecoilCallback(({ snapshot, set }) => async () => {
    const visitor = await snapshot.getPromise(visitorInfoState);
    const { activeAdmins, assignedTo } = visitor;

    const list = [];
    activeAdmins?.forEach((v) => {
      list.push(v._id);
      set(activeAdminState(v._id), v);
    });
    logStore("activeAdminState", list);
    set(activeAdminsState, list);
    if (assignedTo) {
      const adminPerson = await getAdminUser({ adminId: assignedTo });
      set(assignedAdminState, adminPerson);
    }
    setDone(true);
  });

  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      init();
    }
    return () => {};
  }, [init]);

  useServerEvents(visitorService, visitorUpdater);
  useServerEvents(usersService, usersUpdater);
  return done;
};
