import { useEffect, useRef } from "react";
import { useRecoilCallback } from "recoil";
import { useLocationCallback } from "../../hooks";
import { logStore } from "../../utils";
import { processTrigger } from "../utils";

export const useWindowEvent = (eventName, cb, filter = () => true) => {
  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      logStore(`${eventName} is on`);
      const handle = window.addEventListener(eventName, (e) => {
        if (filter(e)) {
          logStore(`${eventName} is emited`, e);
          cb(e);
        }
      });

      return () => {
        logStore(`${eventName} is off`);
        window.removeEventListener(eventName, handle);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useTriggers = () => {
  const setter = useRecoilCallback(({ snapshot, set }) => async (e) => {
    if (e.type === "locationchange") await processTrigger({ snapshot, set }, window.location.href);
    else if (e.type === "harper-trigger") await processTrigger({ snapshot, set }, e.detail.src);
  });

  useLocationCallback(setter);
  useWindowEvent("harper-trigger", setter);
  return setter;
};
