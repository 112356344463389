import React, { useState } from "react";
import clsx from "clsx";

import TextField from "./TextField";
import RadioButton from "./RadioButton";
import Checkbox from "./Checkbox";
import Markdown from "./Markdown";

import styles from "./styles.module.scss";
import FileUploadInput from "./FileUploadInput";
import PasswordField from "./PasswordField";

export const renderFieldByType = (field, { register, errors, disabled }, isAdmin) => {
  switch (field.type) {
    case "TEXTFIELD":
      return <TextField field={field} register={register} errors={errors} isAdmin={isAdmin} />;
    case "PASSWORD":
      return <PasswordField field={field} register={register} errors={errors} isAdmin={isAdmin} />;
    case "RADIO":
      return <RadioButton field={field} register={register} disabled={disabled} />;
    case "CHECKBOX":
      return <Checkbox field={field} register={register} disabled={disabled} />;
    case "MARKDOWN":
      return <Markdown field={field} errors={errors} />;
    case "FILEUPLOAD":
      return (
        <FileUploadInput field={field} register={register} errors={errors} isAdmin={isAdmin} />
      );
    default:
      return null;
  }
};

export const Truncate = ({ text }) => {
  const showChar = 26;
  const ellipsesText = "...";
  const [isHovering, setIsHovering] = useState(false);

  if (text.length > showChar) {
    const truncatedText = text.substr(0, showChar) + ellipsesText;

    const showTexts = () => {
      setIsHovering(true);
    };

    const hideTexts = () => {
      setIsHovering(false);
    };

    return (
      <span
        className={clsx(isHovering && styles.truncatedText)}
        onMouseEnter={showTexts}
        onMouseLeave={hideTexts}
      >
        {isHovering ? text : truncatedText}
      </span>
    );
  }

  return <span>{text}</span>;
};
