import objectPath from "object-path";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fileUploader } from "../utils";
import { videoFormState, visitorInfoState, visitorStorageState } from "../store/states";
import { useUpdateStorage } from "../store/hooks/useUpdateStorage";
import { useUpdateMessage } from "../store/hooks/useUpdateMessage";
import { useProcessBlocks } from "../store/hooks/useProcessBlocks";
import { useSendMessage } from "../store/hooks/useSendMessage";

const UPLOAD_FILE_LIMIT = 10;
const UPLOAD_LIMIT_VAR = "fileUploadLimit";

const useFormOnSubmit = () => {
  const processBlock = useProcessBlocks();
  const updateMessage = useUpdateMessage();
  const updateStorage = useUpdateStorage();
  const sendMessage = useSendMessage();
  const setVideoForm = useSetRecoilState(videoFormState);
  const visitorInfo = useRecoilValue(visitorInfoState);
  const { organisationAlias, botAlias, _id: visitorId } = visitorInfo;
  const visitorStorage = useRecoilValue(visitorStorageState);

  const notifyMessage = (text) => {
    const txtMsg = {
      _id: uuidv4(),
      type: "MARKDOWN",
      text,
      actorType: "BOT",
    };
    sendMessage(txtMsg, { visitorId, organisationAlias, botAlias });
  };

  const onSubmit = async (data, message) => {
    const button = message.fields.find((field) => field.type === "BUTTON");
    try {
      const storageObj = { variables: {}, passwords: {} };
      const updatedMessage = {
        _id: message._id,
        actorType: message.actorType,
        text: message.text,
        fields: await Promise.all(
          message.fields.map(async (field) => {
            if (field.target) {
              const current = objectPath.get(data, field.target);
              // we only support one button on a form.
              if (field.type === "BUTTON") {
                return { ...field, selected: true };
              }

              if (current && Array.isArray(current) && field.type === "CHECKBOX") {
                const isSelected = current.find((el) => el === field.value) || false;

                return { ...field, selected: isSelected && true };
              }

              if (field.type === "RADIO") {
                const isSelected = current === field.value;
                return { ...field, selected: isSelected && true };
              }

              if (field.type === "TEXTFIELD") {
                return { ...field, value: current, selected: true };
              }

              if (field.type === "PASSWORD") {
                return { ...field, value: current, selected: true };
              }

              if (current && !current?.cloudUrl && field.type === "FILEUPLOAD") {
                const fileUploadLimit =
                  objectPath.get(visitorStorage.variables, UPLOAD_LIMIT_VAR) || 0;
                if (!fileUploadLimit || fileUploadLimit <= UPLOAD_FILE_LIMIT) {
                  try {
                    const file = current[0];
                    const fileUpload = await fileUploader({ file, organisationAlias, botAlias });
                    const { cloudUrl } = fileUpload;
                    const keyMap = fileUpload;
                    Object.keys(keyMap).forEach((key) => {
                      objectPath.set(storageObj.variables, `${field.target}.${key}`, keyMap[key]);
                    });
                    const storageObjFileUpdate = { variables: {} };
                    objectPath.set(
                      storageObjFileUpdate.variables,
                      UPLOAD_LIMIT_VAR,
                      fileUploadLimit + 1,
                    );
                    updateStorage(storageObjFileUpdate);
                    return { ...field, value: cloudUrl, selected: true };
                  } catch {
                    notifyMessage(
                      "*Configuration error: Fail to upload file. Please contact admin.*",
                    );
                    return field;
                  }
                }
                notifyMessage("*Limitation error: Fail to upload file. Please contact admin.*");
                return { ...field, selected: true };
              }
            }

            return field;
          }),
        ),
      };

      updatedMessage.fields.forEach((field) => {
        if (field.type === "TEXTFIELD") {
          objectPath.set(storageObj.variables, field.target, field.value);
        }
        if (field.type === "PASSWORD") {
          objectPath.set(storageObj.passwords, field.target, field.value);
        }
        if (field.type === "CHECKBOX" && field.selected) {
          objectPath.push(storageObj.variables, field.target, field.value);
        }

        if (field.type === "RADIO" && field.selected) {
          objectPath.set(storageObj.variables, field.target, field.value);
        }

        if (field.type === "BUTTON" && field.selected && field.target && field.value) {
          objectPath.set(storageObj.variables, field.target, field.value);
        }
      });

      if (message.type === "FORM") {
        updateMessage(updatedMessage);
        updateStorage(storageObj);
        processBlock(button.nextBlockId, {
          storageObj: storageObj.variables,
          storagePasswords: storageObj.passwords,
        });
      }
      if (message.type === "VIDEOFORM") {
        sendMessage({ ...updatedMessage, type: message.type, _id: uuidv4() });
        updateStorage(storageObj);
        processBlock(button.nextBlockId, {
          storageObj: storageObj.variables,
          storagePasswords: storageObj.passwords,
        });
        setVideoForm(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return onSubmit;
};

export default useFormOnSubmit;
