import React from "react";

import Chat from "../Chat";
import ChatFooter from "../Chat/ChatFooter/index";

const Container = () => {
  return (
    <>
      <Chat />
      <ChatFooter />
    </>
  );
};

export default Container;
