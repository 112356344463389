export const scrollIntoView = (ref) => {
  if (ref.current) {
    const currentScrollHeight = ref.current.scrollHeight;
    // eslint-disable-next-line no-param-reassign
    ref.current.scrollTop = currentScrollHeight;
    // need to check if scrollHeight have changed it can be done after all the render.
    setTimeout(() => {
      try {
        if (ref.current.scrollHeight !== currentScrollHeight) scrollIntoView(ref);
      } catch {
        /* */
      }
    }, 100);
    setTimeout(() => {
      try {
        if (ref.current.scrollHeight !== currentScrollHeight) scrollIntoView(ref);
      } catch {
        /* */
      }
    }, 1000);
  }
};
