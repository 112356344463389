import React from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { hideVideoState } from "../../../store/states";
import { videoControlsState, videoMessageState } from "../../../store/videoState";
import { ChatHeaderDetails } from "./ChatHeaderDetails";
import VideoPlayer from "../../VideoPlayer";

const ChatHeader = () => {
  const videoMessage = useRecoilValue(videoMessageState);
  const { displayVideoPlayer } = useRecoilValue(videoControlsState);
  const hideVideo = useRecoilValue(hideVideoState);

  return (
    <div className={clsx(styles.container__header, "--harper-chatHeader-container")}>
      {videoMessage && !videoMessage.targetContainer && !hideVideo && displayVideoPlayer ? (
        <div className={styles.header_content_inner}>
          <VideoPlayer />
        </div>
      ) : (
        <ChatHeaderDetails />
      )}
    </div>
  );
};

export const ChatVideoOnly = () => {
  return (
    <div className={clsx(styles.container__header_videoOnly, "--harper-chatHeader-container")}>
      <VideoPlayer />
    </div>
  );
};

export default ChatHeader;
