/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from "react";

const useResizeBotHeight = (divRef) => {
  const [isResizing, setIsResizing] = useState(false);
  const startYRef = useRef(null);
  const startHeightRef = useRef(null);

  const handleResizeHeight = (e) => {
    setIsResizing(true);
    startYRef.current = e.clientY;
    startHeightRef.current = parseInt(window.getComputedStyle(divRef.current).height, 10);
    divRef.current.style.userSelect = "none";
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
    divRef.current.style.userSelect = "text";
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newHeight = startHeightRef.current - (e.clientY - startYRef.current);
      divRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleResizeEnd);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleResizeEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);
  return handleResizeHeight;
};

export default useResizeBotHeight;
