import {
  MdOutlineCheckCircle,
  MdExpandMore,
  MdOutlineClose,
  MdSend,
  MdOutlineVolumeUp,
  MdVolumeOff,
  MdOutlineMovie,
  MdFullscreen,
  MdMoreHoriz,
  MdReplay,
  MdMoreVert,
  MdOutlineRestartAlt,
  MdOutlineVolumeOff,
  MdSettings,
} from "react-icons/md";
import { FiAirplay } from "react-icons/fi";
import { FaCirclePlay } from "react-icons/fa6";
import { AiOutlineMessage } from "react-icons/ai";

// Search here https://react-icons.github.io/react-icons/

const IconSVG = {
  // Header Icons
  OptionIcon: MdMoreVert,
  MinimizeIcon: MdExpandMore,

  VideoShrinkIcon: MdOutlineClose,
  VideoExpandIcon: FiAirplay,

  CloseIcon: MdOutlineClose,
  VolumeUpIcon: MdOutlineVolumeUp,
  VolumeOffIcon: MdVolumeOff,
  VideoReplayIcon: MdReplay,
  FullScreenIcon: MdFullscreen,

  // Header Option Icons
  RestartChatIcon: MdOutlineRestartAlt,
  DisableSoundIcon: MdOutlineVolumeOff,
  EnableSoundIcon: MdOutlineVolumeUp,
  SettingCogIcon: MdSettings,

  // Icons
  DeliverIcon: MdOutlineCheckCircle,
  PlayIcon: FaCirclePlay,
  SendIcon: MdSend,
  // chatbox is minimize
  MinimizeChatIcon: AiOutlineMessage,
  MinimizeVideoIcon: MdOutlineMovie,
  ThreeDotsIcon: MdMoreHoriz,
};

export default IconSVG;
