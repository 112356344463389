import React from "react";
import styles from "./styles.module.scss";
import { adminBackupImage } from "../../IconImages/images";

const renderName = (name, limit = 16) => {
  if (name.length <= limit) return name;
  const nameParts = name.split(" ");
  while (nameParts.join(" ").length > limit && nameParts.length > 1) {
    nameParts.pop();
  }
  return nameParts.join(" ");
};
export const TemplatePrimary = ({ admin }) => {
  const fullName = `${admin?.firstName} ${admin?.lastName}`;
  const getAdminBadge = (admn) => {
    if (!admn?.isOnline) return "#26242c";
    return admn?.onlineStatus === "ONLINE" ? "#44b700" : "#26242c";
  };

  return (
    <div className={styles.header_content}>
      <div className={styles.header_right_content}>
        <div
          style={{
            backgroundImage: admin?.profileImageUrl
              ? `url(${admin.profileImageUrl})`
              : `url(${adminBackupImage})`,
          }}
          className={styles.admin_img}
        />
        <div className={styles.avatar_badge} style={{ backgroundColor: getAdminBadge(admin) }} />
        <div className={styles.text_wrapper}>
          <span className={styles.upper_text}>Chat with</span>
          <span
            style={{
              fontSize: renderName(fullName) > 13 ? "20px" : "28px",
              whiteSpace: "nowrap",
            }}
            className={styles.lower_text}
          >
            {admin ? renderName(fullName) : <span className={styles.logo}>Greta Harper</span>}
          </span>
        </div>
      </div>
    </div>
  );
};
