import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";

import { BotAvatar } from "../ChatAvatar";
import BotMessage from "../ChatMessage/BotMessage";
import styles from "./styles.module.scss";

export const ChatEvent = ({ message, isAdmin, getBot }) => {
  if (message.action === "CALL-ADMIN" && isAdmin)
    return <UserAskForHelp message={message} isAdmin={isAdmin} getBot={getBot} />;
  if (!message.text) return null;
  return <BotMessage message={message} isAdmin={isAdmin} getBot={getBot} />;
};

const UserAskForHelp = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <>
      <div className={clsx(styles.container__botmessage, "--harper-botMessage-container")}>
        <BotAvatar
          messageDate={messageDate}
          isAdmin={isAdmin}
          getBot={getBot}
          botAlias={message?.botAlias}
        />
        <div className={clsx(styles.bot_message, "--harper-botMessage-text-container")}>
          <div>User asked for help</div>
        </div>
      </div>
      {message?.text && (
        <div className={clsx(styles.container__botmessage, "--harper-botMessage-container")}>
          <div className={clsx(styles.hidden)}>
            <BotAvatar
              messageDate={messageDate}
              isAdmin={isAdmin}
              getBot={getBot}
              botAlias={message?.botAlias}
            />
          </div>
          <div className={clsx(styles.bot_message, "--harper-botMessage-text-container")}>
            <Markdown>{message.text}</Markdown>
          </div>
        </div>
      )}
    </>
  );
};
