import { useRecoilCallback } from "recoil";
import { notifyTypingSvc } from "../api";
import { isAdminTypingState } from "../states";
import { useServerEvents } from "./useServerEvents";

export const useNotifyIsTyping = () => {
  const updater = useRecoilCallback(({ set }) => async (data) => {
    set(isAdminTypingState, !!data.admin?.isTyping);
  });

  useServerEvents(notifyTypingSvc, updater);
};
