import React, { useState } from "react";
import clsx from "clsx";
import objectPath from "object-path";
import { useUpdateStorage } from "../../../store/hooks/useUpdateStorage";

import { useProcessBlocks, useUpdateMessage } from "../../../store/hooks";
import { Truncate } from "./utils";
import styles from "./styles.module.scss";

const ChoiceUser = ({ choice, message }) => {
  const processBlock = useProcessBlocks();
  const updateMessage = useUpdateMessage();
  const updateStorage = useUpdateStorage();
  const [isBusy, setIsBusy] = useState(false);

  const handleClick = async () => {
    if (isBusy) return;
    setIsBusy(true);
    try {
      const updatedMessage = {
        _id: message._id,
        fields: message.fields
          .filter((field) => field._id === choice._id)
          .map((field) => {
            return { ...field, selected: true };
          }),
      };

      const storageObj = { variables: {} };
      updatedMessage.fields.forEach((field) => {
        if (field.type === "BUTTON" && field.selected) {
          objectPath.set(storageObj.variables, field.target, field.value || field.title);
        }
      });

      updateMessage(updatedMessage);
      updateStorage(storageObj);
      processBlock(choice.nextBlockId, { storageObj: storageObj.variables });
    } catch (error) {
      console.error(error);
    }
    await new Promise((success) => {
      setTimeout(() => {
        setIsBusy(false);
        success();
      }, 1500);
    });
  };

  return (
    <div
      onClick={handleClick}
      type="button"
      className={clsx(
        styles.choice,
        styles.choice_user,
        choice.selected && styles.selected,
        "--harper-chatForm-choice-container",
      )}
    >
      <Truncate text={choice.title} />
    </div>
  );
};

const ChoiceAdmin = ({ choice, disabled }) => {
  return (
    <div
      disabled={disabled}
      className={clsx(
        styles.choice,
        styles.choice_admin,
        choice.selected && styles.selected,
        disabled && styles.disabled,
      )}
    >
      <Truncate text={choice.title} />
    </div>
  );
};

const Choice = (params) =>
  params.isAdmin ? <ChoiceAdmin {...params} /> : <ChoiceUser {...params} />;

export default Choice;
