/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import {
  botConfigState,
  videoFormState,
  enableNotifySoundState,
  isSettingsModeState,
  visitorStorageState,
  activeAdminsState,
  activeAdminState,
  visitorInfoState,
} from "../../../store/states";
import { useProcessBlocks } from "../../../store/hooks";
import { HarperProcess, HarperTriggerEvent } from "../../../utils/events";
import styles from "./styles.module.scss";
import {
  videoMessageState,
  backupVideoMessageState,
  isDropDownState,
} from "../../../store/videoState";
import { useUpdateStorage } from "../../../store/hooks/useUpdateStorage";
import { getChatMessages, setAssignAdmin, sendMessage } from "../../../store/api";
import { adminBackupImage } from "../../IconImages/images";
import { ButtonSVG } from "../../shared";
import IconSVG from "../../IconImages/IconSVG";

export const OptionIcon = ({ position = "bottom", ...props }) => {
  const botConfig = useRecoilValue(botConfigState);
  const processBlock = useProcessBlocks();
  const setVideoForm = useSetRecoilState(videoFormState);
  const [videoMessage, setVideoMessage] = useRecoilState(videoMessageState);
  const setBackupVideoMessage = useSetRecoilState(backupVideoMessageState);
  const [isDropDown, setIsDropDown] = useRecoilState(isDropDownState);
  const [enableSound, setEnableSound] = useRecoilState(enableNotifySoundState);
  const [isSettingsMode, setIsSettingsMode] = useRecoilState(isSettingsModeState);
  const [settingView, setViewSettings] = useState("changeEmail");

  const resetBlock = () => {
    const { hash, pathname } = window.location;
    setBackupVideoMessage(null);
    setVideoForm(null);
    setVideoMessage(null);
    localStorage.removeItem(`${botConfig?.organisationAlias}-${botConfig.alias}`);
    processBlock(botConfig.startBlockId);
    setIsDropDown(false);

    if (hash) {
      return HarperProcess(HarperTriggerEvent(hash));
    }
    return HarperProcess(HarperTriggerEvent(pathname));
  };

  const toogleEnableSound = () => {
    setEnableSound(!enableSound);
    setIsDropDown(false);
  };

  useEffect(() => {
    const offIsDrop = () => {
      setIsDropDown(false);
    };
    if (isDropDown) {
      // timeout means do not include the first click
      setTimeout(() => {
        window.addEventListener("click", offIsDrop, { once: true });
      });
    }
  }, [isDropDown, setIsDropDown]);

  const adminPassword = "ferdnand";
  const clearCache = () => {
    console.log("Clear localstorage.");
    localStorage.clear();
    console.log("Clear sessionstorage.");
    const debug = localStorage.getItem("debug");
    sessionStorage.clear();
    localStorage.setItem("debug", debug);
    localStorage.setItem("admin", adminPassword);

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    console.log("Clear cookies.");
    const allCookies = document.cookie.split(";");
    for (let i = 0; i < allCookies.length; i += 1)
      document.cookie = `${allCookies[i]}=;expires=${new Date(0).toUTCString()}`;

    console.log("Reload");
    window.location.reload();
  };

  const toggleDebug = () => {
    const debug = localStorage.getItem("debug");
    if (debug) {
      console.log("Disable debug.");
      window.localStorage.removeItem("debug");
    } else {
      console.log("Enable debug.");
      console.log('window.localStorage.setItem("debug", "harper:*:*");');
      window.localStorage.setItem("debug", "harper:*:*");
    }
  };

  const toggle = () => {
    setIsDropDown(true);
  };

  const toogleSettingsMode = () => {
    setIsDropDown(false);
    setIsSettingsMode((v) => !v);
  };

  const handleView = (e) => {
    setViewSettings(e.target.value);
  };

  return (
    <>
      <ButtonSVG
        onClick={toggle}
        className="--harper-chatHeader-option-icon"
        iconSVG={IconSVG.OptionIcon}
        {...props}
      />

      {isDropDown && (
        <div className={clsx(styles.toggle_list, position === "top" && styles.adjustTop100)}>
          <div onClick={resetBlock} className={styles.toggle_item}>
            <div className={styles.toggle_icon}>
              <IconSVG.RestartChatIcon />
            </div>
            <span className={styles.toggle_text}>Restart the chat</span>
          </div>
          <div onClick={toogleEnableSound} className={styles.toggle_item}>
            <div className={styles.toggle_icon}>
              {enableSound ? <IconSVG.DisableSoundIcon /> : <IconSVG.EnableSoundIcon />}
            </div>
            <span className={styles.toggle_text}>
              {enableSound ? "Disable message sound" : "Enable message sound"}
            </span>
          </div>
          <div onClick={toogleSettingsMode} className={styles.toggle_item}>
            <div className={styles.toggle_icon}>
              <IconSVG.SettingCogIcon />
            </div>
            <span className={styles.toggle_text}>Settings</span>
          </div>
          {localStorage.getItem("admin") === adminPassword && (
            <>
              <div onClick={clearCache} className={styles.toggle_item}>
                <div className={styles.toggle_icon}>
                  <IconSVG.RestartChatIcon />
                </div>
                <span className={styles.toggle_text}>admin - clear all cache</span>
              </div>
              <div onClick={toggleDebug} className={styles.toggle_item}>
                <div className={styles.toggle_icon}>
                  <IconSVG.RestartChat />
                </div>
                <span className={styles.toggle_text}>admin - toggle debug</span>
              </div>
            </>
          )}
        </div>
      )}

      {isSettingsMode && (
        <div
          className={clsx(
            styles.settings_mode,
            position === "top" && styles.settingsTop,
            position === "top" && videoMessage?.targetContainer && styles.settingsTopExtra,
            "--harper-chatHeader-settings-wrap",
          )}
        >
          <div className={styles.settings_mode_close_wrap}>
            <div>
              <IconSVG.SettingCogIcon />
              Settings
            </div>
            <button onClick={toogleSettingsMode}>
              {/* Option inside, close icon  */}
              <IconSVG.CloseIcon size={22} />
            </button>
          </div>
          <div className={clsx(styles.settings_content_wrap)}>
            <div className={clsx(styles.settings_content_menu)}>
              <button onClick={handleView} className="--harper-viewBtn" value={"changeEmail"}>
                Change email
              </button>
              <button onClick={handleView} className="--harper-viewBtn" value={"assignAdmin"}>
                Assign admin
              </button>
              <button onClick={handleView} className="--harper-viewBtn" value={"chatLog"}>
                Chat log
              </button>
              <button onClick={handleView} className="--harper-viewBtn" value={"viewCart"}>
                View cart
              </button>
              {/* <button onClick={handleView} className="--harper-viewBtn" value={"recoverSession"}>
                Recover session
              </button> */}
            </div>
            <div className={clsx(styles.settings_selected_view)}>
              <SettingsView settingView={settingView} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SettingsView = ({ settingView }) => {
  const visitorStorage = useRecoilValue(visitorStorageState);
  const updateStorage = useUpdateStorage();
  const emailAdd = visitorStorage?.variables?.email || "";
  const setIsSettingsMode = useSetRecoilState(isSettingsModeState);
  const { register, handleSubmit, formState, setFocus } = useForm();
  const { errors } = formState;

  useEffect(() => {
    if (settingView === "changeEmail") {
      setFocus("email", { shouldSelect: true });
    }
    const btns = document.querySelectorAll(".--harper-viewBtn");
    btns.forEach((btn) => {
      if (btn.value === settingView) {
        // eslint-disable-next-line no-param-reassign
        btn.style.backgroundColor = "#f7e9e9";
      } else {
        // eslint-disable-next-line no-param-reassign
        btn.style.backgroundColor = "white";
      }
    });
  }, [settingView, setFocus]);

  const onSubmit = (data) => {
    const storageObj = { variables: { email: data.email } };
    updateStorage(storageObj);
    setIsSettingsMode(false);
  };

  if (settingView === "changeEmail") {
    return (
      <div className={clsx(styles.setting_change_email_wrap)}>
        <div>Update your email.</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please enter a valid email",
              },
            })}
            defaultValue={emailAdd || ""}
            type="text"
          ></input>
          <div>
            &nbsp;
            {errors?.email?.message}
          </div>
          <button type="submit">Save</button>
        </form>
      </div>
    );
  }
  if (settingView === "assignAdmin") {
    return <AssignAdmin />;
  }
  if (settingView === "chatLog") {
    return <DownloadChatLog />;
  }

  return <div className={styles.notImplemented}>Not implemented in this chatbot!</div>;
};

const AssignAdmin = () => {
  const adminIds = useRecoilValue(activeAdminsState);
  return (
    <div className={clsx(styles.assignAdminWrap)}>
      <div>Choose admin</div>
      {adminIds.map((adminId, i) => (
        <MapAdminList key={i} adminId={adminId} />
      ))}
    </div>
  );
};

const DownloadChatLog = () => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const botConfig = useRecoilValue(botConfigState);
  const visitor = useRecoilValue(visitorInfoState);
  const { organisationAlias, alias: botAlias } = botConfig;
  const visitorStorage = useRecoilValue(visitorStorageState);
  const setIsSettingsMode = useSetRecoilState(isSettingsModeState);
  const setToDeleteLog = () => {
    setShowConfirm(true);
  };

  const handleDeleteLogs = async () => {
    const text =
      "Note: The visitor has requested the removal of all his/her information, this is in compliance with GDPR";
    const prms = { organisationAlias, botAlias, visitorId: visitor._id };
    const msg = { text, actorType: "HIDDEN", type: "MARKDOWN" };
    await sendMessage(msg, prms);
    localStorage.clear();
    window.location.reload();
  };
  const handleDownloadLogs = async () => {
    const params = { visitorId: visitor._id, organisationAlias, botAlias, limit: 50000 };
    const { data } = await getChatMessages(params);
    const infoAll = {
      visitor,
      messages: data,
      variables: visitorStorage,
    };
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(infoAll, null, 2),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "chat-log.json";
    link.click();
    setIsSettingsMode(false);
  };
  return (
    <div className={clsx(styles.downloadChatLogWrap)}>
      {showConfirm ? (
        <>
          <div>Remove all your logs in our database? We will create a new chat for you. </div>
          <button
            className={clsx(styles.downloadLogBtn, styles.mInline5)}
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
          <button className={clsx(styles.confirmDeleteLog)} onClick={handleDeleteLogs}>
            Confirm
          </button>
        </>
      ) : (
        <>
          <button className={clsx(styles.downloadLogBtn)} onClick={handleDownloadLogs}>
            Download chat logs
          </button>
          <button onClick={setToDeleteLog} className={clsx(styles.downloadLogBtn)}>
            Delete all logs
          </button>
        </>
      )}
    </div>
  );
};

const MapAdminList = ({ adminId }) => {
  const admin = useRecoilValue(activeAdminState(adminId));
  const botConfig = useRecoilValue(botConfigState);
  const visitorInfo = useRecoilValue(visitorInfoState);
  const setIsSettingsMode = useSetRecoilState(isSettingsModeState);

  const handleSetAdmin = async (id) => {
    const data = { adminId: id, botConfig, visitorId: visitorInfo._id };
    await setAssignAdmin(data);
    setIsSettingsMode(false);
  };

  return (
    <div className={clsx(styles.mapAdminWrap)}>
      <div>
        <img src={admin?.profileImageUrl ? admin.profileImageUrl : adminBackupImage} />
        <div>{admin?.firstName}</div>
      </div>
      <button className={clsx(styles.btnSetMe)} onClick={() => handleSetAdmin(admin._id)}>
        SET ME
      </button>
    </div>
  );
};
