import React, { useRef, useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

import {
  botConfigState,
  visitorInfoState,
  directChatStatusState,
  isTypingState,
  messagesState,
  latestReadMessageState,
} from "../../../store/states";
import { useSendMessage } from "../../../store/hooks";

import styles from "./styles.module.scss";
import { createNotifyTyping, setLastestReadMessage } from "../../../store/api";
import IconSVG from "../../IconImages/IconSVG";

const LIMIT = 50000;

const MessageForm = () => {
  const isDirectChatEnable = useRecoilValue(directChatStatusState);
  const visitorInfo = useRecoilValue(visitorInfoState);
  const botConfig = useRecoilValue(botConfigState);
  const sendMessageFunc = useSendMessage();
  const textareaRef = useRef(null);
  const [characterLimit, setCharacterLimit] = useState(LIMIT);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [isUserTyping, setIsTyping] = useRecoilState(isTypingState);
  const messagesIds = useRecoilValue(messagesState);
  const [latestReadId, setLatestReadId] = useRecoilState(latestReadMessageState);

  useEffect(() => {
    setLimitExceeded(characterLimit < 0);
    return () => {
      setIsTyping(false);
    };
  }, [characterLimit, setIsTyping]);

  if (!isDirectChatEnable) return null;

  const handleNotifyIsTyping = (isTyping = false) => {
    createNotifyTyping(isTyping, visitorInfo);
    const lastMessageId = messagesIds[messagesIds.length - 1];
    if (latestReadId !== lastMessageId) {
      setLastestReadMessage(lastMessageId, visitorInfo);
      setLatestReadId(lastMessageId);
    }
    setIsTyping(isTyping);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const text = textareaRef.current.value.trim();
    if (!text) return;
    if (text.length > LIMIT) return;
    handleNotifyIsTyping(false);
    const { organisationAlias, alias: botAlias } = botConfig;
    const visitorId = visitorInfo._id;
    const txtMsg = {
      _id: uuidv4(),
      type: "MARKDOWN",
      text,
      actorType: "VISITOR",
    };
    sendMessageFunc(txtMsg, { visitorId, organisationAlias, botAlias });
    textareaRef.current.value = "";
    setCharacterLimit(LIMIT);
  };

  const handleChange = (event) => {
    const text = event.target.value;
    if (text.length === 0 && isUserTyping) handleNotifyIsTyping(false);
    if (text.length > 0 && !isUserTyping) handleNotifyIsTyping(true);
    setCharacterLimit(LIMIT - text.length);
  };

  const handleKey = (e) => {
    // new line
    if ((e.keyCode === 13 || e.which === 13) && (e.altKey || e.ctrlKey)) {
      const myField = textareaRef.current;
      const myValue = "\r\n";
      if (myField.selectionStart !== undefined) {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;
        myField.value =
          myField.value.substring(0, startPos) +
          myValue +
          myField.value.substring(endPos, myField.value.length);
        myField.selectionStart = startPos + 1;
        myField.selectionEnd = startPos + 1;
        // scroll to selectionStart
        myField.blur();
        myField.focus();
      }
    }

    // submit
    if ((e.keyCode === 13 || e.which === 13) && !e.altKey && !e.ctrlKey && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  return (
    <div className={clsx(styles.form_container, "--harper-mainForm-container")}>
      <div className={styles.line}></div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextareaAutosize
          ref={textareaRef}
          onKeyDown={handleKey}
          onKeyUp={handleChange}
          maxRows={5}
          autoComplete="off"
          placeholder="Enter your message here..."
          className={clsx(styles.input, "--harper-mainForm-inputField")}
          name="message"
        />
        {characterLimit < LIMIT ? (
          <span className={clsx(styles.limit, limitExceeded ? styles.max : styles.normal)}>
            {characterLimit}
          </span>
        ) : null}
        <button
          disabled={limitExceeded}
          type="submit"
          className={clsx(
            styles.send_bubble,
            limitExceeded && styles.disabled,
            "--harper-mainForm-submitButton",
          )}
        >
          <div className={styles.send_icon}>
            <IconSVG.SendIcon size={24} />
          </div>
        </button>
      </form>
    </div>
  );
};

export default MessageForm;
