import React, { useState, Fragment, memo, useRef, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";

import styles from "./styles.module.scss";

import {
  messagesState,
  botConfigState,
  messageState,
  visitorInfoState,
  minimizedChatState,
  isAdminTypingState,
  messageCanPaginateState,
} from "../../../store/states";
import { logRender, logStore, scrollIntoView } from "../../../utils";
import messageRenderer from "../../../utils/messageRenderer";
import { getChatMessages } from "../../../store/api";
import { videoMessageState } from "../../../store/videoState";
import LoadingSVG from "../../IconImages/LoadingSVG";

// eslint-disable-next-line react/display-name
const ChatBodyMessage = memo(({ messageId }) => {
  logRender("ChatBodyMessage");
  const message = useRecoilValue(messageState(messageId));
  return <>{messageRenderer(message)}</>;
});

const ChatBody = () => {
  const chatState = useRecoilValue(messagesState);
  const { type } = useRecoilValue(botConfigState);
  const chatBodyRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [canPaginate, setCanPaginate] = useRecoilState(messageCanPaginateState);
  const minimizedChat = useRecoilValue(minimizedChatState);
  const videoMessage = useRecoilValue(videoMessageState);
  const runOnce = useRef(false);
  const isAdminTyping = useRecoilValue(isAdminTypingState);
  logRender("ChatBody", chatState.length);

  const fetchMessage = useRecoilCallback(({ snapshot, set }) => async (event) => {
    const botConfig = await snapshot.getPromise(botConfigState);
    const { organisationAlias, alias: botAlias } = botConfig;
    const visitor = await snapshot.getPromise(visitorInfoState);
    if (event) chatBodyRef.current.scrollTop = 5;
    setIsFetching(true);
    const { data: chatMessages } = await getChatMessages({
      visitorId: visitor._id,
      organisationAlias,
      botAlias,
      skip: chatState.length,
    });
    const prevHeight = chatBodyRef.current.scrollHeight;
    const list = [];
    chatMessages.forEach((m) => {
      list.push(m._id);
      set(messageState(m._id), m);
    });

    list.reverse();
    if (list.length < 10) {
      setCanPaginate(false);
    }
    logStore("messagesPaginate", list);
    set(messagesState, (v) => [...list.filter((id) => !v.includes(id)), ...v]);
    setIsFetching(false);
    setTimeout(() => {
      const isWebkitBased = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isWebkitBased) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight - prevHeight + 156;
      }
    });
  });

  useEffect(() => {
    if (!isFetching) {
      scrollIntoView(chatBodyRef);
    } else {
      chatBodyRef.current.style.scrollBehavior = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState.length, minimizedChat, videoMessage, isAdminTyping]);

  useEffect(() => {
    if (!runOnce.current) {
      if (chatState.length < 10) {
        setCanPaginate(false);
      }
      runOnce.current = true;
    }
  }, [chatState, setCanPaginate]);

  const pagination = ({ previousPosition, currentPosition, event }) => {
    if (previousPosition === "above" && currentPosition === "inside" && event) {
      if (canPaginate) {
        fetchMessage();
      }
    }
  };

  return (
    <div
      ref={chatBodyRef}
      className={clsx(
        styles.container_chatbody,
        type === "popup" && styles.container_chatbody_popup,
        "--harper-chatBody-container",
      )}
    >
      {canPaginate && !isFetching && (
        <div className={clsx(styles.button)} onClick={(e) => fetchMessage(e)}>
          Load more
        </div>
      )}
      {isFetching && (
        <div className={clsx(styles.loading)}>
          <LoadingSVG />
        </div>
      )}
      {chatState.map((messageId) => (
        <Fragment key={messageId}>
          {chatState[2] === messageId && <Waypoint onEnter={pagination} />}
          <ChatBodyMessage messageId={messageId} />
        </Fragment>
      ))}
      {isAdminTyping && <div className={clsx(styles.adminTyping)}>Admin is writing...</div>}
    </div>
  );
};

export default ChatBody;
