import { atom, selector } from "recoil";
import { localStorageEffect } from "./effects/localStorageEffect";

export const videoControlsState = atom({
  key: "videoControlsState",
  default: {
    isMutePlaying: true,
    goToTime: 0.01,
    volume: 100,
    isMute: false,
    isPlaying: false,
    isBuffer: true,
    displayVideoIcons: false,
    displayVideoPlayer: false,
    width: 400,
    height: 239,
  },
});

export const isVideoPlayingSelector = selector({
  key: "isVideoPlayingSelector",
  get: ({ get }) => {
    return get(videoControlsState).isPlaying;
  },
  set: ({ set }, value) => {
    set(videoControlsState, (v) => ({ ...v, isPlaying: value }));
  },
});

export const isVideoBufferSelector = selector({
  key: "isVideoBufferSelector",
  get: ({ get }) => {
    return get(videoControlsState).isBuffer;
  },
  set: ({ set }, value) => {
    set(videoControlsState, (v) => ({ ...v, isBuffer: value }));
  },
});

export const isComplexFormHiddenState = atom({
  key: "isComplexFormHiddenState",
  default: true,
});

export const videoIsStartedState = atom({
  key: "videoIsStartedState",
  default: false,
  effects: [localStorageEffect("video.started")],
});

// To store "actorType", "blockId", "_id" only
export const videoMessageRefState = atom({
  key: "videoMessageRefState",
  default: null,
  effects: [localStorageEffect("video.messageId")],
});

export const videoMessageState = atom({
  key: "videoMessageState",
  default: null,
});

export const backupVideoMessageState = atom({
  key: "backupVideoMessageState",
  effects: [localStorageEffect("video.backupMinimizedVideo")],
});

export const queueVideoState = atom({
  key: "queueVideoState",
  default: null,
});

export const isDropDownState = atom({
  key: "isDropDownState",
  default: false,
});
