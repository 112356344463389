import React, { useRef } from "react";
import clsx from "clsx";

import ChatHeader from "./ChatHeader";
import Chatbody from "./ChatBody";
import MessageForm from "./MessageForm";

import styles from "./styles.module.scss";
import { useFadeEffectMinimize, useResizeBotHeight } from "../../hooks";

const Chat = () => {
  const [isChatMinimized, containerRef] = useFadeEffectMinimize();
  const divRef = useRef(null);
  const handleResizeHeight = useResizeBotHeight(divRef);

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container_outer, `${isChatMinimized && styles.container_fade}`)}
      onMouseDown={handleResizeHeight}
    >
      <div
        ref={divRef}
        onMouseDown={(e) => e.stopPropagation()}
        className={clsx(styles.container, "--harper-chat-container")}
      >
        <ChatHeader />
        <Chatbody />
        <MessageForm />
      </div>
    </div>
  );
};

export default Chat;
