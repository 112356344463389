import { atom, selector } from "recoil";
import { addBaseUrl, harperCreated, logStore } from "../../utils";
import { HarperSettingsEvent, HarperProcess } from "../../utils/events";
import { configureClient } from "../api/feathers";

export const botConfigSetting = atom({
  key: "botConfigSetting",
});

export const botConfigState = atom({
  key: "botConfigState",
  default: selector({
    key: "botConfigState/default",
    get: async ({ get }) => {
      const settings = get(botConfigSetting);
      const res = await fetch(settings.configUrl);
      if (res.status !== 200) throw new Error(`Can not load:${settings.configUrl}`);
      const dataRes = await res.json();
      const configJson = { ...dataRes, ...settings, hideVideoControls: true };
      configJson.minimizedIcon &&= addBaseUrl(configJson.baseUrl, configJson.minimizedIcon);
      harperCreated();

      logStore("config from properties:", settings);
      logStore("config:", configJson);
      HarperProcess(HarperSettingsEvent(configJson));
      await configureClient(configJson); // i think this should be moved to a better location.
      return configJson;
    },
  }),
});
