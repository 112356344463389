/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import objectPath from "object-path";
import axios from "axios";

const getNumDaysTillNow = (millSecounds) => {
  const milliSec = Math.round(Date.now() - (millSecounds || Date.now()));
  const firstVisitDays = (milliSec / (1000 * 60 * 60 * 24)).toFixed(0);
  return parseInt(firstVisitDays, 10);
};

export const harperCreated = (method = "GET") => {
  const key = "harper-init";
  const ip = "0.0.0.0";
  let dateStored = localStorage.getItem(key);
  let initHarper = { created: Date.now(), updated: Date.now(), ip };
  if (!dateStored) {
    try {
      fetch("https://json.geoiplookup.io/")
        .then((r) => r.json())
        .then((resJson) => {
          dateStored = JSON.stringify({ created: Date.now(), updated: Date.now(), ip: resJson.ip });
          localStorage.setItem(key, dateStored);
        });
    } catch {
      dateStored = JSON.stringify({ ...initHarper, ip });
      localStorage.setItem(key, dateStored);
    }
  }
  try {
    initHarper = JSON.parse(dateStored);
  } catch {
    dateStored = JSON.stringify(initHarper);
    localStorage.setItem(key, dateStored);
  }
  if (method === "SET") {
    localStorage.setItem(key, JSON.stringify({ ...initHarper, updated: Date.now() }));
    return { ...initHarper, updated: Date.now() };
  }
  return initHarper;
};

const fillInRouterData = (data) => {
  const { created: initDate, updated: revisitDate, ip: ipAddress } = harperCreated();
  const d = new Date();
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  objectPath.set(data, "@visitor.firstVisitDays", getNumDaysTillNow(initDate));
  objectPath.set(data, "@visitor.lastVisitDay", getNumDaysTillNow(revisitDate));
  objectPath.set(data, "@currentDay", weekday[d.getUTCDay()]);
  objectPath.set(data, "@currentMonth", month[d.getMonth()]);
  objectPath.set(data, "@currentDate", d.getDate());
  objectPath.set(data, "@currentYear", d.getFullYear());
  objectPath.set(data, "@currentMinutes", d.getMinutes());
  objectPath.set(data, "@currentHour", d.getHours());
  objectPath.set(data, "@ipNumber", ipAddress);
  objectPath.set(data, "@userAgent", window.navigator.userAgent);
  return data;
};

async function downloadFile(rawUrl, options) {
  // We use accessToken since we are processing > 1MB file
  return axios
    .get(rawUrl, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
    })
    .then((res) => res.data.uri)
    .catch((err) => {
      throw new Error(err.message);
    });
}

const replaceTextHelper = (text, data) => {
  let newText = text;
  const regExp = /{[^{}]+}/g;
  const matches = text.match(regExp);
  if (matches) {
    matches.forEach((placeholder) => {
      const key = placeholder.substring(1, placeholder.length - 1).trim();
      const replacement = objectPath.get(data, key);
      newText = newText.replace(placeholder, replacement);
      if (key.includes("visitor.lastVisitDay")) {
        harperCreated("SET");
      }
    });
  }
  return newText;
};

export const replaceText = (text, data) => {
  if (!text) return text;
  let newText = text;
  if (text.includes("{@") || text.includes("{ @")) {
    data = fillInRouterData(data);
  }
  newText = replaceTextHelper(newText, data);
  return newText;
};

export const replaceTextAsync = async (text, data, options) => {
  if (!text) return text;
  let newText = text;
  if (text.includes("{@") || text.includes("{ @")) {
    data = fillInRouterData(data);
    if (text.includes("{@download") || text.includes("{ @download")) {
      const match = text.match(/\{@download\(([^)]+)\)\}/);
      if (match) {
        const secretVariable = match[1].trim();
        const parseData = objectPath.get(data, secretVariable);
        try {
          const replacement = await downloadFile(parseData, options);
          newText = newText.replace(match[0], replacement);
        } catch (err) {
          console.error(err.message);
        }
      }
    }
  }
  newText = replaceTextHelper(newText, data);

  return newText;
};

export const objectReplaceText = async (subjectObject, data, options = {}) => {
  if (typeof subjectObject === "string") {
    return replaceTextAsync(subjectObject, data, options);
  }

  if (Array.isArray(subjectObject)) {
    // Handle arrays by recursively processing each element.
    const newArray = await Promise.all(
      subjectObject.map(async (element) => objectReplaceText(element, data, options)),
    );
    return newArray;
  }

  if (typeof subjectObject === "object" && subjectObject !== null) {
    // Handle objects by recursively processing each property.
    const newObject = {};
    for (const [key, value] of Object.entries(subjectObject)) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await objectReplaceText(value, data, options);
      newObject[key] = newValue;
    }
    return newObject;
  }

  // If it's neither a string, object, nor array, return it as is.
  return subjectObject;
};
