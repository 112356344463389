import React from "react";
import objectPath from "object-path";
import { useRecoilValue, useSetRecoilState } from "recoil";
import clsx from "clsx";
import { useUpdateStorage } from "../../../store/hooks/useUpdateStorage";
import { videoFormState } from "../../../store/states";
import { useProcessBlocks, useSendMessage } from "../../../store/hooks";
import styles from "../styles.module.scss";
import { videoControlsState } from "../../../store/videoState";

const Choice = ({ field, videoForm }) => {
  const processBlock = useProcessBlocks();
  const setVideoForm = useSetRecoilState(videoFormState);
  const sendMessage = useSendMessage();
  const updateStorage = useUpdateStorage();
  const { height, width } = useRecoilValue(videoControlsState);

  const getFontSize = (h = 239) => {
    if (h < 240) return 14;
    if (h > 600) return 22;
    return 18;
  };

  const modifyLength = width < 400 && field.title.length > 30;

  const handleClick = async () => {
    try {
      const updatedMessage = {
        fields: videoForm.fields.map((v) => {
          if (v._id === field._id) {
            return { ...field, selected: true };
          }
          return v;
        }),
      };
      if (field.target) {
        const storageObj = { variables: {} };
        objectPath.set(storageObj.variables, field.target, field.value || field.title);
        updateStorage(storageObj);
      }
      sendMessage({ ...videoForm, ...updatedMessage });
      setVideoForm(null);
      processBlock(field.nextBlockId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      className={clsx(
        styles.choice,
        modifyLength && styles.choiceSmallWidth,
        "--harper-videoForm-choice",
      )}
      style={{ fontSize: getFontSize(height), minWidth: modifyLength ? `${width}px` : `100px` }}
    >
      {field.title}
    </div>
  );
};

export default Choice;
