import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";

import styles from "./styles.module.scss";
import { UserAvatar } from "../ChatAvatar";

const AdminVisitorMessage = ({ message }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div>
      <div className={clsx(styles.container__adminmessage, "--harper-adminMessage-container")}>
        <UserAvatar messageDate={messageDate} />
        <div className={clsx(styles.admin_message, "--harper-adminMessage-text-container")}>
          <Markdown>{message.text}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default AdminVisitorMessage;
