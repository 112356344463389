import { useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { toggleMinimzeChatSelector } from "../store/states";

const useFadeEffectMinimize = () => {
  const containerRef = useRef(null);
  const isChatMinimized = useRecoilValue(toggleMinimzeChatSelector);

  useEffect(() => {
    let timeoutId;
    if (!isChatMinimized) {
      containerRef.current.style.display = "inherit";
    } else {
      timeoutId = setTimeout(() => {
        if (containerRef.current?.style) {
          containerRef.current.style.display = "none";
        }
      }, 300);
    }
    return () => clearTimeout(timeoutId);
  }, [isChatMinimized]);

  return [isChatMinimized, containerRef];
};

export default useFadeEffectMinimize;
