// calculates the days difference between the two dates
const getDaysDiff = (date1, date2) => {
  return Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
};

const getMinutesDiff = (date1, date2) => {
  const diffInMs = Math.abs(date1 - date2);
  return Math.floor(diffInMs / 1000 / 60);
};

const getDateDiffInWords = (date) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;

  return `${days[date.getDay()]} ${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`;
};

const getDateTime = (date) => {
  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedDate;
};

// calculates and returns the time elapsed since a particular date
const fromNow = (date) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const elapsed = new Date() - new Date(date);

  if (elapsed < msPerMinute) {
    return "just now";
  }

  if (elapsed < msPerHour) {
    const minutes = Math.round(elapsed / msPerMinute);
    return `${minutes > 1 ? `${minutes} minutes` : "a minute"} ago`;
  }

  if (elapsed < msPerDay) {
    const hours = Math.floor(elapsed / msPerHour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  }

  return date; // Return the original date if the elapsed time is more than a day
};

export default { getDaysDiff, getMinutesDiff, getDateDiffInWords, getDateTime, fromNow };
