import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";

import styles from "./styles.module.scss";
import { AdminAvatar } from "../ChatAvatar";

const AdminMessage = ({ message }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={clsx(styles.positionRelative)}>
      <div className={clsx(styles.container__adminmessage, "--harper-adminMessage-container")}>
        <AdminAvatar adminId={message?.changed?.by} messageDate={messageDate} />
        <div className={clsx(styles.admin_message, "--harper-adminMessage-text-container")}>
          <Markdown>{message.text}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default AdminMessage;
