import React from "react";
import clsx from "clsx";

import styles from "../styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const Checkbox = ({ field, register }) => {
  return (
    <label onClick={(e) => e.stopPropagation()}>
      <div className={clsx(styles.checkbox_text)}>
        <span className={clsx(styles.clickableOnTheDiv)}>
          <input
            type="checkbox"
            defaultChecked={field.selected}
            value={field.value}
            {...getFormValidation(register, field)}
            className={clsx(styles.checkbox_input, "--harper-videoForm-checkbox")}
          />
        </span>
        <span className={clsx(styles.checkbox_text, "--harper-videoForm-checkbox-text")}>
          {field.title}
        </span>
      </div>
    </label>
  );
};

export default Checkbox;
