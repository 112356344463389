import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import IconSVG from "../../IconImages/IconSVG";

import styles from "./styles.module.scss";
import { AdminAdminAvatar } from "../ChatAvatar";

const AdminAdminMessage = ({ message, getAuthor }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={clsx(styles.adminAdminMessageWrap)}>
      <AdminAdminAvatar
        adminId={message.changed.by}
        getAuthor={getAuthor}
        messageDate={messageDate}
      />
      <div className={clsx(styles.container__usermessage, "--harper-userMessage-container")}>
        <div className={clsx(styles.user_message, "--harper-userMessage-text-container")}>
          <Markdown>{message.text}</Markdown>
        </div>
        {message.delivered ? (
          <div
            className={clsx(styles.delivered_icon, "--harper-userMessage-delivered-icon-container")}
          >
            <IconSVG.DeliverIcon size={"80%"} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminAdminMessage;
