import React from "react";
import { useSetRecoilState } from "recoil";
import { unreadMessageState } from "../../../store/states";

export const AudioNotify = (id = "random") => {
  const setUnreadMessage = useSetRecoilState(unreadMessageState);
  const [isPlay, setIsPlay] = React.useState(true);
  const handleEnd = () => {
    setIsPlay(false);
    setUnreadMessage((v) => ({ ...v, audio: 0 }));
  };
  React.useEffect(() => {
    setIsPlay(true);
  }, [id]);

  if (!isPlay) return null;
  return (
    <audio key={id} controls={false} autoPlay={true} onEnded={handleEnd}>
      <source
        key={id}
        src="https://assets.mixkit.co/sfx/preview/mixkit-software-interface-back-2575.mp3"
        type="audio/mp3"
      />
    </audio>
  );
};
