import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";

import Chat from "../Chat";
import ChatFooter from "../Chat/ChatFooter/index";
import Draggable from "../Chatbot/draggable/Draggable";

import styles from "./styles.module.scss";
import {
  botConfigState,
  stopProcessConditionState,
  toggleMinimzeChatSelector,
  unreadMessageState,
} from "../../store/states";
import { AudioNotify } from "../Chat/Notify/AudioNotify";
import { useProcessBlocks } from "../../store/hooks";
import { usePageTitleNotification } from "../../hooks";
import IconSVG from "../IconImages/IconSVG";
import { emptyImage } from "../IconImages/images";

const onImageError = ({ currentTarget }) => {
  // eslint-disable-next-line no-param-reassign
  currentTarget.onerror = null; // prevents looping
  // eslint-disable-next-line no-param-reassign
  currentTarget.src = emptyImage;
};

const Popup = () => {
  const { minimizedChatIcon } = useRecoilValue(botConfigState);
  const [unreadMessage, setUnreadMessage] = useRecoilState(unreadMessageState);
  const [isChatMinimized, toggleChatMinimize] = useRecoilState(toggleMinimzeChatSelector);
  const [stopCondition, setStopProcess] = useRecoilState(stopProcessConditionState);
  const processBlock = useProcessBlocks();
  const currentUrlSrc = window.location.href;
  usePageTitleNotification();
  // drag states
  const [disableClick, setDisableClick] = React.useState(false);
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);

  const maximizeChat = () => {
    if (disableClick) return;
    toggleChatMinimize();
    if (stopCondition?.halt === "WHEN-BOT-IS-MINIMIZED") {
      if (currentUrlSrc === stopCondition?.urlSrc) processBlock(stopCondition.resumeBlockId);
      setStopProcess((v) => ({ ...v, halt: null, resumeBlockId: "" }));
    }
  };
  useEffect(() => {
    if (window.location.href !== currentUrlSrc) {
      setStopProcess((v) => ({ ...v, halt: null, resumeBlockId: "" }));
    }
  }, [currentUrlSrc, setStopProcess]);

  useEffect(() => {
    setUnreadMessage({ count: 0, audio: 0 });
  }, [isChatMinimized, setUnreadMessage]);

  return (
    <>
      <Chat />
      {isChatMinimized && (
        <Draggable setDisableClick={setDisableClick} x={x} y={y} setX={setX} setY={setY}>
          <div
            onTouchEnd={maximizeChat}
            onClick={maximizeChat}
            className={clsx(styles.message_bubble, "--harper-chat-bubble")}
          >
            {!minimizedChatIcon ? (
              <IconSVG.MinimizeChatIcon size={35} />
            ) : (
              <img
                loading="lazy"
                className={clsx(styles.message_bubble_icon)}
                src={minimizedChatIcon}
                onError={onImageError}
                alt="Minimize bot image"
              />
            )}
          </div>
          {unreadMessage?.count !== 0 && (
            <div className={styles.message_bubble_badge}>{unreadMessage.count}</div>
          )}
        </Draggable>
      )}
      {unreadMessage?.audio !== 0 && <AudioNotify id={unreadMessage.audio} />}
      {!isChatMinimized ? <ChatFooter /> : null}
    </>
  );
};

export default Popup;
