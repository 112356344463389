import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";

const ChatEmail = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();
  if (!isAdmin && !message?.text) return null;

  return (
    <div className={styles.container}>
      <BotAvatar
        messageDate={messageDate}
        isAdmin={isAdmin}
        getBot={getBot}
        botAlias={message?.botAlias}
      />
      <div className={clsx(styles.email_wrapper, "--harper-chatEmail-wrapper")}>
        {message.text}

        {isAdmin && (
          <>
            <hr />
            Email Message
            <hr />
            <div>To: {message.to}</div>
            <div>Subject: {message.subject}</div>
            <div>Body: {message.body}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatEmail;
