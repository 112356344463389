import { isEqual } from "lodash-es";

let lastEmittedStorage = {};
const processHarperStorage = (event) => {
  if (!isEqual(lastEmittedStorage, event.detail.storage)) {
    lastEmittedStorage = event.detail.storage;
    window.dispatchEvent(event);
  }
};

const processDefault = (event) => {
  window.dispatchEvent(event);
};

export const HarperProcess = (event) => {
  if (event.type === "harper-storage") {
    processHarperStorage(event);
  } else {
    processDefault(event);
  }
};

export const HarperListen = (cb, filter = () => true) => {
  const on = (name) => {
    const handle = window.addEventListener(name, (e) => {
      if (filter(e)) {
        cb(e);
      }
    });

    return () => {
      window.removeEventListener(name, handle);
    };
  };

  const offs = [
    on("harper-trigger"),
    on("harper-block"),
    on("harper-message"),
    on("harper-storage"),
    on("harper-settings"),
  ];
  return () => {
    offs.forEach((off) => off());
  };
};

export const HarperTriggerEvent = (src) => new CustomEvent("harper-trigger", { detail: { src } });
export const HarperBlockEvent = (block) => new CustomEvent("harper-block", { detail: { block } });
export const HarperSettingsEvent = (config) =>
  new CustomEvent("harper-settings", { detail: { config } });
export const HarperStorageEvent = (storage) =>
  new CustomEvent("harper-storage", { detail: { storage } });
export const HarperMessageEvent = (message) =>
  new CustomEvent("harper-message", { detail: { message } });
export const HarperVisitorEvent = (visitor) =>
  new CustomEvent("harper-visitor", { detail: { visitor } });
export const HarperAdminEvent = (admin) => new CustomEvent("harper-admin", { detail: { admin } });
