export const getVideo = () => document.querySelector("#react-video-player")?.querySelector("video");

export const setProgress = (
  player,
  videoBlock,
  progress,
  duration,
  setDetectLoop,
  setTimeProgress,
) => {
  if (player && videoBlock && progress) {
    const { end, loop } = videoBlock.movie;
    setTimeProgress(progress);

    if (!loop) return;

    if ((end && progress >= end) || progress >= duration) {
      setDetectLoop(true);
      // eslint-disable-next-line no-param-reassign
      player.currentTime = loop;
    }
  }
};

export const secondsToTime = (sec = 0) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = Math.floor(sec % 60)
    .toString()
    .padStart(2, "0");

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export default {
  getVideo,
  setProgress,
  secondsToTime,
};
