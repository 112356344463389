import React from "react";
import clsx from "clsx";
import objectPath from "object-path";
import styles from "./styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const FileUploadInput = ({ field, register, errors, isAdmin }) => {
  const error = objectPath.get(errors, field?.target);
  const isDisabled = isAdmin || field?.selected || false;

  const textDisplay = typeof field.value === "string" ? "File uploaded" : "Uploading fail";

  return (
    <div className={clsx(styles.fileInput)}>
      {field?.selected && (
        <a
          className={clsx(styles.fileUploaded)}
          href={field?.value}
          target="_blank"
          rel="noreferrer"
        >
          {textDisplay}
        </a>
      )}

      {!field?.selected && (
        <input type="file" {...getFormValidation(register, field)} disabled={isDisabled} />
      )}
      {error && (
        <span className={clsx(styles.error_message, "--harper-chatForm-input-error")}>
          {error.message}
        </span>
      )}
    </div>
  );
};

export default FileUploadInput;
