import objectPath from "object-path";
import { logStore } from "../../utils";
import { HarperSettingsEvent, HarperProcess } from "../../utils/events";
import { botConfigState } from "../states/botConfig";

export const configChangeEffect = (key = null) => {
  return ({ onSet, getLoadable, trigger, setSelf }) => {
    const config = getLoadable(botConfigState);
    const botConfig = config.getValue();
    const {
      minimizedVideo,
      minimizedChat,
      organisationAlias,
      hideChat,
      hideVideo,
      alias: botAlias,
    } = botConfig;
    const newKey = `${organisationAlias}-${botAlias}`;
    const savedValue = localStorage.getItem(newKey);

    // init the localStorage and atom;
    let initValue = {
      config: {
        minimizedVideo,
        minimizedChat,
        hideChat,
        hideVideo,
        stopProcessCondition: { halt: null, resumeBlockId: "", urlSrc: "" },
        enableSound: true,
      },
      video: { backupMinimizedVideo: null, form: null, message: null, started: false },
    };
    if (savedValue !== null) {
      initValue = JSON.parse(savedValue);
    } else {
      localStorage.setItem(newKey, JSON.stringify(initValue));
    }

    if (trigger === "get") {
      const storedValue = objectPath.get(initValue, key);
      if (key === "config.hideChat" && storedValue === true) {
        setTimeout(() => {
          setSelf(storedValue);
        });
      } else {
        setSelf(storedValue);
      }
    }
    onSet((newValue, _, isReset) => {
      HarperProcess(HarperSettingsEvent({ [key]: newValue }));

      logStore("configChangeEffect", newValue);
      let onSetValue = {};
      const currentStorageValue = localStorage.getItem(newKey);
      if (currentStorageValue != null) onSetValue = JSON.parse(currentStorageValue);
      if (isReset) {
        objectPath.del(onSetValue, key);
        setSelf(objectPath.get(onSetValue, key));
      } else {
        objectPath.set(onSetValue, key, newValue);
        setSelf(objectPath.get(onSetValue, key));
      }
      localStorage.setItem(newKey, JSON.stringify(onSetValue));
      return null;
    });
  };
};
