import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { videoControlsState, videoMessageState } from "../videoState";

export const useAllowChatBotIcons = () => {
  const videoMessage = useRecoilValue(videoMessageState);
  const setVideoControls = useSetRecoilState(videoControlsState);

  useEffect(() => {
    if (videoMessage && !videoMessage.targetContainer) {
      setVideoControls((v) => ({ ...v, displayVideoIcons: false, displayVideoPlayer: true }));
    }
  }, [videoMessage, setVideoControls]);
};
