import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const Checkbox = ({ field, register, disabled }) => {
  return (
    <>
      <label>
        <input
          type="checkbox"
          defaultChecked={field.selected}
          value={field.value}
          {...getFormValidation(register, field)}
          className={clsx(styles.checkbox_input, "--harper-chatForm-checkbox")}
          disabled={disabled}
        />
        <span className={clsx(styles.checkbox_text, "--harper-chatForm-checkbox-text")}>
          {field.title}
        </span>
      </label>
    </>
  );
};

export default Checkbox;
