import { useRecoilCallback } from "recoil";
import { processing } from "../utils";

export const useProcessBlocks = () => {
  const setter = useRecoilCallback(
    ({ snapshot, set }) =>
      async (blockId, { storageObj = {}, storagePasswords = {} } = {}) => {
        await processing({ snapshot, set }, blockId, {
          isPlaying: true,
          videoIsStarted: true,
          minimizedVideoState: false,
          videoSendMessage: true,
          storageObj,
          storagePasswords,
        });
      },
  );

  return setter;
};
