import React from "react";
import clsx from "clsx";
import styles from "../styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const RadioButton = ({ field, register }) => {
  return (
    <label onClick={(e) => e.stopPropagation()}>
      <div className={clsx(styles.radio_text)}>
        <span className={clsx(styles.clickableOnTheDiv)}>
          {field.selected && (
            <input
              type="radio"
              value={field.value}
              {...getFormValidation(register, field)}
              disabled
              checked
              className={clsx(styles.radio_input, "--harper-videoForm-radio--selected")}
            />
          )}
          {!field.selected && (
            <input
              type="radio"
              value={field.value}
              {...getFormValidation(register, field)}
              className={clsx(styles.radio_input, "--harper-videoForm-radio")}
            />
          )}
        </span>
        <span>{field.title}</span>
      </div>
    </label>
  );
};

export default RadioButton;
