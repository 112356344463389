import React, { Suspense } from "react";
import { RecoilRoot, useRecoilValue } from "recoil";
import { ErrorBoundary } from "react-error-boundary";

import { authenticatedUserState, initializeState, visitorInfoState } from "../../store/states";
import { useVisitorInfo, useMessages, useBotConfig, useNotifyIsTyping } from "../../store/hooks";
import { logRender } from "../../utils";
import { useStorage } from "../../store/hooks/useStorage";
import { LoadingMain } from "./errorLoading/LoadingMain";
import ChatbotFace from "./ChatbotFace";
import "../../styles/globals.scss";

const ChatbotAuthentication = ({ children }) => {
  logRender("ChatbotAuthentication");
  const authenticatedUser = useRecoilValue(authenticatedUserState);
  const visitorInfo = useRecoilValue(visitorInfoState);
  if (!authenticatedUser || !visitorInfo) return null;
  return children;
};

const ChatbotInit = ({ children }) => {
  logRender("ChatbotInit");
  const visitorStorageLoaded = useStorage();
  const messagesLoaded = useMessages();
  const visitorloaded = useVisitorInfo();
  useNotifyIsTyping();
  useBotConfig();
  if (!visitorloaded || !visitorStorageLoaded || !messagesLoaded) return null;
  return children;
};

const ChatbotRoot = (props) => {
  logRender("ChatbotRoot");
  return (
    <RecoilRoot initializeState={initializeState(props)}>
      <ErrorBoundary FallbackComponent={LoadingMain}>
        <Suspense>
          <ChatbotAuthentication>
            <ChatbotInit>
              <ChatbotFace {...props} />
            </ChatbotInit>
          </ChatbotAuthentication>
        </Suspense>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export default ChatbotRoot;
