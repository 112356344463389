import React, { useState } from "react";
import Markdown from "markdown-to-jsx";
import ReactPlayer from "react-player";
import { useSetRecoilState } from "recoil";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { videoMessageState } from "../../../store/videoState";
import { useProcessBlocks } from "../../../store/hooks";
import { BotAvatar } from "../ChatAvatar";

import { videoPlayerUtils } from "../../../utils";
import IconSVG from "../../IconImages/IconSVG";

const ChatVideo = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();
  const [isVideoPlayed, setIsVidePlayed] = useState(false);
  const { imageSrc, text, src } = message;
  const processBlock = useProcessBlocks();
  const setVideoMessage = useSetRecoilState(videoMessageState);

  if (!isAdmin && !imageSrc && !text) return null;

  const playVideo = () => setIsVidePlayed(true);

  const handleVisitorReplay = () => {
    if (message.actorType === "ADMIN") {
      setVideoMessage(message);
    } else {
      processBlock(message?.blockId);
    }
  };

  return (
    <div>
      <div className={styles.container__chatVideo}>
        <BotAvatar
          messageDate={messageDate}
          isAdmin={isAdmin}
          getBot={getBot}
          botAlias={message?.botAlias}
        />

        <div className={styles.message}>
          {text && <Markdown className={styles.text}>{text}</Markdown>}
          {isAdmin && (
            <>
              {isVideoPlayed && (
                <div className={styles.closeBtn} onClick={() => setIsVidePlayed(false)}>
                  <IconSVG.CloseIcon />
                </div>
              )}
              <div
                className={clsx(styles.player_wrapper, "--harper-message-video")}
                style={{
                  backgroundColor: "pink",
                  backgroundImage: !isVideoPlayed && `url(${imageSrc})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={playVideo}
              >
                <div className={clsx(styles.play_button, isVideoPlayed && styles.hide)}>
                  <IconSVG.PlayIcon size={"100%"} />
                </div>
                {isVideoPlayed && (
                  <div>
                    <ReactPlayer
                      url={src}
                      width="100%"
                      height="100%"
                      controls
                      playing
                      playsinline={true}
                    />
                  </div>
                )}
              </div>

              <div className={clsx(styles.statusFlex, isVideoPlayed && styles.videoRow)}>
                <small>Current: {videoPlayerUtils.secondsToTime(message?.viewedProgress)}</small>
                <small>
                  Watch time:
                  {videoPlayerUtils.secondsToTime(message?.watchTime)}
                </small>
                <small>
                  Seen:
                  {videoPlayerUtils.secondsToTime(message?.maxViewedProgress)}/
                  {videoPlayerUtils.secondsToTime(message?.movie?.end)}
                </small>
              </div>
            </>
          )}

          {!isAdmin && imageSrc && (
            <div
              onClick={handleVisitorReplay}
              className={styles.player_wrapper}
              style={{ backgroundImage: `url(${imageSrc})`, backgroundColor: "pink" }}
            >
              <div className={clsx(styles.play_button)}>
                <IconSVG.PlayIcon size={"100%"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatVideo;
