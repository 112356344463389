import { atom, atomFamily, selector } from "recoil";
import { logStore } from "../../utils";
import { authUser, authVisitor, setLastestReadMessage } from "../api";
import { configChangeEffect, localStorageEffect } from "../effects";
import { isVideoPlayingSelector, videoMessageState } from "../videoState";
import { botConfigSetting, botConfigState } from "./botConfig";
import { blocksDataState } from "./blocksData";

export const stopProcessConditionState = atom({
  key: "stopProcessConditionState",
  default: { halt: null, resumeBlockId: "", urlSrc: "" },
  effects: [configChangeEffect("config.stopProcessCondition")],
});

// intends to handle EVENT without hot reload.
export const hideChatState = atom({
  key: "hideChatState",
  default: false,
  effects: [configChangeEffect("config.hideChat")],
});

export const hideVideoState = atom({
  key: "hideVideoState",
  default: false,
  effects: [configChangeEffect("config.hideVideo")],
});

export const authenticatedUserState = atom({
  key: "authenticatedUserState",
  default: selector({
    key: "authenticatedUserState/default",
    get: async ({ get }) => {
      const config = await get(botConfigState);
      const user = await authUser(config);
      logStore("user", user);
      return user;
    },
  }),
});

export const visitorInfoState = atom({
  key: "visitorInfoState",
  default: selector({
    key: "visitorInfoState/default",
    get: async ({ get }) => {
      const config = await get(botConfigState);
      const user = await get(authenticatedUserState);
      const visitor = await authVisitor(user, config);
      logStore("visitor", visitor);
      return visitor;
    },
  }),
});

export const currentBlockState = atom({
  key: "currentBlockState",
  default: null,
});

export const messagesLoadedState = atom({
  key: "messagesLoadedState",
  default: false,
});

export const storageLoadedState = atom({
  key: "storageLoadedState",
  default: false,
});

export const messageState = atomFamily({
  key: "messageState",
  default: null,
  effects: [],
});

export const messagesState = atom({
  key: "messagesState",
  default: null,
});

export const videoFormState = atom({
  key: "videoFormState",
  default: null,
  effects: [localStorageEffect("video.form")],
});

export const minimizedChatState = atom({
  key: "minimizedChatBotState",
  default: false,
  effects: [configChangeEffect("config.minimizedChat")],
});

export const minimizedVideoState = atom({
  key: "minimizedVideoState",
  default: false,
  effects: [configChangeEffect("config.minimizedVideo")],
});

export const latestReadMessageState = atom({
  key: "latestReadMessageState",
  default: selector({
    key: "latestReadMessageState/default",
    get: async ({ get }) => {
      const visitor = await get(visitorInfoState);
      return visitor.latestReadMessage;
    },
  }),
});

export const toggleMinimzeChatSelector = selector({
  key: "toggleMinimzeChat/default",
  get: ({ get }) => {
    return get(minimizedChatState);
  },
  set: ({ get, set }) => {
    const minimizedChat = get(minimizedChatState);
    if (minimizedChat) {
      set(minimizedChatState, false);
      const latestReadId = get(latestReadMessageState);
      const messagesIds = get(messagesState);
      const lastMessageId = messagesIds[messagesIds.length - 1];
      if (latestReadId !== lastMessageId) {
        const visitor = get(visitorInfoState);
        setLastestReadMessage(lastMessageId, visitor);
        set(latestReadMessageState, lastMessageId);
      }
    } else {
      set(minimizedChatState, true);
      const hideChat = get(hideChatState);
      const hideVideo = get(hideVideoState);
      const videoMessage = get(videoMessageState);
      const isVideoOnly = hideChat && !hideVideo && videoMessage && !videoMessage?.targetContainer;
      if (isVideoOnly) set(minimizedVideoState, true);
      if (!videoMessage?.targetContainer) set(isVideoPlayingSelector, false);
    }
  },
});

export const assignedAdminState = atom({
  key: "assignedAdminState",
  default: null,
});

export const directChatStatusState = atom({
  key: "directChatStatusState",
  default: selector({
    key: "directChatStatusState/default",
    get: async ({ get }) => {
      const config = await get(botConfigState);
      const { enableDirectChat } = config;

      return enableDirectChat;
    },
  }),
});

export const blocksState = selector({
  key: "blocksState",
  get: ({ get }) => {
    const blocks = get(blocksDataState);
    const objBlocks = {};
    blocks.forEach((v) => {
      objBlocks[v._id] = v;
    });
    return objBlocks;
  },
});

export const activeAdminState = atomFamily({
  key: "activeAdminState",
  default: null,
  effects: [],
});

export const activeAdminsState = atom({
  key: "activeAdminsState",
  default: [],
});

export const triggersState = selector({
  key: "triggersState",
  get: ({ get }) => {
    const blocks = get(blocksDataState);
    const objTriggers = blocks.filter((v) => v.triggers);
    // Note: Quick fix, for trigger to triggers[]
    const triggers = [];
    objTriggers.forEach((block) => {
      block.triggers.forEach((trig) => {
        triggers.push({ _id: block._id, type: block.type, trigger: trig }); //  {_id: "", trigger: {src: "", _id: "", priority: 1} }
      });
    });
    logStore("triggers", blocks, triggers);
    return triggers;
  },
});

export const initializeState = (settings) => {
  return async ({ set }) => {
    set(botConfigSetting, settings);
  };
};

export const unreadMessageState = atom({
  key: "unreadMessageState",
  default: { count: 0, audio: 0 },
});

export const hasNewMessageState = atom({
  key: "hasNewMessageState",
  default: false,
});

export const isTypingState = atom({
  key: "isTypingState",
  default: false,
});

export const isAdminTypingState = atom({
  key: "isAdminTypingState",
  default: false,
});

export const enableNotifySoundState = atom({
  key: "enableNotifySoundState",
  default: true,
  effects: [configChangeEffect("config.enableSound")],
});

export const isSettingsModeState = atom({
  key: "isSettingsModeState",
  default: false,
});

export const messageCanPaginateState = atom({
  key: "messageCanPaginateState",
  default: true,
});

export * from "./botConfig";
export * from "./visitorStorage";
export * from "./blocksData";
