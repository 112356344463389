if (import.meta.env.DEV)
  // eslint-disable-next-line no-console
  console.log(
    `Debug \n current: \x1B[41;93;4m ${localStorage.getItem(
      "debug",
    )}\x1B[m \n cmd: window.localStorage.setItem("debug", "harper:*:*")\n options: [harper:render:info, harper:api:info, harper:store:info, harper:debug:info]`,
  );

// Convert relative urls to absolute urls with a basePath
export function addBaseUrl(baseUrl, url) {
  let validBaseUrl = "/";
  if (baseUrl) validBaseUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;
  return url.startsWith("#") || url.startsWith("/") || url.startsWith("http")
    ? url
    : `${validBaseUrl}${url}`;
}

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export * from "./scrollIntoView";
export * from "./replaceText";
export * from "./infoLogger";
export * from "./fileUploader";
export * as videoPlayerUtils from "./videoPlayer";

export { default as formatDate } from "./formatDate";

export * as pageTitleNotification from "./pageTitleNotification";
