import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { pick } from "lodash-es";

const MAX_FILE_SIZE_MB = 10;
const invalidFileTypes = ["html", "css", "js", "ts", "jsx", "tsx"];

export const validateFile = (file) => {
  if (!file) throw new Error("No file found");
  const { name: fileName, size: fileSize, type: fileType } = file;

  if (fileSize > MAX_FILE_SIZE_MB * 1024 * 1024) {
    throw new Error("Size should be lesser than 10MB.");
  }

  const fileExtension = fileType.split("/").pop().toLowerCase();
  if (invalidFileTypes.includes(fileExtension)) throw new Error("Invalid file format");

  const fileNameParts = fileName.split(".");
  const fileNameExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  if (invalidFileTypes.includes(fileNameExtension)) throw new Error("Invalid file format");

  return { fileName, fileType, fileSize };
};

export const fileUploader = async ({ file, organisationAlias, botAlias }) => {
  const accessToken = localStorage.getItem(`jwt-${organisationAlias}-${botAlias}`);
  const baseUrl =
    window.location.hostname === "localhost"
      ? "http://localhost:8180"
      : `${window.location.origin}/api/v1`;

  const fileName = file.name;
  const formData = new FormData();
  formData.append("uri", file);
  formData.append("fileName", fileName);
  formData.append("title", `${fileName}-${uuidv4()}`);

  try {
    const res = await axios.post(
      `${baseUrl}/organisations/${organisationAlias}/files-upload`,
      formData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const dataRes = pick(res.data, ["cloudUrl", "contentType", "fileName", "size"]);
    return {
      ...dataRes,
      uriUrl: `${baseUrl}/organisations/${organisationAlias}/files/${res.data._id}/uri`,
    };
  } catch (err) {
    if (err.response?.data?.message?.startsWith("E11000")) {
      return err?.response?.data;
    }
    throw new Error(err.message);
  }
};

// const getBase64Info = async (file) => {
//   if (file) {
//     const reader = new FileReader();

//     return new Promise((resolve, reject) => {
//       reader.onload = () => {
//         const fileInfo = {
//           base64: reader.result.split(",")[1],
//           ...getFileInfo(file),
//         };
//         resolve(fileInfo);
//       };
//       reader.onerror = () => {
//         reject(new Error("Error reading the file."));
//       };
//       reader.readAsDataURL(file);
//     });
//   }
//   return Promise.reject(new Error("No file data provided"));
// };
