import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line import/no-cycle
import client from "./feathers";
import { logApi } from "../../utils";

export const blockService = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/blocks");

export const chatMessageService = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/visitors/:visitorId/messages");

export const storageService = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/visitors/:visitorId/storage");

export const joinChannelService = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/visitors/:visitorId/join");

export const registerDeviceService = () => client.service("/onboarding/register-device-user");

export const visitorService = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/visitors");

export const usersGetService = (id) => client.service("/users").get(id);

export const usersService = () => client.service("/users");

export const botConfigService = () => client.service("/organisations/:organisationAlias/bots");

export const getBlocks = async ({ botAlias, organisationAlias }) =>
  blockService().find({ query: { botAlias, organisationAlias } });

export const sendMessage = async (message, { organisationAlias, botAlias, visitorId }) => {
  const toStore = { ...message, browserUrl: window.location.href };
  logApi("sendMessage", toStore);
  return chatMessageService().create(toStore, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const updateMessage = async (id, message, { organisationAlias, botAlias, visitorId }) => {
  return chatMessageService().update(id, message, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const patchMessage = async (id, message, { organisationAlias, botAlias, visitorId }) => {
  return chatMessageService().patch(id, message, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const joinChannel = async ({ visitorId, botAlias, organisationAlias }) => {
  return joinChannelService().create(
    {},
    {
      query: { organisationAlias, botAlias, visitorId },
    },
  );
};

export const getChatMessages = async ({
  visitorId,
  organisationAlias,
  botAlias,
  skip = 0,
  limit = 10,
}) => {
  return chatMessageService().find({
    organisationAlias,
    botAlias,
    visitorId,
    query: {
      $limit: limit,
      $skip: skip,
    },
  });
};

export const getVisitor = async (visitorId) => {
  return visitorService().get(visitorId);
};

export const getVisitorStorage = async ({ visitorId, organisationAlias, botAlias }) => {
  return storageService().find({ query: { organisationAlias, botAlias, visitorId } });
};

export const createStorage = async (message, { organisationAlias, botAlias, visitorId }) => {
  return storageService().create(message, { query: { organisationAlias, botAlias, visitorId } });
};

export const updateStorage = async (message, { organisationAlias, botAlias, visitorId }) => {
  return storageService().update(visitorId, message, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const patchStorage = async (message, { organisationAlias, botAlias, visitorId }) => {
  return storageService().patch(visitorId, message, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const createVisitor = async ({ visitorId, botAlias, organisationAlias }) => {
  return visitorService().create(
    { _id: visitorId },
    {
      query: { organisationAlias, botAlias },
    },
  );
};

export const getBotConfig = async (botAlias) => botConfigService().get(botAlias);

const registerDevice = async () => {
  const { _id: deviceId } = await registerDeviceService().create({});
  return deviceId;
};

const authenticateDeviceId = async (deviceId) => {
  const authResult = await client.authenticate({
    strategy: "device",
    _id: deviceId,
  });

  return authResult;
};

export const stripePayService = () =>
  client.service("/organisations/:organisationAlias/stripes/pay");

export const authUser = async (config) => {
  const { organisationAlias, alias: botAlias } = config;
  let user;
  let deviceId = localStorage.getItem(`device-${organisationAlias}-${botAlias}`);
  // create user if it dosen't exist.
  if (!deviceId) {
    deviceId = await registerDevice();
  }

  // authenticate the user.
  try {
    try {
      user = (await client.reAuthenticate(true)).user;
      logApi("authUser reAuthenticate", user._id);
    } catch (err) {
      user = (await authenticateDeviceId(deviceId, { organisationAlias, botAlias })).user;
      logApi("authUser new device", user._id);
    }
    logApi("authUser success", user);
  } catch (err) {
    // if the device user is removed from the database or invalid device id "not logged in"
    if (err.code === 404 || err.code === 401) {
      localStorage.removeItem(`device-${organisationAlias}-${botAlias}`);
      user = await authUser(config);
    } else {
      console.error(err);
    }
  }

  localStorage.setItem(`device-${organisationAlias}-${botAlias}`, deviceId);
  return user;
};

export const authVisitor = async (user, botConfig) => {
  const { organisationAlias, alias: botAlias } = botConfig;
  let vInfo;
  const visitorId = localStorage.getItem(`visitor-${organisationAlias}-${botAlias}`);
  try {
    if (visitorId) {
      vInfo = await getVisitor(visitorId);
    } else {
      vInfo = await createVisitor({ visitorId: uuidv4(), organisationAlias, botAlias });
    }

    await joinChannel({ visitorId: vInfo._id, botAlias, organisationAlias });
    logApi("authVisitor and join channel success", vInfo);
  } catch (err) {
    // if the device user is removed from the database or invalid device id "not logged in"
    if (err.code === 404) {
      localStorage.removeItem(`visitor-${organisationAlias}-${botAlias}`);
      vInfo = await authVisitor(user, botConfig);
    } else {
      console.error(err);
    }
  }

  localStorage.setItem(`visitor-${organisationAlias}-${botAlias}`, vInfo._id);
  return vInfo;
};

export const notifyTypingSvc = () =>
  client.service("/organisations/:organisationAlias/bots/:botAlias/visitors/:visitorId/notify");

export const createNotifyTyping = async (isTyping, visitor) => {
  const { organisationAlias, botAlias, _id: visitorId } = visitor;
  const message = { visitor: { isTyping } };
  logApi("notifyIsTyping", message);
  return notifyTypingSvc().create(message, {
    query: { organisationAlias, botAlias, visitorId },
  });
};

export const setAssignAdmin = async ({ adminId, botConfig, visitorId }) => {
  const { organisationAlias, alias: botAlias } = botConfig;
  return visitorService().patch(
    visitorId,
    { assignedTo: adminId },
    { query: { organisationAlias, botAlias } },
  );
};

export const setLastestReadMessage = async (messageId, visitor) => {
  const { organisationAlias, botAlias, _id: visitorId } = visitor;
  return visitorService().patch(
    visitorId,
    { latestReadMessage: messageId },
    { query: { organisationAlias, botAlias } },
  );
};
