import React from "react";
import { useRecoilValue } from "recoil";
import { assignedAdminState, botConfigState } from "../../../store/states";
import styles from "./styles.module.scss";
import { ChatHeaderMenu } from "./ChatHeaderMenu";
import { TemplatePrimary } from "./TemplatePrimary";
import { TemplateListAdmin } from "./TemplateListAdmin";

export const ChatHeaderDetails = () => {
  const admin = useRecoilValue(assignedAdminState);
  const botConfig = useRecoilValue(botConfigState);
  const { headerTemplate = "PRIMARY" } = botConfig;

  return (
    <div className={styles.header_content_inner}>
      <ChatHeaderMenu />
      {headerTemplate === "PRIMARY" && <TemplatePrimary admin={admin} />}
      {headerTemplate === "ADMIN-LIST" && <TemplateListAdmin isTest={false} />}
      <p className={styles.header_note}>
        {botConfig?.chatHeader || "We usually reply within 5 minutes."}
      </p>
    </div>
  );
};
