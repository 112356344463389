import React from "react";
import objectPath from "object-path";
import clsx from "clsx";
import styles from "../styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const PasswordField = ({ field, register, errors }) => {
  const error = objectPath.get(errors, field.target);

  return (
    <div className={clsx(error ? styles.mbAdjust11 : styles.mbAdjust2)}>
      <input
        type="password"
        onClick={(e) => e.stopPropagation()}
        className={clsx(styles.textField, "--harper-videoForm-passwordfield")}
        {...getFormValidation(register, field)}
        placeholder={field.title}
      />
      {error && (
        <div className={clsx(styles.error_message, "--harper-videoForm-passwordfield--error")}>
          {error.message}
        </div>
      )}
    </div>
  );
};

export default PasswordField;
