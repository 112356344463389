import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styles from "./styles.module.scss";
import { hideChatState } from "../../../store/states";
import {
  videoControlsState,
  videoMessageState,
  backupVideoMessageState,
  isComplexFormHiddenState,
} from "../../../store/videoState";
import { isVideoInChatOnlySelector } from "../../../store/selectorState";
import { ButtonSVG } from "../../shared";
import IconSVG from "../../IconImages/IconSVG";

export const CloseIconVideo = () => {
  const [videoMessage, setVideoMessage] = useRecoilState(videoMessageState);
  const setBackupVideoMessage = useSetRecoilState(backupVideoMessageState);
  const [{ displayVideoIcons }, setVideoControl] = useRecoilState(videoControlsState);
  const hideChat = useRecoilValue(hideChatState);
  const isComplexFormHidden = useRecoilValue(isComplexFormHiddenState);
  const isVideoInChatOnly = useRecoilValue(isVideoInChatOnlySelector);

  const closeVideo = () => {
    setBackupVideoMessage(videoMessage);
    setVideoMessage(null);
  };
  const handleHover = () => {
    if (!displayVideoIcons) {
      setVideoControl((v) => ({ ...v, displayVideoIcons: true }));
    }
  };

  if (videoMessage?.targetContainer) return null;
  /* Video close icon  */
  return (
    <>
      {!isVideoInChatOnly && !hideChat && isComplexFormHidden && (
        <div className={styles.close_icon}>
          <ButtonSVG
            onClick={closeVideo}
            onMouseEnter={handleHover}
            iconSVG={IconSVG.VideoShrinkIcon}
            className="--harper-close-video"
          />
        </div>
      )}
    </>
  );
};
