import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { videoFormState } from "../store/states";

const useIsTabHasFocus = () => {
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const videoForm = useRecoilValue(videoFormState) || { fields: [{ type: "" }] };

  const isBusy =
    (videoForm.fields && videoForm.fields?.some((field) => field.type === "FILEUPLOAD")) || false;

  useEffect(() => {
    const handleFocus = () => setTabHasFocus(document.hasFocus());
    const handleBlur = () => setTabHasFocus(false);

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return isBusy ? true : tabHasFocus;
};

export default useIsTabHasFocus;
