import React from "react";
import clsx from "clsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";

const ChatPDF = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={styles.container}>
      <BotAvatar
        messageDate={messageDate}
        isAdmin={isAdmin}
        getBot={getBot}
        botAlias={message?.botAlias}
      />
      <div className={clsx(styles.pdf_wrapper, "--harper-chatImage-wrapper")}>
        <div className={clsx(styles.smallFont)}>
          {message.text ? message.text : "Preparing pdf..."}
        </div>
      </div>
    </div>
  );
};

export default ChatPDF;
