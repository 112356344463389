import React, { Fragment, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import clsx from "clsx";

import TextField from "./TextField";
import RadioButton from "./RadioButton";
import Checkbox from "./Checkbox";
import Choice from "./Choice";
import Markdown from "./Markdown";
import FileUploadInput from "./FileUploadInput";

import styles from "../styles.module.scss";
import { logRender } from "../../../utils";
import { isComplexFormHiddenState } from "../../../store/videoState";
import LoadingSVG from "../../IconImages/LoadingSVG";
import { useFormOnSubmit } from "../../../hooks";
import PasswordField from "./PasswordField";

const renderFieldByType = (field, { register, errors }) => {
  switch (field.type) {
    case "TEXTFIELD":
      return <TextField field={field} register={register} errors={errors} />;
    case "PASSWORD":
      return <PasswordField field={field} register={register} errors={errors} />;
    case "RADIO":
      return <RadioButton field={field} register={register} />;
    case "CHECKBOX":
      return <Checkbox field={field} register={register} />;
    case "FILEUPLOAD":
      return <FileUploadInput field={field} register={register} errors={errors} />;
    case "MARKDOWN":
      return <Markdown field={field} errors={errors} />;
    default:
      return null;
  }
};

const FormChoices = ({ fields, videoForm }) => {
  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles.row)}>
        {fields.map((field, i) => {
          return (
            <Fragment key={field._id}>
              <div className={clsx(styles.col)}>
                <Choice field={field} videoForm={videoForm} />
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const CheckboxAndRadioErrors = ({ fields, errors }) => {
  const isError = Object.keys(errors).length === 0;
  if (isError) return null;
  let error = null;
  fields.forEach((f) => {
    if (f.type === "CHECKBOX" || f.type === "RADIO") {
      const found = objectPath.get(errors, f.target);
      if (found) error = { ...found, message: `${f.type}: ${found.message}` };
    }
  });
  if (!error) return null;
  return (
    <React.Fragment>
      <span className={clsx(styles.error_radio_message, "--harper-chatForm-radio-error")}>
        {error.message}
      </span>
    </React.Fragment>
  );
};

const RenderField = ({ fields, register, errors, button, isSubmitting }) => {
  return (
    <div
      className={clsx(styles.single_field_container, "--harper-videoForm-single-field-container")}
    >
      <div>
        {fields.map((field) => (
          <Fragment key={field._id}>{renderFieldByType(field, { register, errors })}</Fragment>
        ))}
      </div>
      <div className={clsx(styles.textCenter)}>
        <CheckboxAndRadioErrors errors={errors} fields={fields} />
        <button
          onClick={(e) => e.stopPropagation()}
          className={clsx(styles.single_field_button, "--harper-videoForm-single-field-button")}
          type="submit"
        >
          {button.title} {isSubmitting && <LoadingSVG size={16} />}
        </button>
      </div>
    </div>
  );
};

const Form = ({ videoForm, isAllButton }) => {
  logRender("VideoPlayer/Form");

  const { fields } = videoForm;
  const [isVideoFormHidden, setIsVideoFormHidden] = useRecoilState(isComplexFormHiddenState);
  const { isPopOver = false } = videoForm;
  const onSubmit = useFormOnSubmit();
  const hiddenFormRef = useRef();
  const button = fields.find((field) => field.type === "BUTTON");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    setIsVideoFormHidden(true);
  }, [videoForm, setIsVideoFormHidden]);

  const toggleForm = (e) => {
    e.stopPropagation();

    if (isVideoFormHidden) {
      setIsVideoFormHidden(false);
      setTimeout(() => {
        hiddenFormRef.current.style.transform = "scale(1)";
      }, 100);
      return null;
    }

    hiddenFormRef.current.style.transform = "scale(0)";
    setTimeout(() => {
      setIsVideoFormHidden(true);
    }, 250);

    return null;
  };

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data, videoForm))}
      className={clsx(
        styles.videoFormWrapper,
        !isVideoFormHidden && isPopOver && !isAllButton && styles.wrapEntireVideo,
        "--harper-videoForm",
      )}
      autoComplete="off"
    >
      {isAllButton && <FormChoices fields={fields} videoForm={videoForm} />}
      {isPopOver && !isAllButton && (
        <>
          {isVideoFormHidden && (
            <div className={styles.hidden_form_toggle} onClick={toggleForm}>
              Open Form
            </div>
          )}

          {!isVideoFormHidden && (
            <div
              onClick={(e) => e.stopPropagation()}
              ref={hiddenFormRef}
              className={clsx(styles.hidden_form, "--harper-videoForm--hidden")}
            >
              <span className={clsx(styles.close_button, "--harper-videoForm-close-button")}>
                <button onClick={toggleForm}>close</button>
              </span>

              <RenderField fields={fields} register={register} errors={errors} button={button} />
            </div>
          )}
        </>
      )}
      {!isPopOver && !isAllButton && (
        <div className={clsx(styles.formAbsolute)}>
          <RenderField
            fields={fields}
            register={register}
            isSubmitting={isSubmitting}
            errors={errors}
            button={button}
          />
        </div>
      )}
    </form>
  );
};

export default Form;
