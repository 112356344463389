import objectPath from "object-path";
import { logStore } from "../../utils";
import { botConfigState } from "../states/botConfig";

export const localStorageEffect = (key) => {
  return ({ setSelf, onSet, trigger, getLoadable }) => {
    const config = getLoadable(botConfigState);
    const { organisationAlias, alias: botAlias } = config.getValue();
    const newKey = `${organisationAlias}-${botAlias}`;
    const savedValue = localStorage.getItem(newKey);
    // init the localStorage and atom;
    let initValue = {};
    if (savedValue != null) {
      initValue = JSON.parse(savedValue);
    } else {
      localStorage.setItem(newKey, JSON.stringify(initValue));
    }

    if (trigger === "get") {
      const storedValue = objectPath.get(initValue, key);
      setSelf(storedValue);
      if (key === "video.form") {
        const videoMessage = objectPath.get(initValue, "video.messageId");
        if (videoMessage?.nextBlockId !== storedValue?.blockId) {
          setSelf(null);
        }
      }
    }
    onSet((newValue, _, isReset) => {
      logStore("setting localstorage", newValue);
      let onSetValue = {};
      const currentStorageValue = localStorage.getItem(newKey);
      if (currentStorageValue != null) onSetValue = JSON.parse(currentStorageValue);
      if (isReset) {
        objectPath.del(onSetValue, key);
        setSelf(objectPath.get(onSetValue, key));
      } else {
        objectPath.set(onSetValue, key, newValue);
        setSelf(objectPath.get(onSetValue, key));
      }

      localStorage.setItem(newKey, JSON.stringify(onSetValue));
      return null;
    });
  };
};
