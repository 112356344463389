import React from "react";
import clsx from "clsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";

const ChatFile = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={styles.container}>
      <BotAvatar
        messageDate={messageDate}
        isAdmin={isAdmin}
        getBot={getBot}
        botAlias={message?.botAlias}
      />
      <div className={clsx(styles.pdf_wrapper, "--harper-chatImage-wrapper")}>
        <div> {message?.text || "File is now ready."}</div>
        <a target="_blank" rel="noreferrer" href={message?.cloudUrl || ""}>
          <button className={styles.button}>Open Url</button>
        </a>
      </div>
    </div>
  );
};

export default ChatFile;
