import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { UserAvatar } from "../../ChatAvatar";
import IconSVG from "../../../IconImages/IconSVG";

export const UserBlockText = ({ message }) => (
  <div className={clsx(styles.container__usermessage, "--harper-userMessage-container")}>
    <div className={clsx(styles.user_message, "--harper-userMessage-text-container")}>
      {message.text}
    </div>
    {message.delivered ? (
      <div className={clsx(styles.delivered_icon, "--harper-userMessage-delivered-icon-container")}>
        <IconSVG.DeliverIcon size={"90%"} />
      </div>
    ) : null}
  </div>
);

export const AdminBlockText = ({ message }) => {
  const messageDate = message?.added?.at || Date.now();
  return (
    <div className={clsx(styles.container__adminmessage, "--harper-adminMessage-container")}>
      <UserAvatar messageDate={messageDate} />
      <div className={clsx(styles.admin_message, "--harper-adminMessage-text-container")}>
        {message.text}
      </div>
    </div>
  );
};
