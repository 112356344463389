import React from "react";

import ChatMessage from "../components/Chat/ChatMessage";
import ChatImage from "../components/Chat/ChatImage";
import ChatFile from "../components/Chat/ChatFile";
import ChatForm from "../components/Chat/ChatForm";
import ChatUrl from "../components/Chat/ChatUrl";
import ChatPDF from "../components/Chat/ChatPDF";
import ChatStripe from "../components/Chat/ChatStripe";
import ChatVideo from "../components/Chat/ChatVideo";
import ChatEmail from "../components/Chat/ChatEmail";
import { ChatEvent } from "../components/Chat/ChatEvent/ChatEvent";
import ChatRouter from "../components/Chat/ChatRouter";

const messageRenderer = (message, isAdmin, options) => {
  const getAuthor = options?.getAuthor || null;
  const getBot = options?.getBot || null;

  if (message) {
    switch (message.type) {
      case "MARKDOWN":
        return (
          <ChatMessage message={message} isAdmin={isAdmin} getAuthor={getAuthor} getBot={getBot} />
        );
      case "IMAGE":
        return <ChatImage message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "FILE":
        return <ChatFile message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "FORM":
        return <ChatForm message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "VIDEO":
        return <ChatVideo message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "URL":
        return <ChatUrl message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "PDF":
        return <ChatPDF message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "STRIPE":
        return <ChatStripe message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "ROUTER":
        return <ChatRouter message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "EVENT":
        return <ChatEvent message={message} isAdmin={isAdmin} getBot={getBot} />;
      case "EMAIL":
        return <ChatEmail message={message} isAdmin={isAdmin} getBot={getBot} />;
      default:
        return null;
    }
  }
  return null;
};

export default messageRenderer;
