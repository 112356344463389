import React from "react";
import clsx from "clsx";
import objectPath from "object-path";
import styles from "./styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const TextField = ({ field, register, errors, isAdmin }) => {
  const error = objectPath.get(errors, field.target);

  return (
    <>
      {field.selected && (
        <input key={field.alias} className={styles.input} value={field.value} disabled />
      )}

      {!field.selected && (
        <input
          className={clsx(
            styles.input,
            isAdmin && styles.cursorNotAllowed,
            "--harper-chatForm-input",
          )}
          {...getFormValidation(register, field)}
          placeholder={field.title}
          disabled={isAdmin}
        />
      )}
      {error && (
        <span className={clsx(styles.error_message, "--harper-chatForm-input-error")}>
          {error.message}
        </span>
      )}
    </>
  );
};

export default TextField;
