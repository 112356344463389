// Display as visitor-view, and the chat is authored by chatbot

import React from "react";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { botConfigState } from "../../../../store/states";
import { useTimeStamp } from "../../../../hooks";
import { botImage } from "../../../IconImages/images";
import { Avatar, ChatTooltip } from "../../../shared";

export const VisitorBotAvatar = ({ messageDate }) => {
  const bot = useRecoilValue(botConfigState);
  const alias = bot?.alias || "bot";
  const date = useTimeStamp(messageDate);
  const tooltipText = `${date} · ${bot?.title || "Bot"}`;
  const id = `message-${messageDate}-${uuidv4()}-${alias.replace(/[^a-zA-Z0-9]/g, "")}`;

  return (
    <>
      <Avatar
        id={id}
        altText={`${alias} avatar`}
        imageSrc={botImage}
        className="--harper-botMessage-avatar-container"
      />
      <ChatTooltip id={id} text={tooltipText} />
    </>
  );
};
