import React, { useState } from "react";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { videoControlsState } from "../../../store/videoState";
import styles from "./styles.module.scss";
import { ButtonSVG } from "../../shared";
import IconSVG from "../../IconImages/IconSVG";

export const VolumeIcon = () => {
  const [isVolumeSlider, setIsVolumeSlider] = useState(false);
  const [{ volume, isMute }, setVideoControls] = useRecoilState(videoControlsState);

  const changeVolume = (e) => {
    const vol = e.target.value;
    setVideoControls((v) => ({ ...v, isMute: vol === 0, volume: parseInt(vol, 10) }));
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsVolumeSlider(true)}
        onMouseLeave={() => setIsVolumeSlider(false)}
        className={clsx(styles.volumeIconWrap)}
      >
        <ButtonSVG
          onClick={() => setVideoControls((v) => ({ ...v, isMute: !v.isMute }))}
          iconSVG={isMute || volume === 0 ? IconSVG.VolumeOffIcon : IconSVG.VolumeUpIcon}
          className={clsx("--harper-chatHeader-volume-icon")}
        />

        {isVolumeSlider && (
          <input
            onChange={changeVolume}
            type="range"
            value={volume}
            className={clsx(styles.volumeSlider)}
          />
        )}
      </div>
    </>
  );
};
