import React from "react";
import { AdminBotAvatar } from "./AdminView/AdminBotAvatar";
import { VisitorBotAvatar } from "./VisitorView/VisitorBotAvatar";

export const BotAvatar = ({ messageDate, isAdmin, getBot, botAlias }) => {
  return isAdmin ? (
    <AdminBotAvatar messageDate={messageDate} getBot={getBot} botAlias={botAlias} />
  ) : (
    <VisitorBotAvatar messageDate={messageDate} />
  );
};
