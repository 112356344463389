import { useEffect, useRef } from "react";
import { useRecoilCallback } from "recoil";
import { blocksState, botConfigState, messagesState } from "../states";
import { processing, processTrigger } from "../utils";

export const useProcessStartBlocks = () => {
  const setter = useRecoilCallback(({ snapshot, set }) => async () => {
    const botConfig = await snapshot.getPromise(botConfigState);
    const chatMessages = await snapshot.getPromise(messagesState);
    const blocks = await snapshot.getPromise(blocksState);

    // get the nextBlockId from the messages;
    let nextBlockId;
    if (chatMessages.length === 0) {
      nextBlockId = botConfig.startBlockId;
    } else if (chatMessages[chatMessages.length - 1].nextBlockId) {
      // is there messages that need to be processed run them. // the handleBlock loop have been interrupted.
      nextBlockId = chatMessages[chatMessages.length - 1].nextBlockId;
      // if blocks is not in currents blocks, blocktree has been recreated.
      if (!blocks[nextBlockId]) nextBlockId = botConfig.startBlockId;
    }

    if (nextBlockId) {
      await processing({ snapshot, set }, nextBlockId, {
        videoIsStarted: false,
        isPlaying: false,
      });
    }

    await processTrigger({ snapshot, set }, window.location.href);
  });

  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      setter();
    }
  }, [setter]);
  return setter;
};
