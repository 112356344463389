/* eslint-disable no-console */
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";

import { isSettingsModeState, toggleMinimzeChatSelector } from "../../../store/states";
import styles from "./styles.module.scss";
import {
  videoControlsState,
  videoMessageState,
  backupVideoMessageState,
  isComplexFormHiddenState,
} from "../../../store/videoState";
import { isVideoInChatOnlySelector } from "../../../store/selectorState";
import { OptionIcon } from "./OptionIcon";
import { ButtonSVG } from "../../shared";
import IconSVG from "../../IconImages/IconSVG";

export const ChatHeaderMenu = () => {
  const [videoMessage, setVideoMessage] = useRecoilState(videoMessageState);
  const [backupVideoMessage] = useRecoilState(backupVideoMessageState);
  const [{ displayVideoIcons }, setVideoControl] = useRecoilState(videoControlsState);
  const isVideoInChatOnly = useRecoilValue(isVideoInChatOnlySelector);
  const isComplexFormHidden = useRecoilValue(isComplexFormHiddenState);
  const [minimizeChat, toggleChatMinimize] = useRecoilState(toggleMinimzeChatSelector);
  const isSettingsMode = useRecoilValue(isSettingsModeState);

  const handleRestoreVideo = () => {
    setVideoMessage(backupVideoMessage);
  };

  const handleHover = () => {
    if (!displayVideoIcons) {
      setVideoControl((v) => ({ ...v, displayVideoIcons: true }));
    }
  };

  if (minimizeChat && isVideoInChatOnly) return null;

  if (!isComplexFormHidden && !videoMessage?.targetContainer) return null;

  return (
    <div className={styles.icon_wrapper}>
      {!videoMessage && backupVideoMessage && (
        <ButtonSVG
          iconSVG={IconSVG.VideoExpandIcon}
          onClick={handleRestoreVideo}
          className="--harper-chatHeader-show-video-icon"
        />
      )}
      {!isVideoInChatOnly && <OptionIcon onMouseEnter={handleHover} />}

      {!isSettingsMode && (
        <ButtonSVG
          onClick={toggleChatMinimize}
          onMouseEnter={handleHover}
          iconSVG={IconSVG.MinimizeIcon}
          className={clsx("--harper-chatHeader-minimize-icon")}
          iconSize={29}
        />
      )}
    </div>
  );
};
