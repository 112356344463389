import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { videoMessageState } from "../../store/videoState";
import { ButtonSVG } from "../shared";
import IconSVG from "../IconImages/IconSVG";

export const FullScreen = () => {
  const isWebkitBased = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const videoMessage = useRecoilValue(videoMessageState);
  const isSmallScrrenWebkit = isWebkitBased && !videoMessage.targetContainer;

  function enterFullscreen() {
    if (isSmallScrrenWebkit) return;
    const element = document.querySelector(".--harper-videoPlayer-container");
    if (element?.requestFullScreen) {
      element.requestFullScreen();
    } else if (element?.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element?.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element?.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msFullscreenElement) {
      document.msFullscreenElement();
    }
  }

  const toggleFullScreen = () => {
    const isFull =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.webkitCurrentFullScreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;
    if (isFull) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  useEffect(() => {
    const player = document.querySelector(".--harper-videoPlayer-container");
    if (!player) return () => {};
    const fKeyPress = (e) => {
      if (e.altKey || e.ctrlKey) return;
      if ((e.key === "f" || e.which === "70" || e.code === "KeyF") && !isWebkitBased) {
        if (document.hasFocus()) {
          const { activeElement } = document;
          const inputs = ["input", "select", "button", "textarea"];
          if (inputs.includes(activeElement.tagName.toLowerCase())) {
            return;
          }
          toggleFullScreen();
        }
      }
    };
    player.addEventListener("dblclick", toggleFullScreen);
    window.addEventListener("keyup", fKeyPress);
    return () => {
      player.removeEventListener("dblclick", toggleFullScreen);
      player.removeEventListener("keyup", fKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSmallScrrenWebkit) return null;
  return (
    <ButtonSVG
      iconSVG={IconSVG.FullScreenIcon}
      onClick={() => toggleFullScreen()}
      className="--harper-btn-fullscreen"
    />
  );
};
