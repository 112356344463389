import clsx from "clsx";
import React, { useEffect, useState } from "react";
import LoadingAnimate from "./LoadingAnimate";
import styles from "./styles.module.scss";
import IconSVG from "../../IconImages/IconSVG";

export const LoadingMain = ({ error, resetErrorBoundary }) => {
  const [show, setShow] = useState(true);
  const [isRetry, setIsRetry] = useState(false);
  const [ready, setReady] = useState(false);

  const errMessage = () => {
    if (isRetry) return "Reloading...";
    if (error.message.startsWith("Can not load:")) {
      return "We cannot find the chatbot in the system. Status: 404";
    }
    return error.message;
  };

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 15000);
  }, []);

  const handleRetry = () => {
    setIsRetry(true);
    setTimeout(() => {
      resetErrorBoundary();
    }, 1000);
  };

  const handleReload = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div
      className={clsx(styles.chatBotError, !show && styles.displayNone, "--harper-chatbot-error")}
    >
      <div className={clsx(styles.chatBotErrClose)} onClick={() => setShow(false)}>
        <IconSVG.CloseIcon size={"70%"} style={{ width: "20px" }} />
      </div>
      <LoadingAnimate ready={ready} />
      <div className={clsx(styles.chatBotErrDetail)}>
        <h3>The chatbot has encountered an error while loading.</h3>
        <div>{errMessage()}</div>
        <button onClick={handleReload}>Force reload</button>
        <button onClick={handleRetry}>Retry</button>
      </div>
    </div>
  );
};
