import { useEffect, useRef, useState } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import objectPath from "object-path";
import { visitorInfoState } from "../../../store/states";
import { videoControlsState, videoMessageState } from "../../../store/videoState";
import { chatMessageService } from "../../../store/api";

export const useViewedProgress = (timeProgress, detectLoop, duration) => {
  const videoMessage = useRecoilValue(videoMessageState);
  const visitorInfo = useRecoilValue(visitorInfoState);
  const botStorageKey = `${visitorInfo.organisationAlias}-${visitorInfo.botAlias}`;
  const videoViewedProgressKey = `videoViewedProgress.${videoMessage.blockId}`;
  const currVideoProgress = JSON.parse(localStorage.getItem(botStorageKey));
  const initProgress = { viewedProgress: 0, maxViewedProgress: 0, watchTime: 0 };
  const storedProgress = objectPath.get(currVideoProgress, videoViewedProgressKey) || initProgress;
  const recentProgress = typeof storedProgress !== "object" ? initProgress : storedProgress;
  const { isMutePlaying, isPlaying } = useRecoilValue(videoControlsState);
  const startWatchRef = useRef(null);
  const [isNoLoopWatching, setIsNoLoopWatching] = useState(false);

  const updateVideo = useRecoilCallback(({ snapshot }) => () => {
    const updateDb = async () => {
      const message = await snapshot.getPromise(videoMessageState);
      const { organisationAlias, botAlias } = visitorInfo;
      chatMessageService()
        .patch(
          message._id,
          { ...recentProgress, movie: { end: duration } },
          {
            organisationAlias,
            botAlias,
            visitorId: visitorInfo._id,
          },
        )
        .catch(() => {});
    };
    updateDb();
  });

  const handleStorageProgress = () => {
    let watchTime = 0;
    if (isNoLoopWatching) {
      watchTime = startWatchRef?.current ? timeProgress - startWatchRef.current : 0;
      if (watchTime < 0.4) watchTime = 0;
      if (watchTime > 2) watchTime = 0;
    }
    startWatchRef.current = timeProgress;
    if (isMutePlaying || timeProgress < 1) return;
    objectPath.set(currVideoProgress, videoViewedProgressKey, {
      viewedProgress: timeProgress,
      maxViewedProgress:
        recentProgress?.maxViewedProgress > timeProgress
          ? recentProgress.maxViewedProgress
          : timeProgress,
      watchTime: recentProgress?.watchTime ? recentProgress.watchTime + watchTime : 1,
    });
    localStorage.setItem(botStorageKey, JSON.stringify(currVideoProgress));
  };

  useEffect(() => {
    if (isPlaying) setIsNoLoopWatching(true);
  }, [isPlaying]);

  useEffect(() => {
    handleStorageProgress();
    if (detectLoop) {
      setIsNoLoopWatching(false);
      startWatchRef.current = duration;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectLoop]);

  useEffect(() => {
    handleStorageProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeProgress]);

  return [recentProgress, updateVideo];
};
