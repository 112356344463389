import React from "react";
import { useRecoilValue } from "recoil";
import { activeAdminsState, activeAdminState } from "../../../store/states";
import styles from "./styles.module.scss";

function getColor(id) {
  const color = ["#d47500", "#00aa55", "#e3bc01", "#410864", "#dc2929"];
  return color[id];
}

export const TemplateListAdmin = ({ isTest = true, mockAdmins }) => {
  const adminIds = useRecoilValue(activeAdminsState);
  const adminIdsTruncate = adminIds.slice(0, 5);

  if (isTest) return <AdminTestView mockAdmins={mockAdmins} />;
  return (
    <div className={styles.adminListWrap}>
      <div className={styles.italic}>Chat with the support team:</div>
      <div className={styles.adminWrap}>
        {adminIdsTruncate.map((adminId, idx) => (
          <AdminPerson adminId={adminId} key={idx} idx={idx} />
        ))}
        {adminIds.length > 5 && <div className={styles.text_md}>+</div>}
      </div>
    </div>
  );
};

const AdminPerson = ({ adminId, idx }) => {
  const admin = useRecoilValue(activeAdminState(adminId));
  const altName = `${admin?.firstName.slice(0, 1) || "G"}${admin?.lastName.slice(0, 1) || "H"}`;

  return (
    <>
      {admin?.profileImageUrl && (
        <div
          style={{ backgroundImage: `url(${admin.profileImageUrl})` }}
          className={styles.adminPersonImage}
        ></div>
      )}
      {!admin?.profileImageUrl && (
        <div className={styles.adminPerson} style={{ backgroundColor: getColor(idx) }}>
          {altName}
        </div>
      )}
    </>
  );
};

const AdminTestView = ({ mockAdmins }) => {
  const mockAdminsTruncate = mockAdmins.slice(0, 5);
  const getInitial = (admin) =>
    `${admin?.firstName.slice(0, 1) || "G"}${admin?.lastName.slice(0, 1) || "H"}`;
  return (
    <div className={styles.adminListWrap}>
      <div className={styles.italic}>Chat with the support team:</div>
      <div className={styles.adminWrap}>
        {mockAdminsTruncate.map((admin, idx) => (
          <div
            className={styles.adminPerson}
            key={admin._id}
            style={{ backgroundColor: getColor(idx) }}
          >
            {getInitial(admin)}
          </div>
        ))}
        {mockAdmins.length > 5 && <div className={styles.text_md}>+</div>}
      </div>
    </div>
  );
};
