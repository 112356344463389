import { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { assignedAdminState, hasNewMessageState } from "../store/states";
import { pageTitleNotification } from "../utils";
import useIsTabHasFocus from "./useIsTabHasFocus";

const usePageTitleNotification = () => {
  const tabHasFocus = useIsTabHasFocus();
  const assignedAdmin = useRecoilValue(assignedAdminState);
  const [hasNewMessage, setHasNewMessage] = useRecoilState(hasNewMessageState);

  useEffect(() => {
    let interval;
    if (tabHasFocus) {
      pageTitleNotification.off();
      setHasNewMessage(false);
    } else if (hasNewMessage && !tabHasFocus) {
      interval = pageTitleNotification.on(
        `New message from ${assignedAdmin ? assignedAdmin.firstName : "admin"}!`,
        1000,
      );
    }
    return () => {
      pageTitleNotification.off();
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [assignedAdmin, hasNewMessage, setHasNewMessage, tabHasFocus]);
};

export default usePageTitleNotification;
