import React, { useEffect } from "react";
import clsx from "clsx";
import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";
import { stripePayService } from "../../../store/api";

const isTestMode = import.meta.env.MODE === "test";

const handlePay = async (message) => {
  const {
    cancelUrl,
    successUrl,
    botAlias,
    organisationAlias,
    currency,
    staticTitle,
    staticAmount,
    staticImageSrc,
    isShippable,
    description,
  } = message;
  try {
    const { theUrl } = await stripePayService().create(
      {
        cancelUrl,
        successUrl,
        isShippable,
        meta: { botAlias },
        currency,
        products: [
          {
            title: staticTitle,
            image: staticImageSrc || null,
            price: parseInt(staticAmount, 10),
            description,
          },
        ],
      },
      { query: { organisationAlias } },
    );
    if (!isTestMode) window.open(theUrl, "_self");
  } catch (err) {
    console.error(err.message);
  }
};

const ChatStripe = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  useEffect(() => {
    const m = document.querySelectorAll(".stripe-show-once");
    m.forEach((v, i) => {
      // eslint-disable-next-line no-param-reassign
      v.style.opacity = i + 1 !== m.length ? 0.4 : 1;
    });
  }, []);

  return (
    <>
      {message.mode === "dynamic" && (
        <div className={styles.container}>
          <BotAvatar
            messageDate={messageDate}
            isAdmin={isAdmin}
            getBot={getBot}
            botAlias={message?.botAlias}
          />
          <div className={clsx(styles.stripe_wrapper, "--harper-chatImage-wrapper")}>
            <div className={clsx(styles.smallFont)}>
              {message?.description ? message.description : "Stripe session"}
            </div>
          </div>
        </div>
      )}
      {message.mode === "static" && (
        <div className={clsx(styles.container__usermessage, "stripe-show-once")}>
          <button
            className={clsx(styles.button, isAdmin && styles.disableBtn)}
            onClick={() => handlePay(message)}
            disabled={isAdmin}
          >
            Pay with Card
          </button>
        </div>
      )}
    </>
  );
};

export default ChatStripe;
