import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import clsx from "clsx";

import { BotAvatar } from "../ChatAvatar";
import { UserBlockText, AdminBlockText } from "./DisplayAsFormText";

import { botConfigState, visitorStorageState } from "../../../store/states";
import { useProcessBlocks, useUpdateMessage } from "../../../store/hooks";
import { renderFieldByType } from "./utils";

import styles from "./styles.module.scss";
import Choice from "./Choice";
import { replaceText } from "../../../utils";
import LoadingSVG from "../../IconImages/LoadingSVG";
import { useFormOnSubmit } from "../../../hooks";

const getDefaultValues = (fields, visitorStorage) => {
  const data = visitorStorage.variables;
  return fields.reduce((acc, field) => {
    if (field.target) {
      const current = objectPath.get(data, field.target);
      if (current && field.type === "CHECKBOX") {
        acc[field.target] = current || [];
      } else {
        acc[field.target] = current || "";
      }
    }
    return acc;
  }, {});
};

const FormButton = ({ title, disabled, isAdmin, isSubmitting }) => {
  if (isAdmin || disabled) return null;

  if (!isAdmin || !disabled) {
    return (
      <button className={styles.button} type="submit">
        {title} {isSubmitting && <LoadingSVG size={14} />}
      </button>
    );
  }

  return null;
};

const FormFields = ({ fields, register, errors, button, disabled, isSubmitting, isAdmin }) => {
  return (
    <>
      {fields
        .filter((field) => field.type !== "BUTTON")
        .map((field) => (
          <div key={field._id} className={styles.input_wrapper}>
            {renderFieldByType(
              field,
              {
                register,
                errors,
                disabled,
              },
              isAdmin,
            )}
          </div>
        ))}
      <CheckboxAndRadioErrors errors={errors} fields={fields} />
      <FormButton
        disabled={disabled}
        isSubmitting={isSubmitting}
        title={button.title}
        isAdmin={isAdmin}
      />
    </>
  );
};

const CheckboxAndRadioErrors = ({ fields, errors }) => {
  const isError = Object.keys(errors).length === 0;
  if (isError) return null;
  let error = null;
  fields.forEach((f) => {
    if (
      (f.type === "CHECKBOX" && Object.keys(errors)[0] === f.target) ||
      (f.type === "RADIO" && Object.keys(errors)[0] === f.target)
    ) {
      error = errors[`${f.target}`];
    }
  });
  if (!error) return null;
  return (
    <React.Fragment>
      <span className={clsx(styles.error_radio_message, "--harper-chatForm-radio-error")}>
        {error.message}
      </span>
    </React.Fragment>
  );
};

const FormAsText = ({ fields, message, type }) => {
  const data = fields.reduce((acc, field) => {
    if (field.target && field.selected && field.value) {
      if (field.type === "CHECKBOX") {
        objectPath.push(acc, field.target, field.value);
      } else {
        objectPath.set(acc, field.target, field.value);
      }
    }
    return acc;
  }, {});

  const text = replaceText(message.text, data);

  if (type === "user") {
    return <UserBlockText message={{ ...message, text }} />;
  }

  return <AdminBlockText message={{ ...message, text }} />;
};

const FormUser = ({ fields, message, isAllButton, alreadySelected }) => {
  const button = fields.find((field) => field.type === "BUTTON");
  const visitorStorage = useRecoilValue(visitorStorageState);
  const defaultValues = getDefaultValues(fields, visitorStorage);
  const onSubmit = useFormOnSubmit();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  if (message.text && alreadySelected)
    return <FormAsText fields={fields} message={message} isAdmin={true} type="user" />;

  return (
    <div className={clsx(!isAllButton && styles.form_container)}>
      {/* {!isAllButton && !button.selected && !message.text && <BotAvatar />}
      {!isAllButton && button.selected && !message.text && <BotAvatar />}
      {!isAllButton && !button.selected && message.text && <BotAvatar />} */}
      {!isAllButton && button.selected && message.text && (
        <FormAsText fields={fields} message={message} isAdmin={true} type="user" />
      )}

      {!(!isAllButton && button.selected && message.text) && (
        <form
          className={clsx(
            styles.form,
            !isAllButton && styles.form_fields,
            "--harper-chatForm-user",
          )}
          onSubmit={handleSubmit((data) => onSubmit(data, message))}
          autoComplete="off"
        >
          {isAllButton ? (
            message.fields.map((form, idx) => (
              <Fragment key={idx}>
                <Choice
                  choice={form}
                  message={message}
                  disabled={alreadySelected}
                  isAdmin={false}
                />
              </Fragment>
            ))
          ) : (
            <>
              {
                <FormFields
                  fields={fields}
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  disabled={button.selected}
                  button={button}
                />
              }
            </>
          )}
        </form>
      )}
    </div>
  );
};

const FormAdmin = ({ fields, message, isAllButton, alreadySelected, isAdmin, getBot }) => {
  const button = fields.find((field) => field.type === "BUTTON");
  const {
    register,
    formState: { errors },
  } = useForm();

  if (isAllButton && message.text && alreadySelected)
    return <FormAsText fields={fields} message={message} isAdmin={true} type="admin" />;

  return (
    <div className={clsx(!isAllButton && styles.form_container_admin)}>
      {!isAllButton && !button.selected && !message.text && (
        <BotAvatar isAdmin={isAdmin} getBot={getBot} botAlias={message?.botAlias} />
      )}
      {!isAllButton && button.selected && !message.text && (
        <BotAvatar isAdmin={isAdmin} getBot={getBot} botAlias={message?.botAlias} />
      )}
      {!isAllButton && !button.selected && message.text && (
        <BotAvatar isAdmin={isAdmin} getBot={getBot} botAlias={message?.botAlias} />
      )}
      {!isAllButton && button.selected && message.text && (
        <FormAsText fields={fields} message={message} isAdmin={true} type="admin" />
      )}

      {!(!isAllButton && button.selected && message.text) && (
        <form
          className={clsx(
            styles.form,
            styles.form_admin,
            isAllButton && styles.form_admin__choices,
            "--harper-chatForm-admin",
          )}
          autoComplete="off"
        >
          {isAllButton ? (
            message.fields.map((form, idx) => (
              <Fragment key={idx}>
                <Choice choice={form} message={message} disabled={alreadySelected} isAdmin={true} />
              </Fragment>
            ))
          ) : (
            <>
              <FormFields
                fields={fields}
                register={register}
                errors={errors}
                disabled={button.selected}
                isAdmin={isAdmin}
                button={button}
              />
            </>
          )}
        </form>
      )}
    </div>
  );
};

const Form = (params) => (params.isAdmin ? <FormAdmin {...params} /> : <FormUser {...params} />);
export default Form;
