import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { videoMessageState } from "../../store/videoState";
import {
  botConfigState,
  hideChatState,
  hideVideoState,
  minimizedVideoState,
} from "../../store/states";
import { isExternalVideoOnlySelector, isVideoInChatOnlySelector } from "../../store/selectorState";
import {
  useProcessStartBlocks,
  useTriggers,
  useAllowChatBotIcons,
  useStoreVideoMessageId,
} from "../../store/hooks";
import { logRender } from "../../utils";
import { ChatVideoOnly } from "../Chat/ChatHeader";
import { ChatLayout } from "../Layout/ChatLayout";
import { ExternalVideoPlayer } from "./ExternalVideoPlayer";
import styles from "./styles.module.scss";
import { useResizeBotWidth } from "../../hooks";

const ChatBotWrap = ({ children }) => {
  const { type, alias } = useRecoilValue(botConfigState);
  const divRef = useRef(null);
  const handleResizeWidth = useResizeBotWidth(divRef);
  const isVideoMinimized = useRecoilValue(minimizedVideoState);
  const isVideoInChatOnly = useRecoilValue(isVideoInChatOnlySelector);

  return (
    <div
      id={alias}
      ref={divRef}
      className={clsx(
        type === "popup" ? styles.popup_container : styles.container,
        "--harper-chatBot-container",
      )}
      style={isVideoMinimized && isVideoInChatOnly ? { maxHeight: "0px" } : {}}
      onMouseDown={handleResizeWidth}
    >
      <div className={clsx(styles.containerControl)} onMouseDown={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

const ChatbotFace = () => {
  const { type } = useRecoilValue(botConfigState);
  const hideVideo = useRecoilValue(hideVideoState);
  const hideChat = useRecoilValue(hideChatState);
  const videoMessage = useRecoilValue(videoMessageState);
  const isVideoInChatOnly = useRecoilValue(isVideoInChatOnlySelector);
  const isExternalVideoOnly = useRecoilValue(isExternalVideoOnlySelector);

  logRender("Chatbot");
  useProcessStartBlocks();
  useTriggers();
  useAllowChatBotIcons();
  useStoreVideoMessageId();

  // video in container only && chat is hidden
  if (isExternalVideoOnly) {
    return <ExternalVideoPlayer videoMessage={videoMessage} />;
  }

  // video in chat only
  if (isVideoInChatOnly) {
    return (
      <ChatBotWrap>
        <ChatVideoOnly />
      </ChatBotWrap>
    );
  }

  if (hideChat) return null;

  // Support video in container and a chat
  return (
    <ChatBotWrap>
      <ChatLayout type={type} />
      {videoMessage?.targetContainer && !hideVideo && (
        <ExternalVideoPlayer videoMessage={videoMessage} />
      )}
    </ChatBotWrap>
  );
};

export default ChatbotFace;
