/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from "react";

const useResizeBotWidth = (divRef) => {
  const [isResizing, setIsResizing] = useState(false);
  const startXRef = useRef(null);
  const startWidthRef = useRef(null);

  const handleResizeWidth = (e) => {
    setIsResizing(true);
    startXRef.current = e.clientX;
    startWidthRef.current = parseInt(window.getComputedStyle(divRef.current).width, 10);
    divRef.current.style.userSelect = "none";
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
    divRef.current.style.userSelect = "text";
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = startWidthRef.current - (e.clientX - startXRef.current);
      divRef.current.style.width = `${newWidth}px`;
    }
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleResizeEnd);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleResizeEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);
  return handleResizeWidth;
};

export default useResizeBotWidth;
