import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { Tooltip } from "react-tooltip";
import styles from "./styles.module.scss";
import { useTimeStamp } from "../../../hooks";
import IconSVG from "../../IconImages/IconSVG";

const VisitorMessage = ({ message }) => {
  const date = useTimeStamp(message.added.at);
  const id = `message-${message.added.at}-visitor`;

  return (
    <div className={clsx(styles.container__usermessage, "--harper-userMessage-container")}>
      <div id={id} className={clsx(styles.user_message, "--harper-userMessage-text-container")}>
        <Markdown>{message.text}</Markdown>
      </div>

      <Tooltip anchorSelect={`#${id}`} place="top" className={clsx(styles.frontZ)}>
        {date}
      </Tooltip>
      {message.delivered ? (
        <div
          className={clsx(styles.delivered_icon, "--harper-userMessage-delivered-icon-container")}
        >
          <IconSVG.DeliverIcon size={"90%"} />
        </div>
      ) : null}
    </div>
  );
};

export default VisitorMessage;
