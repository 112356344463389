import { useRecoilCallback } from "recoil";
import { merge } from "lodash-es";
import { chatMessageService } from "../api";
import { botConfigState, messageState, visitorInfoState } from "../states";

export const useUpdateMessage = () => {
  const setter = useRecoilCallback(({ snapshot, set }) => (message) => {
    set(messageState(message.fields._id), (state) => merge({}, state, message));
    const updateDb = async () => {
      const botConfig = await snapshot.getPromise(botConfigState);
      const visitorInfo = await snapshot.getPromise(visitorInfoState);
      const { organisationAlias, alias: botAlias } = botConfig;

      chatMessageService().patch(message._id, message, {
        organisationAlias,
        botAlias,
        visitorId: visitorInfo._id,
      });
    };

    updateDb();
  });

  return setter;
};
