import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

const LoadingAnimate = ({ ready }) => {
  return (
    <div className={clsx(styles.loadingContainer)}>
      <div className={clsx(styles.loadingCircle)}>
        <div className={clsx(styles.loadingTransparentBorder)}>
          <div className={!ready && clsx(styles.loadingProgress)}></div>
          <div className={clsx(styles.loadingLoadText)}>
            Greta Harper
            {!ready && <div>Loading...</div>}
          </div>
        </div>
      </div>
      <div className={clsx(styles.loadingBlur)}></div>
    </div>
  );
};

export default LoadingAnimate;
