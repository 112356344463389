import React from "react";
import clsx from "clsx";
import objectPath from "object-path";
import styles from "../styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const FileUploadInput = ({ field, register, errors }) => {
  const error = objectPath.get(errors, field.target);
  const textDisplay = typeof field.value === "string" ? "File uploaded" : "Uploading fail";

  return (
    <div onClick={(e) => e.stopPropagation()} className={clsx(styles.fileInputVid)}>
      {field?.selected && (
        <a
          className={clsx(styles.fileUploaded)}
          href={field?.value}
          target="_blank"
          rel="noreferrer"
        >
          {textDisplay}
        </a>
      )}

      {!field?.selected && <input type="file" {...getFormValidation(register, field)} />}
      {error && (
        <div className={clsx(styles.error_message, "--harper-videoForm-textfield--error")}>
          {error.message}
        </div>
      )}
    </div>
  );
};

export default FileUploadInput;
