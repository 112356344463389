const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg+xml"];
export const isImage = (file) => {
  if (file.type) {
    const imageMimeTypes = imageExtensions.map((extension) => `image/${extension}`);
    return imageMimeTypes.includes(file.type);
  }
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return imageExtensions.includes(fileExtension);
};

const videoExtensions = [
  "mp4",
  "avi",
  "mov",
  "mkv",
  "wmv",
  "flv",
  "webm",
  "quicktime",
  "x-matroska",
  "x-msvideo",
  "x-ms-wmv",
];
export const isVideo = (file) => {
  if (file.type) {
    const videoMimeTypes = videoExtensions.map((extension) => `video/${extension}`);
    return videoMimeTypes.includes(file.type);
  }
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return videoExtensions.includes(fileExtension);
};

const documentExtensions = ["pdf", "doc", "docx", "txt", "rtf", "html", "xml"];
export const isDocument = (file) => {
  if (file.type) {
    const documentMimeTypes = documentExtensions.map((extension) => {
      if (extension === "doc" || extension === "docx") return "application/msword";
      if (extension === "txt") return "text/plain";
      if (extension === "html") return "text/html";
      return `application/${extension}`;
    });
    return documentMimeTypes.includes(file.type);
  }
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return documentExtensions.includes(fileExtension);
};

const soundExtensions = ["mp3", "wav", "ogg", "flac", "aac", "wma"];
export const isAudio = (file) => {
  if (file.type) {
    const soundMimeTypes = soundExtensions.map((extension) => {
      if (extension === "mp3") return "audio/mpeg";
      if (extension === "wma") return "audio/x-ms-wma";
      return `audio/${extension}`;
    });

    return soundMimeTypes.includes(file.type);
  }
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return soundExtensions.includes(fileExtension);
};

export const isPDF = (file) => {
  if (file.type) return file.type === "application/pdf";
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension === "pdf";
};
