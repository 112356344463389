import moment from "./moment";

const formatDate = (dateString) => {
  const daysDiff = moment.getDaysDiff(new Date(dateString), new Date());

  if (daysDiff === 0) {
    return moment.fromNow(new Date(dateString));
  }
  if (daysDiff > 7) {
    return moment.getDateTime(new Date(dateString));
  }
  return moment.getDateDiffInWords(new Date(dateString));
};

export default formatDate;
