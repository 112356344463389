import { useEffect, useRef } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { logStore } from "../../utils";
import { HarperMessageEvent, HarperProcess } from "../../utils/events";
import { chatMessageService, getChatMessages } from "../api";
import {
  botConfigState,
  messagesLoadedState,
  messagesState,
  messageState,
  visitorInfoState,
  unreadMessageState,
  hideChatState,
  isTypingState,
  enableNotifySoundState,
  hasNewMessageState,
} from "../states";
import { useServerEvents } from "./useServerEvents";

const isDislayableMessage = (data) => {
  if (data.type === "MARKDOWN") return true;
  if (data.type === "IMAGE") return true;
  if (data.type === "FILE") return true;
  if (data.type === "FORM") return true;
  if (data.type === "URL") return true;
  if (data.type === "PDF") return true;
  if (data.type === "STRIPE") return true;
  if (data.type === "EMAIL" && data?.text) return true;
  if (data.type === "EVENT" && data?.text) return true;
  if (data.type === "VIDEO" && data?.text) return true;
  if (data.type === "VIDEOFORM" && data?.text) return true;
  if (data.type === "VIDEO" && data?.imageSrc) return true;
  return false;
};

export const useMessages = () => {
  const done = useRecoilValue(messagesLoadedState);

  const updater = useRecoilCallback(({ set, snapshot }) => async (data) => {
    const isUserTyping = await snapshot.getPromise(isTypingState);
    const isEnableSound = await snapshot.getPromise(enableNotifySoundState);

    logStore("messagesUpdater", data);
    set(messageState(data._id), data);
    if (data.actorType === "ADMIN") {
      set(unreadMessageState, (v) => ({ ...v, count: v.count + 1 }));
      set(hasNewMessageState, true);
      if (!isUserTyping && isEnableSound) {
        set(unreadMessageState, (v) => ({ ...v, audio: v.audio + 1 }));
      }
      set(hideChatState, false);
    } else if (isDislayableMessage(data)) {
      set(unreadMessageState, (v) => ({ ...v, count: v.count + 1 }));
    }
    set(messagesState, (state) => {
      if (state?.indexOf(data._id) === -1) return [...state, data._id];
      HarperProcess(HarperMessageEvent(data));
      return state;
    });
  });

  const init = useRecoilCallback(({ snapshot, set }) => async () => {
    logStore("messagesInit");

    const botConfig = await snapshot.getPromise(botConfigState);
    const { organisationAlias, alias: botAlias } = botConfig;
    const visitor = await snapshot.getPromise(visitorInfoState);

    const { data: chatMessages } = await getChatMessages({
      visitorId: visitor._id,
      organisationAlias,
      botAlias,
    });

    const list = [];
    chatMessages.forEach((m) => {
      list.push(m._id);
      set(messageState(m._id), m);
    });

    list.reverse();
    logStore("messagesInit", list);
    set(messagesState, list);
    set(messagesLoadedState, true);
  });

  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      init();
    }
    return () => {};
  }, [init]);

  useServerEvents(chatMessageService, updater);
  return done;
};
