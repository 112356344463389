import { useRecoilCallback } from "recoil";
import { pick, isEqual } from "lodash-es";
import { logStore } from "../../utils";
import { HarperAdminEvent, HarperProcess } from "../../utils/events";
import { botConfigService } from "../api";
import { botConfigState, hideChatState, hideVideoState } from "../states";
import { useServerEvents } from "./useServerEvents";
import { backupVideoMessageState } from "../videoState";

export const useBotConfig = () => {
  const botUpdater = useRecoilCallback(({ snapshot, set }) => async (data) => {
    logStore("botInfo", data);
    const botConfig = await snapshot.getPromise(botConfigState);
    const modifiedByUser = pick(botConfig, ["minimizedChat", "minimizedVideo"]);
    const watchList = [
      "headerTemplate",
      "chatHeader",
      "hideVideo",
      "type",
      "hideChat",
      "enableDirectChat",
    ];
    const inData = pick(botConfig, watchList);
    const commingData = pick(data, watchList);
    if (!isEqual(inData, commingData)) {
      set(botConfigState, { ...botConfig, ...data, ...modifiedByUser });
    }
    set(hideChatState, data.hideChat);
    set(hideVideoState, data.hideVideo);
    if (botConfig.hideVideo) {
      set(backupVideoMessageState, null);
    }
    HarperProcess(HarperAdminEvent(data));
  });

  useServerEvents(botConfigService, botUpdater);
};
