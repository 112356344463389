import { atom, selector } from "recoil";
import { botConfigState } from "./botConfig";
import { getBlocks } from "../api";

export const blocksDataState = atom({
  key: "blocksDataState",
  default: selector({
    key: "blocksDataState/default",
    get: async ({ get }) => {
      const config = get(botConfigState);
      const { organisationAlias, alias: botAlias } = config;
      const { data: dbBlocks } = await getBlocks({ organisationAlias, botAlias });
      return dbBlocks;
    },
  }),
});
