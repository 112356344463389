import { useEffect, useRef } from "react";
import { logStore } from "../../utils";

export const useServerEvents = (serviceFunc, cb, filterCb = () => true) => {
  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      const service = serviceFunc();

      const cbUpdate = (data) => (filterCb(data) ? cb(data) : null);
      const cbCreated = (data) => (filterCb(data) ? cb(data) : null);

      logStore(`listening is on ${service.path}`);
      service.on("updated", cbUpdate);
      service.on("patched", cbUpdate);
      service.on("created", cbCreated);

      return () => {
        logStore(`listening is off ${service.path}`);
        service.off("updated", cbUpdate);
        service.off("patched", cbUpdate);
        service.off("created", cbCreated);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
