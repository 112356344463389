import { useEffect, useRef } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { logStore } from "../../utils";
import { HarperStorageEvent, HarperProcess } from "../../utils/events";
import { createStorage, getVisitorStorage, storageService } from "../api";
import {
  botConfigState,
  storageLoadedState,
  visitorInfoState,
  visitorStorageState,
} from "../states";
import { useServerEvents } from "./useServerEvents";

const checkAndCreateStorage = async ({ organisationAlias, botAlias, visitorId }) => {
  try {
    return await getVisitorStorage({ organisationAlias, botAlias, visitorId });
  } catch (err) {
    return createStorage(
      { _id: visitorId, variables: {} },
      { organisationAlias, botAlias, visitorId },
    );
  }
};

export const useStorage = () => {
  const done = useRecoilValue(storageLoadedState);

  const updater = useRecoilCallback(({ set }) => async (data) => {
    logStore("storageUpdater", data);
    set(visitorStorageState, data);
    HarperProcess(HarperStorageEvent(data.variables));
  });

  const init = useRecoilCallback(({ snapshot, set }) => async () => {
    logStore("storageInit");

    const botConfig = await snapshot.getPromise(botConfigState);
    const { organisationAlias, alias: botAlias } = botConfig;
    const visitor = await snapshot.getPromise(visitorInfoState);
    const { _id: visitorId } = visitor;

    const storage = await checkAndCreateStorage({ organisationAlias, botAlias, visitorId });
    logStore("current storage", storage);
    set(visitorStorageState, storage);
    HarperProcess(HarperStorageEvent(storage.variables));
    set(storageLoadedState, true);
  });

  const once = useRef(false);
  useEffect(() => {
    if (!once.current) {
      once.current = true;
      init();
    }
    return () => {};
  }, [init]);

  useServerEvents(storageService, updater);
  return done;
};
