import clsx from "clsx";
import React from "react";
import { Tooltip } from "react-tooltip";
import styles from "./styles.module.scss";

export const ChatTooltip = ({ id, text }) => (
  <Tooltip anchorSelect={`#${id}`} place="right" className={clsx(styles.frontZ)}>
    {text}
  </Tooltip>
);
