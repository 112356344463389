import authClient from "@feathersjs/authentication-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import { logApi } from "../../utils";
// eslint-disable-next-line import/no-cycle
import { authUser, authVisitor } from "./index";

const client = feathers();

export const configureClient = async (config) => {
  let socket;
  const { apiServer, organisationAlias, alias: botAlias } = config;
  if (apiServer.includes("localhost") || apiServer.includes("127.0.0.1")) {
    socket = io(`//${apiServer}`, {
      path: "/ws/",
      transports: ["websocket"],
    });
  } else {
    socket = io(`//${apiServer}`, {
      path: "/api/v1/ws/",
      transports: ["websocket"],
      secure: true,
      rejectUnauthorized: false,
    });
  }

  client.configure(
    authClient({
      storage: window.localStorage,
      timeout: 800000,
      storageKey: `jwt-${organisationAlias}-${botAlias}`,
    }),
  );
  client.configure(socketio(socket, { timeout: 800000 }));
  //
  return new Promise((resolve) => {
    socket.on("reconnect", () => {
      logApi("socket - reconnect", config);
      const oldBuffer = [...socket.sendBuffer];
      socket.sendBuffer = [];
      authUser(config)
        .then((user) => authVisitor(user, config))
        .then(() => {
          const emitBuffer = () => {
            const item = oldBuffer.shift();
            if (item) {
              socket.emit.call(socket, ...[...item.data, emitBuffer]);
            }
          };
          emitBuffer();
        });
    });

    socket.on("connect", () => {
      logApi("socket - connect", config);
      resolve();
    });
  });
};

export default client;
