import React from "react";
import objectPath from "object-path";
import clsx from "clsx";

import styles from "./styles.module.scss";

const Markdown = ({ field, errors }) => {
  const error = objectPath.get(errors, field.target);

  return (
    <>
      <p className={"--harper-chatForm-markdown-text"}>{field.title}</p>
      {error && (
        <span className={clsx(styles.error_message, "--harper-chatForm-markdown--error")}>
          {error.message}
        </span>
      )}
    </>
  );
};

export default Markdown;
