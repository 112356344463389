import React from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";

const BotMessage = ({ message, isHidden, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={clsx(styles.container__botmessage, "--harper-botMessage-container")}>
      <BotAvatar
        messageDate={messageDate}
        isAdmin={isAdmin}
        getBot={getBot}
        botAlias={message?.botAlias}
      />
      <div
        className={clsx(
          styles.bot_message,
          isHidden ? styles.bot_hidden : "",
          "--harper-botMessage-text-container",
        )}
      >
        <Markdown>{message.text}</Markdown>
      </div>
    </div>
  );
};

export default BotMessage;
