import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import BotMessage from "./BotMessage";
import VisitorMessage from "./VisitorMessage";
import AdminMessage from "./AdminMessage";
import AdminVisitorMessage from "./AdminVisitorMessage";
import AdminAdminMessage from "./AdminAdminMessage";

const ChatMessage = ({ message, isAdmin, getAuthor, getBot }) => {
  const { actorType = "BOT" } = message;

  if (actorType === "BOT")
    return <BotMessage message={message} isAdmin={isAdmin} getBot={getBot} />;
  if (isAdmin) {
    if (actorType === "VISITOR")
      return <AdminVisitorMessage message={message} getAuthor={getAuthor} />;
    if (actorType === "HIDDEN")
      return <BotMessage message={message} isHidden={true} isAdmin={isAdmin} getBot={getBot} />;
    if (actorType === "ADMIN") return <AdminAdminMessage message={message} getAuthor={getAuthor} />;
  } else {
    if (actorType === "VISITOR") return <VisitorMessage message={message} />;
    if (actorType === "ADMIN") return <AdminMessage message={message} />;
  }

  return null;
};

export default ChatMessage;
