/* eslint-disable no-useless-escape */
/* eslint-disable security/detect-unsafe-regex */
import Personnummer from "personnummer";
import { validateFile } from "./fileUploader";
import { isDocument, isImage, isPDF, isAudio, isVideo } from "./checkFileType";

export const getFormValidation = (register, field) => {
  const validator = {};
  if (field?.validation === "EMAIL") {
    validator.required = "Email is required";
    validator.pattern = {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Please enter a valid email",
    };
  }
  if (field?.validation === "PHONE") {
    validator.required = "Phone number is required";
    validator.pattern = {
      value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      message: "Please enter a valid phone number",
    };
  }
  if (field?.validation === "WEB-URL") {
    validator.required = "URL is required";
    validator.pattern = {
      value: /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/,
      message: "Please enter a valid URL",
    };
  }
  if (field?.validation === "PERSONNUMMER") {
    validator.required = "Personnummer is required";
    validator.validate = (value) => {
      return Personnummer.valid(value) || "Please enter a valid personnummer";
    };
  }
  if (field?.validation === "POSITIVE-NUMBER") {
    validator.required = "Number is required";
    validator.pattern = {
      value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
      message: "Please enter a positive number",
    };
  }
  if (field?.validation === "REQUIRED") {
    validator.required = "Field is required";
  }
  if (field?.validation === "IMAGE") {
    validator.validate = (value) => {
      try {
        validateFile(value[0]);
        return isImage(value[0]) ? true : "Please choose an image file";
      } catch (err) {
        return err.message;
      }
    };
  }
  if (field?.validation === "VIDEO") {
    validator.validate = (value) => {
      try {
        validateFile(value[0]);
        return isVideo(value[0]) ? true : "Please choose a video file";
      } catch (err) {
        return err.message;
      }
    };
  }
  if (field?.validation === "AUDIO") {
    validator.validate = (value) => {
      try {
        validateFile(value[0]);
        return isAudio(value[0]) ? true : "Please choose a sound file";
      } catch (err) {
        return err.message;
      }
    };
  }
  if (field?.validation === "DOCUMENT") {
    validator.validate = (value) => {
      try {
        validateFile(value[0]);
        return isDocument(value[0]) ? true : "Please choose a document file";
      } catch (err) {
        return err.message;
      }
    };
  }
  if (field?.validation === "PDF") {
    validator.validate = (value) => {
      try {
        validateFile(value[0]);
        return isPDF(value[0]) ? true : "Please choose a PDF file";
      } catch (err) {
        return err.message;
      }
    };
  }
  return register(field.target || " ", validator);
};
