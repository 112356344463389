import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import VideoPlayer from "../VideoPlayer";

const ExternalComponent = ({ children, targetContainer }) => {
  const target = document.querySelector(targetContainer);
  return createPortal(children, target);
};

const fallbackContainer = "#targetContainerFallback";

const isElementInDOM = (id) => {
  const target = document.querySelector(id);
  return target !== null;
};

const ExternalVideoPlayer = ({ videoMessage }) => {
  const [hasTarget, setHasTarget] = useState(false);
  const [retryInterval, setRetryInterval] = useState(null);

  const checkForTarget = () => {
    const isTarget = isElementInDOM(videoMessage.targetContainer);
    const isFallbackContainer = isElementInDOM(fallbackContainer);
    if (isTarget || isFallbackContainer) {
      setHasTarget(true);
      clearInterval(retryInterval);
    }
  };

  useEffect(() => {
    checkForTarget();
    if (!hasTarget) {
      const intervalId = setInterval(checkForTarget, 3000);
      setRetryInterval(intervalId);
    }

    return () => {
      if (retryInterval) clearInterval(retryInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoMessage.targetContainer, hasTarget]);

  if (!hasTarget) return null;

  const targetContainerId = isElementInDOM(videoMessage.targetContainer)
    ? videoMessage.targetContainer
    : fallbackContainer;

  return isElementInDOM(targetContainerId) ? (
    <ExternalComponent key={videoMessage.src} targetContainer={targetContainerId}>
      <div id="--harper-portal-video-wrapper">
        <VideoPlayer />
      </div>
    </ExternalComponent>
  ) : null;
};

export { ExternalVideoPlayer };
