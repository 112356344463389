const config = {
  currentTitle: null,
  interval: null,
};

export const on = (message, speed) => {
  if (!config.interval) {
    config.currentTitle = document.title;
    config.interval = window.setInterval(() => {
      document.title = config.currentTitle === document.title ? message : config.currentTitle;
    }, speed || 1000);
  }
};

export const off = () => {
  if (config.interval) {
    window.clearInterval(config.interval);
    config.interval = null;
    document.title = config.currentTitle;
  }
};

export default { on, off };
