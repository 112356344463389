/* eslint-disable no-param-reassign */
import { useRecoilCallback } from "recoil";
import { sendMessage } from "../api";
import { botConfigState, messagesState, messageState, visitorInfoState } from "../states";

export const useSendMessage = () => {
  const setter = useRecoilCallback(({ snapshot, set }) => (message) => {
    set(messageState(message._id), { ...message, added: { at: Date.now() } });
    set(messagesState, (state) => [...state, message._id]);

    const updateDb = async () => {
      const botConfig = await snapshot.getPromise(botConfigState);
      const visitorInfo = await snapshot.getPromise(visitorInfoState);
      const { organisationAlias, alias: botAlias } = botConfig;

      sendMessage(message, {
        organisationAlias,
        botAlias,
        visitorId: visitorInfo._id,
      });
    };

    updateDb();
  });

  return setter;
};
