import React from "react";
import Popup from "./Popup";
import Container from "./Container";

export const ChatLayout = (props) => {
  switch (props.type) {
    case "popup":
      return <Popup {...props} />;
    case "container":
      return <Container {...props} />;
    default:
      return null;
  }
};
