import React from "react";
import clsx from "clsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";
import { emptyImage } from "../../IconImages/images";

const ChatImage = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  return (
    <div className={styles.container}>
      <BotAvatar
        messageDate={messageDate}
        isAdmin={isAdmin}
        getBot={getBot}
        botAlias={message?.botAlias}
      />
      <div className={clsx(styles.image_wrapper, "--harper-chatImage-wrapper")}>
        <a href={message.src} rel="noreferrer noopener" target="_blank"></a>
        <img
          className={clsx(styles.image, "--harper-chatImage-wrapper")}
          src={message.src}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null; // prevents looping
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = emptyImage;
          }}
          alt="alt"
        />
      </div>
    </div>
  );
};

export default ChatImage;
