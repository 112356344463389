/* eslint-disable no-use-before-define */
import axios from "axios";
import objectPath from "object-path";
import { merge, sample } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { addBaseUrl, delay, logDebug, logStore, objectReplaceText, replaceText } from ".";
import { HarperBlockEvent, HarperProcess } from "./events";
import { stripePayService } from "../store/api/index";
import { evalExpression } from "./evalExpression";

// message from BOT
const notifyMessage = (text, params) => {
  const message = {
    _id: uuidv4(),
    type: "MARKDOWN",
    text,
    actorType: "BOT",
  };
  params.process.processChat(message);
};

const getNextBlock = async (block, params) => {
  const { blocks } = params;
  if (block.nextBlockId) {
    return blocks[block.nextBlockId];
  }
  return null;
};

const processNextBlock = async (block, params) => {
  const { blocks } = params;
  logDebug("processNext", block, params);
  if (block.nextBlockId) {
    const { variables } = params;
    const nextBlockId = replaceText(block.nextBlockId, variables);
    return handleBlock(blocks[nextBlockId], params);
  }
  return block;
};

const createMessage = (block, values) => {
  return {
    _id: uuidv4(),
    blockId: block._id,
    nextBlockId: block.nextBlockId,
    type: block.type,
    actorType: "BOT",
    ...values,
  };
};

export const createVideoMessage = (block, variables) => {
  const newSrc = replaceText(block.src, variables);
  const newImageSrc = replaceText(block.imageSrc, variables);
  const newText = replaceText(block.text, variables);

  return createMessage(block, {
    src: newSrc,
    imageSrc: newImageSrc,
    text: newText,
    movie: block.movie,
    targetContainer: block.targetContainer,
    autoStart: block.autoStart,
    mute: block.mute,
  });
};

const handleMarkdown = async (block, params) => {
  const { variables } = params;
  logStore("markdown", variables, variables?.vars, block);
  const newText = replaceText(block.text, variables);
  const message = createMessage(block, {
    text: newText,
  });
  params.process.processChat(message);
  return processNextBlock(block, params);
};

const handleImage = async (block, params) => {
  const { variables } = params;
  const newSrc = replaceText(block.src, variables);
  const message = createMessage(block, { src: newSrc });
  params.process.processChat(message);
  return processNextBlock(block, params);
};

const handleFile = async (block, params) => {
  const { variables } = params;
  const newText = replaceText(block.text, variables);
  const newCloudUrl = replaceText(block.cloudUrl, variables);
  const message = createMessage(block, {
    text: newText,
    cloudUrl: newCloudUrl,
    fileId: block?.fileId || `outsource-${uuidv4()}`,
  });
  params.process.processChat(message);
  return processNextBlock(block, params);
};

const handleForm = async (block, params) => {
  const fields = block.fields.map((f) =>
    f.type === "MARKDOWN" ? { ...f, title: replaceText(f.title, params.variables) } : f,
  );
  const message = createMessage(block, { fields, text: block.text });
  params.process.processChatForm(message);
  return block;
};
const reMapResponse = (mapResponse, data) => {
  const mapper = JSON.parse(mapResponse);
  const mapEntries = Object.entries(mapper);
  const obj = {};
  mapEntries.forEach(([k, v]) => {
    obj[k] = objectPath.get(data, v);
  });
  return obj;
};

const parseBody = (bodyContent) => {
  if (typeof bodyContent === "string") {
    try {
      return JSON.parse(bodyContent);
    } catch (error) {
      throw new Error("Invalid JSON string");
    }
  } else if (typeof bodyContent === "object" && bodyContent !== null) {
    return bodyContent;
  }

  throw new Error("Input must be a JSON string or an object");
};

const handleRest = async (block, params) => {
  const { variables, passwords } = params;
  const variablesAndPassword = merge({}, variables, passwords);
  const { mapResponse } = block;
  const { src, target, method, headers = null, body = null } = block.hook;
  const newSrc = replaceText(src, variables);
  let newData = null;
  let newHeaders = null;
  if (body && Object.keys(body).length !== 0) {
    const accessToken = localStorage.getItem(`jwt-${block.organisationAlias}-${block.botAlias}`);
    newData = await objectReplaceText(parseBody(body), variablesAndPassword, { accessToken });
  }
  if (headers && Object.keys(headers).length !== 0) {
    newHeaders = await objectReplaceText(parseBody(headers), variablesAndPassword);
  }
  try {
    const dataResponse = await axios({
      method,
      url: newSrc,
      data: newData,
      headers: { "Content-Type": "application/json", ...newHeaders },
    });
    const { data } = dataResponse;
    if (mapResponse && target && data) {
      objectPath.set(variables, target, reMapResponse(mapResponse, data));
    } else if (target && data) {
      objectPath.set(variables, target, data);
    }
  } catch (err) {
    notifyMessage(`*Configuration error, REST: ${err?.message}, Please contact admin.*`, params);
    console.error("Error:", err.message);
  }
  const message = createMessage(block, { hook: block.hook, actorType: "HIDDEN" });
  params.process.processChat(message);
  return processNextBlock(block, params);
};

function goto(src, targetFrame) {
  if (targetFrame === "_blank" || targetFrame === "_self") {
    window.open(src, targetFrame);
  } else {
    const iframe = document.querySelector(`#${targetFrame}`);
    if (iframe) iframe.src = src;
  }
}

const handleUrl = async (block, params) => {
  const { variables, baseUrl } = params;
  const src = addBaseUrl(baseUrl, block.src);
  const newSrc = replaceText(src, variables);
  const newImageSrc = replaceText(block.imageSrc, variables);
  const newText = replaceText(block.text, variables);
  goto(src, block.targetFrame);

  const message = createMessage(block, {
    src: newSrc,
    imageSrc: newImageSrc,
    text: newText,
    targetFrame: block.targetFrame,
  });

  params.process.processChat(message);
  return processNextBlock(block, params);
};

const handleVideo = async (block, params) => {
  const { variables } = params;
  const message = createVideoMessage(block, variables);
  params.process.processVideo(message);
  const nextBlock = await getNextBlock(block, params);
  if (nextBlock?.type === "VIDEO" && !nextBlock?.movie?.loop) {
    params.process.processQueueVideo(nextBlock);
    return block;
  }
  return processNextBlock(block, params);
};

export const handleVideoForm = async (block, params) => {
  const message = createMessage(block, {
    title: block.title,
    target: block.target,
    actorType: "HIDDEN",
    fields: block.fields,
    value: block.value,
    isPopOver: block.isPopOver,
  });

  params.process.processVideoForm(message);
  return block;
};

const handlePDF = async (block, params) => {
  const { variables } = params;
  const newText = replaceText(block.text, variables);
  const newCloudUrl = replaceText(block.cloudUrl, variables);
  const message = createMessage(block, {
    text: newText,
    cloudUrl: newCloudUrl,
    fileId: block?.fileId || `outsource-${uuidv4()}`,
  });
  await delay(800);
  params.process.processChat(message);
  return processNextBlock(block, params);
};

const handleEventBlock = async (block, params) => {
  const { variables } = params;
  const message = createMessage(block, {
    action: block.action,
    text: block.text,
    haltProcessCondition: block.haltProcessCondition,
  });
  let isStopProcess = false;
  if (block.action === "CLEAR-STORAGE" && block.target) {
    objectPath.set(variables, block.target, null);
  } else {
    isStopProcess = params.process.processEvent(message);
  }
  params.process.processChat(message);
  if (isStopProcess) return message;
  return processNextBlock(block, params);
};

const handleStripe = async (block, params) => {
  const {
    dynamicKey,
    currency,
    mode,
    staticAmount,
    staticTitle,
    staticImageSrc,
    isShippable,
    description,
  } = block;
  const successUrl = block.successUrl || window.location.href;
  const cancelUrl = block.cancelUrl || window.location.href;
  const { variables } = params;
  const newSuccessUrl = replaceText(successUrl, variables);
  const message = createMessage(block, {
    successUrl,
    cancelUrl,
    currency,
    mode,
    staticAmount,
    staticTitle,
    staticImageSrc,
    isShippable,
    dynamicKey,
    description,
  });
  params.process.processChat(message);
  if (block.mode === "dynamic") {
    if (!variables[dynamicKey]) {
      return processNextBlock(block, params);
    }
    try {
      const { theUrl } = await stripePayService().create(
        {
          cancelUrl,
          successUrl: newSuccessUrl,
          isShippable,
          meta: { botAlias: params.botAlias },
          currency,
          products: objectPath.get(variables, dynamicKey),
        },
        { query: { organisationAlias: params.organisationAlias } },
      );
      window.open(theUrl, "_self");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.err(err.message);
    }
  }
  return processNextBlock(block, params);
};

const handleRouter = async (block, params) => {
  const { variables, passwords } = params;
  const { mode, fields } = block;
  let { nextBlockId } = fields[0];
  let newFields = fields;
  if (mode === "RANDOM") {
    nextBlockId = sample(fields).nextBlockId;
  }
  if (mode === "ABTEST") {
    nextBlockId = params.process.processABTEST(block._id, fields);
  }
  if (mode === "CONDITION") {
    const passwordsAndVariables = merge({}, variables, passwords);
    newFields = fields.map((v) => {
      const match = replaceText(v.match, passwordsAndVariables);
      return { ...v, match };
    });
    const isFound = newFields.find((f) => evalExpression(f.match));
    if (isFound) {
      nextBlockId = isFound.nextBlockId;
    } else {
      nextBlockId = newFields[0].nextBlockId;
      // eslint-disable-next-line no-console
      console.log(`Error in evaluating ROUTER condition, toke the first path as fallback`);
    }
  }

  const message = createMessage(block, {
    fields: newFields,
    mode,
    nextBlockId,
    text: replaceText(block.text, variables),
  });
  params.process.processChat(message);
  if (mode === "FANOUT") {
    fields.forEach((f) => {
      processNextBlock({ nextBlockId: f.nextBlockId }, params);
    });
  } else {
    processNextBlock({ nextBlockId }, params);
  }
  return block;
};

const handleEmail = async (block, params) => {
  const { variables } = params;

  const newFrom = replaceText(block.from, variables);
  const newTo = replaceText(block.to, variables);
  const newCc = replaceText(block.cc, variables);
  const newSubject = replaceText(block.subject, variables);
  const newBody = replaceText(block.body, variables);
  const newText = replaceText(block.text, variables);
  const newSalutation = replaceText(block.salutation, variables);
  const newSignature = replaceText(block.signature, variables);

  const message = createMessage(block, {
    from: newFrom,
    to: newTo,
    cc: newCc,
    subject: newSubject,
    salutation: newSalutation,
    body: newBody,
    signature: newSignature,
    text: newText,
  });
  params.process.processChat(message);
  return processNextBlock(block, params);
};

const methods = {
  MARKDOWN: handleMarkdown,
  IMAGE: handleImage,
  FILE: handleFile,
  FORM: handleForm,
  REST: handleRest,
  URL: handleUrl,
  VIDEO: handleVideo,
  VIDEOFORM: handleVideoForm,
  PDF: handlePDF,
  STRIPE: handleStripe,
  EVENT: handleEventBlock,
  ROUTER: handleRouter,
  EMAIL: handleEmail,
};

export const handleBlock = async (block, params) => {
  if (block?.type === undefined) {
    return new Error("block type not supported");
  }
  return new Promise((resolve, rejected) => {
    if (methods[block.type])
      setTimeout(() => {
        try {
          HarperProcess(HarperBlockEvent(block));
          resolve(methods[block.type](block, params));
        } catch (err) {
          rejected(err);
        }
      }, block.delay);
    else {
      rejected(new Error("block type not supported"));
    }
  });
};
