import React from "react";
import clsx from "clsx";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import {
  isVideoBufferSelector,
  isVideoPlayingSelector,
  videoControlsState,
} from "../../store/videoState";
import styles from "./styles.module.scss";
import LoadingSVG from "../IconImages/LoadingSVG";
import IconSVG from "../IconImages/IconSVG";

const PlayButton = ({ detectLoop, timeProgress = 0 }) => {
  const setIsPlaying = useSetRecoilState(isVideoPlayingSelector);
  const videoBuffers = useRecoilValue(isVideoBufferSelector);
  const [{ isMutePlaying, isPlaying }, setVideoControls] = useRecoilState(videoControlsState);

  const handleClickPause = () => {
    setTimeout(() => {
      setVideoControls((v) => ({ ...v, isMutePlaying: false, goToTime: timeProgress }));
      setIsPlaying((v) => !v);
    });
  };

  return (
    <>
      {videoBuffers && !detectLoop && (
        <div className={clsx(styles.buffer_icon, "--harper-videoPlayer-buffer-icon")}>
          <LoadingSVG />
        </div>
      )}
      <div
        onClick={handleClickPause}
        className={clsx(styles.playArea, !isPlaying && !isMutePlaying ? styles.pauseOverlay : null)}
        aria-roledescription="pause area"
      >
        {!isPlaying && !videoBuffers && (
          <div className={clsx(styles.playButtonContainer)}>
            <div className={clsx(styles.playButton, "--harper-videoPlayer-play-button")}>
              <IconSVG.PlayIcon size="100%" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlayButton;
