import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { getFormValidation } from "../../../utils/formValidation";

const RadioButton = ({ field, register, disabled }) => {
  return (
    <>
      <label>
        {field.selected && (
          <input
            type="radio"
            value={field.value}
            {...getFormValidation(register, field)}
            disabled
            checked
            className={clsx(styles.radio_input, "--harper-chatForm-radio--selected")}
          />
        )}
        {!field.selected && (
          <input
            type="radio"
            value={field.value}
            {...getFormValidation(register, field)}
            className={clsx(styles.radio_input, "--harper-chatForm-radio")}
            disabled={disabled}
          />
        )}
        <span className={clsx(styles.radio_text)}>{field.title}</span>
      </label>
    </>
  );
};

export default RadioButton;
