// Display as admin-view, and the chat is authored by chatbot

import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTimeStamp } from "../../../../hooks";
import { botImage } from "../../../IconImages/images";
import { Avatar, ChatTooltip } from "../../../shared";

export const AdminBotAvatar = ({ messageDate, getBot, botAlias }) => {
  const bot = getBot(botAlias);
  const title = bot?.title || "bot";
  const date = useTimeStamp(messageDate);
  const tooltipText = `${date} · ${title}`;
  const id = `message-${messageDate}-${uuidv4()}-${title.replace(/[^a-zA-Z0-9]/g, "")}`;

  return (
    <>
      <Avatar
        id={id}
        altText={`${title} avatar`}
        imageSrc={botImage}
        className="--harper-botMessage-avatar-container"
      />
      <ChatTooltip id={id} text={tooltipText} />
    </>
  );
};
