import React from "react";
import clsx from "clsx";

import { BotAvatar } from "../ChatAvatar";
import styles from "./styles.module.scss";

const ChatRouter = ({ message, isAdmin, getBot }) => {
  const messageDate = message?.added?.at || Date.now();

  if (!message?.text) return null;

  return (
    <div className={styles.container}>
      <div>
        <BotAvatar
          messageDate={messageDate}
          isAdmin={isAdmin}
          getBot={getBot}
          botAlias={message?.botAlias}
        />
      </div>
      <div className={clsx(styles.router_wrapper, "--harper-chatRouter-wrapper")}>
        <div> {message?.text}</div>
      </div>
    </div>
  );
};

export default ChatRouter;
